@import "../../styles-uic/partials/colors";

@mixin ui-list($selector) {
  .#{$selector} {
    li {
      font-size: 0.875rem;
      list-style: none;
      &:before {
        content: "-";
        font-weight: bold;
        width: 8px;
        margin-left: -1rem;
        margin-right: 0.3rem;
        color: $color-coal-black;
      }
    }

    &[variant="disc"] {
      li {
        line-height: 1.3rem;
        &:before {
          /* content: \2022 is the CSS Code/unicode for a bullet */
          content: "\2022";
          font-size: 1.9rem;
          line-height: 1rem;
          vertical-align: sub;
        }
      }
    }
    &[variant="diamond"] {
      font-size: 1rem;
      list-style: none;
      li {
        &:before {
          /* content: \2666 is the CSS Code/unicode for a diamond */
          content: "\2666";
        }
      }
    }
    &[color="blue"] {
      li {
        &:before {
          color: $color-ocean-blue;
        }
      }
    }
    &[color="yellow"] {
      li {
        &:before {
          color: $color-solar-yellow;
        }
      }
    }
    &[color="gray"] {
      li {
        &:before {
          color: $color-magnetic-grey;
        }
      }
    }
    /*
    * Custom classes
    */
    /*
    * padding="none" - to have the list starting at the edge of the container, 
    * canceling default padding of an list.
    */
    &[padding="none"] {
      padding: 0;
    }
    /*
    * padding="default" - the default padding of lists with 1rem;
    */
    &[padding="default"] {
      padding: 1rem 1rem;
    }
    /**
    * padding="large" - when we need the list to nest deeper in the view
    */
    &[padding="large"] {
      padding: 2rem 1rem;
    }
    /*
     * padding="small" - when the list renders closer to the edge. 
    */
    &[padding="small"] {
      padding: 0.5rem 1rem;
    }
  }
}
