@import "./link.component.scss";

.ui-link,
[ui-link] {
  @include ui-link;
}

@media (min-width: 576px) {
  .ui-link,
  [ui-link] {
    @include ui-link-mobile;
  }
}
