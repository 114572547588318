@import "../../styles-uic/partials/fonts";
@import "../../styles-uic/partials/colors";

@mixin ui-link {
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  text-decoration: none;
  position: relative;
  font-family: "Vattenfall Hall", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  letter-spacing: -0.05px;
  line-height: 28px;
  font-weight: normal;
  font-weight: 500;
  color: $color-coal-black;
  padding-left: 35px;

  &:visited {
    color: $color-coal-black;
  }

  &:before {
    content: "\2192";
    position: absolute;
    left: 0;
    font-size: 28px;
    font-weight: normal;
    color: $color-ocean-blue;
    transition-property: opacity, transform;
    transition-duration: 300ms;
    transition-delay: 0ms;
    transform: translate(0%, 0);
  }

  &:hover:before {
    color: $color-ocean-blue;
    transform: translate(50%, 0);
  }

  &:active:before {
    color: $color-vattenfall-blue-button-pressed;
  }

  &:hover {
    color: $color-ocean-blue-hover;
    text-decoration: none;
    cursor: pointer;
  }

  &:focus {
    text-decoration: none;
    border-color: transparent;
    box-shadow: none;
  }

  // TODO: Should have a disabled attribute simular to ui-button
  &:disabled {
    color: $color-disabled-text;
    pointer-events: none;
  }

  &:active {
    color: $color-ocean-blue-active;
    text-decoration: none;
  }

  &[size="medium"] {
    font-size: 16px;
  }

  &[variant="externallink"] {
    &:before {
      @include font-icon;
      content: "\f123";
      position: absolute;
    }

    &:hover:before {
      color: $color-ocean-blue-hover;
      transform: none;
    }
  }

  &[variant="document"] {
    &:before {
      @include font-icon;
      content: "\f160";
      position: absolute;
      transform: none;
    }

    &:hover:before {
      color: $color-ocean-blue-hover;
      transform: none;
    }
  }

  &[variant="edit"] {
    font-weight: 400;
    color: $color-ocean-blue;
    padding-left: 0.5rem;
    padding-right: 2rem;
    vertical-align: baseline;
    line-height: unset;
    &:hover {
      color: darken($color-ocean-blue-hover, 10%);
    }
    &::before {
      content: none;
    }
    &::after {
      @include font-icon;
      content: "\f113";
      position: absolute;
      left: unset;
      font-size: 1rem;
      right: -1.5rem;
      margin-right: 2rem;
      transform: none;
      top: 2px;
    }
  }
}

@mixin ui-link-mobile {
  font-family: "Vattenfall Hall", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 20px;
  letter-spacing: 0;
  line-height: 36px;
}

:host {
  @include ui-link;
}

@media (min-width: 576px) {
  :host {
    @include ui-link-mobile;
  }
}
