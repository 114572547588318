/*
 These components can be styled from outside the #shadow-root
 */
@import "../projects/ui/src/lib/button/button.component.main.scss";
@import "../projects/ui/src/lib/link/link.component.main.scss";
@import "../projects/ui/src/lib/container/container.component.scss";
@import "../projects/ui/src/lib/grid/grid.component.scss";
@import "../projects/ui/src/lib/list/list.component.scss";

@import "../projects/ui/src/styles-uic/partials/breakpoints";
@import "../projects/ui/src/styles-uic/partials/vf-icons-font";
@import "../projects/ui/src/styles-uic/partials/vf-font-faces";

$namespace: "vattenfall-elements";

/*
  'vattenfall-elements' is the namespace used for all the styles when used in the global scope
*/

@include ui-grid(#{$namespace}__ui-grid);
@include ui-container(#{$namespace}__ui-container);
@include ui-list(#{$namespace}__ui-list);

body {
  /**
  * Disable scrolling when dialog modal is open. 
  * This class is added by ui-dialog (dialog.component.ts) on dialog open.
  */
  &.ui-dialog--open {
    overflow: hidden;
  }
}
:root {
  font-family: "Vattenfall Hall", Helvetica, sans-serif;
}

%shimmer {
  $backgroundSizeWidth: 200%;
  $gradientFrom: #989898;
  $gradientTo: #d8d8d8;

  @keyframes leftToRightWawe {
    0% {
      background-position: $backgroundSizeWidth / 2 0%;
    }
    100% {
      background-position: -$backgroundSizeWidth / 2 0%;
    }
  }

  opacity: 0.3;
  background: linear-gradient(90deg, rgba($gradientFrom, 0.5), rgba($gradientTo, 0.5));
  background-size: $backgroundSizeWidth 50%;
  background-position: -$backgroundSizeWidth;
  animation: leftToRightWawe 1.4s cubic-bezier(0.07, 0.85, 0.65, 0.06) infinite;
  transition: background-position 0.8s ease-in-out;
  color: rgba(0, 0, 0, 0) !important;
}

/*
  Styles for when a component is in a compiling state or a ready to be compiled state
*/
.compiling {
  font-size: 0;
  color: transparent;
  @extend %shimmer;
  // Hide slots when compiling
  > *[slot] {
    display: none;
  }
}

/*
  Styles for when a component is in a error state.
  Only used when the application is running in development.
*/
.failed {
  display: block;
  min-height: 3rem;
  background: #eee;
  padding: 0;
  *[slot] {
    display: none;
  }
  code {
    display: block;
    min-height: 3rem;
    padding: 0.75rem;
    background: #fef0ea;
    color: #f93b18;
  }
}

/*
  Hide slots for custom elements that has not been defined
*/
*:not(:defined):not(.failed) {
  // everything in the content of an undefined custom element
  // until the custom element is registered in the browser registry
  elements-input {
    display: none;
  }
  // This line creates issue when using storybook
  // font-size: 0;
  > *[slot] {
    display: none;
  }
}

/*
  Styles to address specific slots
*/

/* MySelectConsumptionIntervalFormSlot styling */

.#{$namespace}__main-heading-container {
  position: relative;
  z-index: 1;
  background: $color-pastel-blue;
  width: 100%;
  margin: auto;
  padding: 2.0625rem 0 5.6875rem 0;
}

.#{$namespace}__main-heading,
[slot="mainHeading"] {
  display: block;
  text-align: center;
  font-size: 4.125rem;
  font-weight: 600;
  width: 100%;
  word-break: break-word;
  margin-bottom: 2rem;
  max-width: 56.875rem; // 910px
  margin: 1rem auto 2rem;
  @include breakpoint-xs {
    font-size: 1.625rem;
    margin-bottom: 1rem;
  }
  @include breakpoint-sm {
    font-size: 1.625rem;
    margin-bottom: 1rem;
  }
}

.#{$namespace}__main-heading--variant-bleed,
[slot="mainHeading"] {
  display: block;
  text-align: center;
  font-size: 2.625rem;
  font-weight: 600;
  width: 100%;
  word-break: break-word;
  margin-bottom: 2rem;
  max-width: 56.875rem; // 910px
  margin: auto;
  line-height: 3.125rem;
  @include breakpoint-xs {
    font-size: 1.625rem;
    padding: 0;
  }
  @include breakpoint-sm {
    font-size: 1.625rem;
    padding: 0;
  }
}

.#{$namespace}__main-body,
[slot="mainBody"] {
  display: block;
  text-align: center;
  font-size: 1.25rem;
  font-weight: normal;
  width: 100%;
  max-width: 56.875rem; // 910px
  margin: 1rem auto 2rem;
}

.#{$namespace}__center {
  text-align: center;
}

.#{$namespace}__expand-button {
  padding: 0.625rem 1.625rem 0.625rem 2.875rem;
  background-color: $color-aura-white;
  border: 2px solid $color-ocean-blue;
  font-size: 1rem;
  line-height: 1.1875rem;
  border-radius: 2rem;
  cursor: pointer;
  outline: none;
  position: relative;
  margin: 2rem auto;

  &:before {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    content: "+";
    font-family: monospace;
    width: 1rem;
    height: 2rem;
    display: inline-block;
    margin-right: 0.5rem;
    font-size: 2rem;
    line-height: 2rem;
  }

  &--expanded {
    &:before {
      content: "-";
      transform: translateY(-55%);
    }
  }

  &:hover {
    background-color: $color-light-grey;
  }
}
.#{$namespace}__ui-container--collapsed {
  max-height: 6rem;
  overflow: hidden;
}
.#{$namespace}__ui-container--expanded {
  max-height: unset !important;
  overflow: visible;
}

.#{$namespace}__edit-link {
  text-decoration: none;
  color: #000;
  &:hover {
    text-decoration: none;
    color: #000;
  }
  &::before {
    font-family: "Vattenfall Icons", sans-serif;
    @extend .vf-icon-VF_elipsis_rgb; // TODO: check icon naming, doesnt correspond to figure.
    color: $color-ocean-blue;
    font-size: 1.3125rem;
    position: relative;
    top: 0.25rem;
    padding-right: 0.5rem;
  }
}

.#{$namespace}__edit-link--pencil-icon__right {
  text-decoration: none;
  color: #000;
  &:hover {
    text-decoration: none;
    color: #000;
  }
  &::after {
    content: "\f113";
    font-family: "Vattenfall Icons", sans-serif;
    color: $color-ocean-blue;
    font-size: 1.3125rem;
    position: relative;
    top: 0.25rem;
    padding-left: 0.5rem;
  }
}

[slot="consumptionIntervalHeading"],
[slot="heatingSystemHeading"] {
  font-size: 1.625rem;
  font-weight: 900;
}

[slot="consumptionIntervalBody"],
[slot="heatingSystemBody"] {
  font-size: 16px;
  margin-top: 25px;
}

[slot="consumptionInterval1Icon"],
[slot="consumptionInterval2Icon"],
[slot="consumptionInterval3Icon"],
[slot="consumptionInterval4Icon"] {
  height: 100%;
  max-height: 70px;
  display: inline-block;
  border-radius: 50%;
  margin: auto;
}

/* End of MySelectConsumptionIntervalFormSlot */

[slot="flowsNewContractSectionNotMovingContractStartDateCaption"],
[slot="flowsNewContractSectionMovingContractStartDateCaption"],
[slot="flowsSubmitContractSectionCurrentContractEndDateCaption"] {
  font-style: italic;
  color: $color-medium-grey;
}

[slot="flowsNewContractSectionInValidDateMessage"],
[slot="flowsSubmitContractSectionInvalidDateMessage"] {
  color: $color-red;
}

[slot="invoiceOverviewLatestInvoiceStatusPaidLabel"] {
  font-weight: bold;
  color: $color-green;
}

[slot="invoiceOverviewLatestInvoiceStatusUnpaidLabel"] {
  font-weight: bold;
  color: $color-coal-black;
}

[slot="invoiceOverviewLatestInvoiceStatusPassedDueDateLabel"] {
  font-weight: bold;
  color: $color-red;
}

[slot^="staticWhiteImage"] {
  height: 6rem;
  min-width: 6rem;
  padding: 1rem;
  border-radius: 50%;
  box-sizing: content-box;
}

[slot="submitFormButtonText"] {
  max-width: 34.8125rem;
  margin: auto;
}

[slot="mySelectElectricalProductIcon"] {
  width: 3.125rem;
  display: inline-block;
  vertical-align: middle;
}

// Select my electrical product slot styling
[slot="mySelectElectricalProductLabel"] {
  color: $color-aura-white;
  border-radius: 2rem;
  position: absolute;
  top: -1rem;
  left: 50%;
  font-weight: 600;
  transform: translateX(-50%) translateY(-50%);
  background-color: $color-dark-green;
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
  // TODO: check if we could have the label as inline.
  // This way it wont show onless there is text content inside, and we don't need to style the label from the outside.

  // this is the reason why we style the slot and not adding a wrapper inside the element.
  // :empty - if there is no content in this slot, we will hide it.
  // In this case we are solving the problem of empty content padding.
  &:empty {
    display: none;
  }
}
.#{$namespace}__input-prefix { 
  max-width: 10%;
  display: block;

  @include breakpoint-xs {
    margin: auto;
    text-align: center;
    margin: 0 auto 1rem auto;
    }
}

[slot="postalCodeGridareaPrefix"],
[slot="consumptionIntervalPrefix"] {
  color: $color-aura-white;
  background-color: $color-ocean-blue;
  border-radius: 50%;
  padding: 4px 11px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.375rem;
  margin-right: 0.75rem;
  vertical-align: top;
  top: 1rem;
  position: relative;
  @include breakpoint-xs {
    display: block;
    max-width: 35px;
    margin: 0 auto 20px;
    padding: 0.4rem;
    text-align: center;
    top: unset;
    position: unset
  }
  &:empty {
    display: none;
  }
}