$fontPath: "https://elements.vattenfall.se/fonts";

// TODO: This dependency to a CDN in the Neatherlands should be changed to something else
$fontLegacyPath: "https://cdn.vattenfall.nl/vattenfallnlprd/1.2.0/assets/fonts";

@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 300;
  font-style: normal;
  src: url($fontPath+"/VattenfallHall-Light.eot") format("eot"),
    url($fontPath+"/VattenfallHall-Light.woff2") format("woff2"),
    url($fontPath+"/VattenfallHall-Light.woff") format("woff");
}

@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 300;
  font-style: italic;
  src: url($fontPath+"/VattenfallHall-LightItalic.eot") format("eot"),
    url($fontPath+"/VattenfallHall-LightItalic.woff2") format("woff2"),
    url($fontPath+"/VattenfallHall-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 400;
  font-style: normal;
  src: url($fontPath+"/VattenfallHall-Regular.eot") format("eot"),
    url($fontPath+"/VattenfallHall-Regular.woff2") format("woff2"),
    url($fontPath+"/VattenfallHall-Regular.woff") format("woff");
}

@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 400;
  font-style: italic;
  src: url($fontPath+"/VattenfallHall-RegularItalic.eot") format("eot"),
    url($fontPath+"/VattenfallHall-RegularItalic.woff2") format("woff2"),
    url($fontPath+"/VattenfallHall-RegularItalic.woff") format("woff");
}

@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 500;
  font-style: normal;
  src: url($fontPath+"/VattenfallHall-Medium.eot") format("eot"),
    url($fontPath+"/VattenfallHall-Medium.woff2") format("woff2"),
    url($fontPath+"/VattenfallHall-Medium.woff") format("woff");
}

@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 500;
  font-style: italic;
  src: url($fontPath+"/VattenfallHall-MediumItalic.eot") format("eot"),
    url($fontPath+"/VattenfallHall-MediumItalic.woff2") format("woff2"),
    url($fontPath+"/VattenfallHall-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "Vattenfall Hall Display";
  font-weight: 600;
  font-style: normal;
  src: url($fontPath+"/VattenfallHallDisplay-Bold.eot") format("eot"),
    url($fontPath+"/VattenfallHallDisplay-Bold.woff2") format("woff2"),
    url($fontPath+"/VattenfallHallDisplay-Bold.woff") format("woff");
}

@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 600;
  font-style: normal;
  src: url($fontPath+"/VattenfallHall-Bold.eot") format("eot"),
    url($fontPath+"/VattenfallHall-Bold.woff2") format("woff2"),
    url($fontPath+"/VattenfallHall-Bold.woff") format("woff");
}

@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 600;
  font-style: italic;
  src: url($fontPath+"/VattenfallHall-BoldItalic.eot") format("eot"),
    url($fontPath+"/VattenfallHall-BoldItalic.woff2") format("woff2"),
    url($fontPath+"/VattenfallHall-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 700;
  font-style: normal;
  src: url($fontPath+"/VattenfallHall-Heavy.eot") format("eot"),
    url($fontPath+"/VattenfallHall-Heavy.woff2") format("woff2"),
    url($fontPath+"/VattenfallHall-Heavy.woff") format("woff");
}

@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 700;
  font-style: italic;
  src: url($fontPath+"/VattenfallHall-HeavyItalic.eot") format("eot"),
    url($fontPath+"/VattenfallHall-HeavyItalic.woff2") format("woff2"),
    url($fontPath+"/VattenfallHall-HeavyItalic.woff") format("woff");
}

// @font-face {
//   font-family: "Vattenfall Icons";
//   src: url($fontLegacyPath+"/Vattenfall-Icons.eot?8ead6525fb6ac8c4c16b856aaf36f668?#iefix")
//       format("embedded-opentype"),
//     url($fontLegacyPath+"/Vattenfall-Icons.woff2?8ead6525fb6ac8c4c16b856aaf36f668")
//       format("woff2"),
//     url($fontLegacyPath+"/Vattenfall-Icons.woff?8ead6525fb6ac8c4c16b856aaf36f668")
//       format("woff"),
//     url($fontLegacyPath+"/Vattenfall-Icons.ttf?8ead6525fb6ac8c4c16b856aaf36f668")
//       format("truetype"),
//     url($fontLegacyPath+"/Vattenfall-Icons.svg?8ead6525fb6ac8c4c16b856aaf36f668#Vattenfall-Icons")
//       format("svg");

@font-face {
  font-family: "Vattenfall Icons";
  src: url($fontLegacyPath+"/Vattenfall-Icons.eot")
      format("embedded-opentype"),
    url($fontLegacyPath+"/Vattenfall-Icons.woff")
      format("woff"),
    url($fontLegacyPath+"/Vattenfall-Icons.woff2")
      format("woff2"),
    url($fontLegacyPath+"/Vattenfall-Icons.ttf")
      format("truetype")
}

@font-face {
  font-family: "Vattenfall Icons";
  src: url($fontPath+"/VattenfallElements-Icons.eot")
      format("embedded-opentype"),
    url($fontPath+"/VattenfallElements-Icons.woff")
      format("woff"),
    url($fontPath+"/VattenfallElements-Icons.ttf")
      format("truetype")
}
