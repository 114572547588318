// DO NOT EDIT - This file is generated by generate-icon-font.js
// 2019-09-26 17:05:31 (hanskerkhof)
// ... but the file is now extracted from its original content.

i[class^="vf-icon-"]:before,
i[class*=" vf-icon-"]:before {
  font-family: "Vattenfall Icons", sans-serif !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vf-icon-VF_add_contact_rgb:before {
  content: "\f101";
}
.vf-icon-VF_alert_rgb:before {
  content: "\f102";
}
.vf-icon-VF_arrow_down_left_rgb:before {
  content: "\f103";
}
.vf-icon-VF_arrow_down_rgb:before {
  content: "\f104";
}
.vf-icon-VF_arrow_down_right_rgb:before {
  content: "\f105";
}
.vf-icon-VF_arrow_left_rgb:before {
  content: "\f106";
}
.vf-icon-VF_arrow_right_rgb:before {
  content: "\f107";
}
.vf-icon-VF_arrow_up_left_rgb:before {
  content: "\f108";
}
.vf-icon-VF_arrow_up_rgb:before {
  content: "\f109";
}
.vf-icon-VF_arrow_up_right_rgb:before {
  content: "\f10a";
}
.vf-icon-VF_calendar_rgb:before {
  content: "\f10b";
}
.vf-icon-VF_call_rgb:before {
  content: "\f10c";
}
.vf-icon-VF_camera_rgb:before {
  content: "\f10d";
}
.vf-icon-VF_chat_rgb:before {
  content: "\f10e";
}
.vf-icon-VF_checkbox_rgb:before {
  content: "\f10f";
}
.vf-icon-VF_download_pdf_rgb:before {
  content: "\f110";
}
.vf-icon-VF_download_rgb:before {
  content: "\f111";
}
.vf-icon-VF_edit_rgb:before {
  content: "\f112";
}
.vf-icon-VF_elipsis_rgb:before {
  content: "\f113";
}
.vf-icon-VF_eye_rgb:before {
  content: "\f114";
}
.vf-icon-VF_favourite_rgb:before {
  content: "\f115";
}
.vf-icon-VF_gallery_rgb:before {
  content: "\f116";
}
.vf-icon-VF_guide_icon_rgb:before {
  content: "\f117";
}
.vf-icon-VF_heart_rgb:before {
  content: "\f118";
}
.vf-icon-VF_help_rgb:before {
  content: "\f119";
}
.vf-icon-VF_information_rgb:before {
  content: "\f11a";
}
.vf-icon-VF_locked_rgb:before {
  content: "\f11b";
}
.vf-icon-VF_mail_rgb:before {
  content: "\f11c";
}
.vf-icon-VF_maps_rgb:before {
  content: "\f11d";
}
.vf-icon-VF_nina_avatar_background_rgb:before {
  content: "\f11e";
}
.vf-icon-VF_nina_happy_avatar_rgb:before {
  content: "\f11f";
}
.vf-icon-VF_notvisible_rgb:before {
  content: "\f120";
}
.vf-icon-VF_off_rgb:before {
  content: "\f121";
}
.vf-icon-VF_open_new_window_rgb:before {
  content: "\f122";
}
.vf-icon-VF_pause_rgb:before {
  content: "\f123";
}
.vf-icon-VF_phone_rgb:before {
  content: "\f124";
}
.vf-icon-VF_pin_rgb:before {
  content: "\f125";
}
.vf-icon-VF_play_rgb:before {
  content: "\f126";
}
.vf-icon-VF_print_rgb:before {
  content: "\f127";
}
.vf-icon-VF_settings_rgb:before {
  content: "\f128";
}
.vf-icon-VF_share_rgb:before {
  content: "\f129";
}
.vf-icon-VF_shop_rgb:before {
  content: "\f12a";
}
.vf-icon-VF_sign_out_rgb:before {
  content: "\f12b";
}
.vf-icon-VF_sort_rgb:before {
  content: "\f12c";
}
.vf-icon-VF_tag_rgb:before {
  content: "\f12d";
}
.vf-icon-VF_unlocked_rgb:before {
  content: "\f12e";
}
.vf-icon-VF_user_rgb:before {
  content: "\f12f";
}
.vf-icon-VF_zoom_in_rgb:before {
  content: "\f130";
}
.vf-icon-VF_zoom_out_rgb:before {
  content: "\f131";
}
.vf-icon-VF_allocate_agent_rgb:before {
  content: "\f132";
}
.vf-icon-VF_allocate_location_rgb:before {
  content: "\f133";
}
.vf-icon-VF_allocate_party_rgb:before {
  content: "\f134";
}
.vf-icon-VF_allocate_team_rgb:before {
  content: "\f135";
}
.vf-icon-VF_promotion_rgb:before {
  content: "\f136";
}
.vf-icon-VF_Sustainable_use_of_resources_rgb:before {
  content: "\f137";
}
.vf-icon-VF_airplane_rgb:before {
  content: "\f138";
}
.vf-icon-VF_apartment_house_rgb:before {
  content: "\f139";
}
.vf-icon-VF_battery_rgb:before {
  content: "\f13a";
}
.vf-icon-VF_benchmark_co2_rgb:before {
  content: "\f13b";
}
.vf-icon-VF_benchmark_cockpit_rgb:before {
  content: "\f13c";
}
.vf-icon-VF_benchmark_rgb:before {
  content: "\f13d";
}
.vf-icon-VF_benchmark_temperature_rgb:before {
  content: "\f13e";
}
.vf-icon-VF_benefit_rgb:before {
  content: "\f13f";
}
.vf-icon-VF_biofuel-biomass_rgb:before {
  content: "\f140";
}
.vf-icon-VF_brainstorm_rgb:before {
  content: "\f141";
}
.vf-icon-VF_callback_rgb:before {
  content: "\f142";
}
.vf-icon-VF_chart_rgb:before {
  content: "\f143";
}
.vf-icon-VF_city_rgb:before {
  content: "\f144";
}
.vf-icon-VF_clock_rgb:before {
  content: "\f145";
}
.vf-icon-VF_coal_rgb:before {
  content: "\f146";
}
.vf-icon-VF_cold_rgb:before {
  content: "\f147";
}
.vf-icon-VF_combined_heat_and_power_rgb:before {
  content: "\f148";
}
.vf-icon-VF_consumption_rgb:before {
  content: "\f149";
}
.vf-icon-VF_contract_details_rgb:before {
  content: "\f14a";
}
.vf-icon-VF_corporate_rgb:before {
  content: "\f14b";
}
.vf-icon-VF_cost_saving_rgb:before {
  content: "\f14c";
}
.vf-icon-VF_cottage_rgb:before {
  content: "\f14d";
}
.vf-icon-VF_currency_rgb:before {
  content: "\f14e";
}
.vf-icon-VF_defence_rgb:before {
  content: "\f14f";
}
.vf-icon-VF_discussion_B_rgb:before {
  content: "\f150";
}
.vf-icon-VF_discussion_rgb:before {
  content: "\f151";
}
.vf-icon-VF_distribution_rgb:before {
  content: "\f152";
}
.vf-icon-VF_dont_rgb:before {
  content: "\f153";
}
.vf-icon-VF_e-bike_rgb:before {
  content: "\f154";
}
.vf-icon-VF_e-mobility_rgb:before {
  content: "\f155";
}
.vf-icon-VF_economize_rgb:before {
  content: "\f156";
}
.vf-icon-VF_eko_hourly_spot_rgb:before {
  content: "\f157";
}
.vf-icon-VF_electricity_rgb:before {
  content: "\f158";
}
.vf-icon-VF_electronic_payment_euro_rgb:before {
  content: "\f159";
}
.vf-icon-VF_energy_mix_rgb:before {
  content: "\f15a";
}
.vf-icon-VF_energy_solutions_rgb:before {
  content: "\f15b";
}
.vf-icon-VF_euro_rgb:before {
  content: "\f15c";
}
.vf-icon-VF_evaluation_rgb:before {
  content: "\f15d";
}
.vf-icon-VF_farm_rgb:before {
  content: "\f15e";
}
.vf-icon-VF_fax_rgb:before {
  content: "\f15f";
}
.vf-icon-VF_full_water_quartal_icon_rgb:before {
  content: "\f160";
}
.vf-icon-VF_gas_rgb:before {
  content: "\f161";
}
.vf-icon-VF_globe_rgb:before {
  content: "\f162";
}
.vf-icon-VF_good_deal_rgb:before {
  content: "\f163";
}
.vf-icon-VF_group_discussion_rgb:before {
  content: "\f164";
}
.vf-icon-VF_heating_rgb:before {
  content: "\f165";
}
.vf-icon-VF_hot_rgb:before {
  content: "\f166";
}
.vf-icon-VF_house_rgb:before {
  content: "\f167";
}
.vf-icon-VF_hydro_rgb:before {
  content: "\f168";
}
.vf-icon-VF_in_queue_rgb:before {
  content: "\f169";
}
.vf-icon-VF_industry_rgb:before {
  content: "\f16a";
}
.vf-icon-VF_invoices_rgb:before {
  content: "\f16b";
}
.vf-icon-VF_large_battery_rgb:before {
  content: "\f16c";
}
.vf-icon-VF_lecture_rgb:before {
  content: "\f16d";
}
.vf-icon-VF_life_events_rgb:before {
  content: "\f16e";
}
.vf-icon-VF_light_bulb_rgb:before {
  content: "\f16f";
}
.vf-icon-VF_manage_my_team_rgb:before {
  content: "\f170";
}
.vf-icon-VF_mobility_rgb:before {
  content: "\f171";
}
.vf-icon-VF_modules_rgb:before {
  content: "\f172";
}
.vf-icon-VF_move_with_existing_contract_rgb:before {
  content: "\f173";
}
.vf-icon-VF_move_with_new_contract_rgb:before {
  content: "\f174";
}
.vf-icon-VF_multi_home_box_rgb:before {
  content: "\f175";
}
.vf-icon-VF_my_cases_rgb:before {
  content: "\f176";
}
.vf-icon-VF_my_documents_rgb:before {
  content: "\f177";
}
.vf-icon-VF_nighttime_electricity_metering_rgb:before {
  content: "\f178";
}
.vf-icon-VF_nuclear_rgb:before {
  content: "\f179";
}
.vf-icon-VF_ocean_energy_rgb:before {
  content: "\f17a";
}
.vf-icon-VF_offshore_rgb:before {
  content: "\f17b";
}
.vf-icon-VF_oil_condesing_gas_turbine_rgb:before {
  content: "\f17c";
}
.vf-icon-VF_partners_rgb:before {
  content: "\f17d";
}
.vf-icon-VF_peat_rgb:before {
  content: "\f17e";
}
.vf-icon-VF_planned_outage_rgb:before {
  content: "\f17f";
}
.vf-icon-VF_policies_rgb:before {
  content: "\f180";
}
.vf-icon-VF_power_grid_rgb:before {
  content: "\f181";
}
.vf-icon-VF_power_plant_rgb:before {
  content: "\f182";
}
.vf-icon-VF_premium_rgb:before {
  content: "\f183";
}
.vf-icon-VF_present_rgb:before {
  content: "\f184";
}
.vf-icon-VF_private_end_user_rgb:before {
  content: "\f185";
}
.vf-icon-VF_private_rgb:before {
  content: "\f186";
}
.vf-icon-VF_recycling_rgb:before {
  content: "\f187";
}
.vf-icon-VF_salary_time_and_benefits_rgb:before {
  content: "\f188";
}
.vf-icon-VF_semi_detached_house_rgb:before {
  content: "\f189";
}
.vf-icon-VF_ship_docking_rgb:before {
  content: "\f18a";
}
.vf-icon-VF_smart_home_rgb:before {
  content: "\f18b";
}
.vf-icon-VF_smartphone_text_rgb:before {
  content: "\f18c";
}
.vf-icon-VF_solar_panel_rgb:before {
  content: "\f18d";
}
.vf-icon-VF_solar_power_rgb:before {
  content: "\f18e";
}
.vf-icon-VF_standard_contract_continuos_rgb:before {
  content: "\f18f";
}
.vf-icon-VF_support_rgb:before {
  content: "\f190";
}
.vf-icon-VF_temperature_rgb:before {
  content: "\f191";
}
.vf-icon-VF_voice_search_rgb:before {
  content: "\f192";
}
.vf-icon-VF_wind_rgb:before {
  content: "\f193";
}
.vf-icon-VF_card_and_tag_rgb:before {
  content: "\f194";
}
.vf-icon-VF_charge_up_your_business_rgb:before {
  content: "\f195";
}
.vf-icon-VF_charging_wall_box_rgb:before {
  content: "\f196";
}
.vf-icon-VF_parking_meter_rgb:before {
  content: "\f197";
}
.vf-icon-VF_partner_rgb:before {
  content: "\f198";
}
.vf-icon-VF_private_charging_rgb:before {
  content: "\f199";
}
.vf-icon-VF_public_business_charging_rgb:before {
  content: "\f19a";
}
.vf-icon-VF_public_charging_rgb:before {
  content: "\f19b";
}
.vf-icon-VF_scooter_2_rgb:before {
  content: "\f19c";
}
.vf-icon-VF_scooter_rgb:before {
  content: "\f19d";
}
.vf-icon-VF_smart_charging_poles_rgb:before {
  content: "\f19e";
}
.vf-icon-VF_check_rgb:before {
  content: "\f19f";
}
.vf-icon-VF_close_rgb:before {
  content: "\f1a0";
}
.vf-icon-VF_down_rgb:before {
  content: "\f1a1";
}
.vf-icon-VF_filter_rgb:before {
  content: "\f1a2";
}
.vf-icon-VF_home_rgb:before {
  content: "\f1a3";
}
.vf-icon-VF_left_rgb:before {
  content: "\f1a4";
}
.vf-icon-VF_less_info_rgb:before {
  content: "\f1a5";
}
.vf-icon-VF_menu_rgb:before {
  content: "\f1a6";
}
.vf-icon-VF_more_rgb:before {
  content: "\f1a7";
}
.vf-icon-VF_refresh_rgb:before {
  content: "\f1a8";
}
.vf-icon-VF_right_rgb:before {
  content: "\f1a9";
}
.vf-icon-VF_rss_rgb:before {
  content: "\f1aa";
}
.vf-icon-VF_search_rgb:before {
  content: "\f1ab";
}
.vf-icon-VF_send_rgb:before {
  content: "\f1ac";
}
.vf-icon-VF_up_rgb:before {
  content: "\f1ad";
}
.vf-icon-VF_TV_remote_rgb:before {
  content: "\f1ae";
}
.vf-icon-VF_alarm_rgb:before {
  content: "\f1af";
}
.vf-icon-VF_cloudy_rgb:before {
  content: "\f1b0";
}
.vf-icon-VF_coffee_maker_rgb:before {
  content: "\f1b1";
}
.vf-icon-VF_console_rgb:before {
  content: "\f1b2";
}
.vf-icon-VF_desklamp_rgb:before {
  content: "\f1b3";
}
.vf-icon-VF_dishwasher_rgb:before {
  content: "\f1b4";
}
.vf-icon-VF_door_closed_rgb:before {
  content: "\f1b5";
}
.vf-icon-VF_door_open_rgb:before {
  content: "\f1b6";
}
.vf-icon-VF_dryer_rgb:before {
  content: "\f1b7";
}
.vf-icon-VF_fridge_freezer_rgb:before {
  content: "\f1b8";
}
.vf-icon-VF_ice_rgb:before {
  content: "\f1b9";
}
.vf-icon-VF_kettle_rgb:before {
  content: "\f1ba";
}
.vf-icon-VF_laptop_rgb:before {
  content: "\f1bb";
}
.vf-icon-VF_light_switch_rgb:before {
  content: "\f1bc";
}
.vf-icon-VF_lightbulb_rgb:before {
  content: "\f1bd";
}
.vf-icon-VF_lightbulb_step_1_rgb:before {
  content: "\f1be";
}
.vf-icon-VF_lightbulb_step_2_rgb:before {
  content: "\f1bf";
}
.vf-icon-VF_lightbulb_step_3_rgb:before {
  content: "\f1c0";
}
.vf-icon-VF_lightbulb_step_4_rgb:before {
  content: "\f1c1";
}
.vf-icon-VF_lightbulb_step_5_rgb:before {
  content: "\f1c2";
}
.vf-icon-VF_microwave_rgb:before {
  content: "\f1c3";
}
.vf-icon-VF_moon_rgb:before {
  content: "\f1c4";
}
.vf-icon-VF_phone_charging_rgb:before {
  content: "\f1c5";
}
.vf-icon-VF_radiator_rgb:before {
  content: "\f1c6";
}
.vf-icon-VF_rain_rgb:before {
  content: "\f1c7";
}
.vf-icon-VF_router_rgb:before {
  content: "\f1c8";
}
.vf-icon-VF_smart_plug_rgb:before {
  content: "\f1c9";
}
.vf-icon-VF_smoke_alarm_rgb:before {
  content: "\f1ca";
}
.vf-icon-VF_snow_rgb:before {
  content: "\f1cb";
}
.vf-icon-VF_sun_rgb:before {
  content: "\f1cc";
}
.vf-icon-VF_table_lamp_2_rgb:before {
  content: "\f1cd";
}
.vf-icon-VF_table_lamp_rgb:before {
  content: "\f1ce";
}
.vf-icon-VF_thunder_rgb:before {
  content: "\f1cf";
}
.vf-icon-VF_ventilator_rgb:before {
  content: "\f1d0";
}
.vf-icon-VF_washing_machine_rgb:before {
  content: "\f1d1";
}
.vf-icon-VF_window_blinds_rgb:before {
  content: "\f1d2";
}
.vf-icon-VF_window_closed_rgb:before {
  content: "\f1d3";
}
.vf-icon-VF_window_open_rgb:before {
  content: "\f1d4";
}
.vf-icon-VF_window_rgb:before {
  content: "\f1d5";
}
.vf-icon-VF_dropbox_rgb:before {
  content: "\f1d6";
}
.vf-icon-VF_facebook_rgb:before {
  content: "\f1d7";
}
.vf-icon-VF_flickr_rgb:before {
  content: "\f1d8";
}
.vf-icon-VF_google_plus_rgb:before {
  content: "\f1d9";
}
.vf-icon-VF_instagram_rgb:before {
  content: "\f1da";
}
.vf-icon-VF_linkedin_rgb:before {
  content: "\f1db";
}
.vf-icon-VF_pinterest_rgb:before {
  content: "\f1dc";
}
.vf-icon-VF_skype_rgb:before {
  content: "\f1dd";
}
.vf-icon-VF_slideshare_rgb:before {
  content: "\f1de";
}
.vf-icon-VF_snapchat_rgb:before {
  content: "\f1df";
}
.vf-icon-VF_twitter_rgb:before {
  content: "\f1e0";
}
.vf-icon-VF_vimeo_rgb:before {
  content: "\f1e1";
}
.vf-icon-VF_xing_rgb:before {
  content: "\f1e2";
}
.vf-icon-VF_youtube_rgb:before {
  content: "\f1e3";
}
