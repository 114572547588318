@import "../../styles-uic/partials/fonts";

@mixin ui-button {
  text-decoration: none;
  box-sizing: border-box;
  display: inline-block;
  font-weight: 500;
  font-family: "Vattenfall Hall";
  color: #222222;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 9px 48px;
  font-size: 14px;
  line-height: 18px;
  border-radius: 20px;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  min-width: 180px;
  position: relative;

  &:before {
    content: "\2192";
    position: absolute;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-transform: translate(-220%, 0);
    transform: translate(-220%, 0);
    font-weight: normal;
    font-size: 20px;
    opacity: 0;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
    -webkit-transition-delay: 0ms;
    transition-delay: 0ms;
  }

  &:hover:before {
    -webkit-transition-delay: 150ms;
    transition-delay: 150ms;
    -webkit-transform: translate(-150%, 0);
    transform: translate(-150%, 0);
    opacity: 1;
  }

  &:hover,
  &:focus {
    color: #222222;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 218, 0, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(255, 218, 0, 0.25);
  }

  &:disabled {
    opacity: 0.65;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &[variant="primary"] {
    color: #111111;
    background: #ffda00;
  }

  &[variant="primary"]:hover {
    color: #111111;
    background: #ffe85b;
  }

  &[variant="primary"]:focus {
    background: #f1c50b;
  }

  &[variant="primary"]:disabled {
    color: #767676;
    background-color: #eeeeee;
  }

  &[variant="secondary"] {
    color: white;
    background: #1964a3;
  }

  &[variant="secondary"]:hover {
    color: white;
    background: #2071b5;
  }

  &[variant="secondary"]:focus,
  &[variant="secondary"]:active {
    color: white;
    background: #1e324f;
  }

  &[variant="secondary"]:disabled {
    color: #767676;
    background-color: #eeeeee;
  }

  &[variant="tertiary"] {
    color: #222222;
    background: transparent;
    border: 2px #cdd1d4 solid;
  }

  &[variant="tertiary"]:hover {
    color: white;
    background: #1964a3;
    border-color: #1964a3;
  }

  &[variant="tertiary"]:focus,
  &[variant="tertiary"]:active {
    color: white;
    background: #1e324f;
    border-color: #1e324f;
  }

  &[variant="tertiary"]:disabled {
    color: #767676;
    background-color: #eeeeee;
    border-color: #eeeeee;
  }

  &[variant="outline-secondary"],
  &[variant="expandable"],
  &[variant="expanded"] {
    color: #1964a3;
    background: transparent;
    border: 2px #2071b5 solid;
  }

  &[variant="outline-secondary"]:hover {
    color: white;
    background: #1964a3;
    border-color: #1964a3;
  }

  &[variant="outline-secondary"]:focus,
  &[variant="outline-secondary"]:active {
    color: white;
    background: #1e324f;
    border-color: #1e324f;
  }

  &[variant="outline-secondary"]:disabled,
  &[variant="expandable"]:disabled,
  &[variant="expanded"]:disabled {
    color: #767676;
    background-color: #eeeeee;
    border-color: #eeeeee;
  }

  &[variant="outline-dark"] {
    color: #222222;
    background: transparent;
    border: 2px #222222 solid;
  }

  &[variant="outline-dark"]:hover {
    color: white;
    background: #222222;
    border-color: #222222;
  }

  &[variant="outline-dark"]:focus,
  &[variant="outline-dark"]:active {
    color: white;
    background: #4e4b48;
    border-color: #4e4b48;
  }

  &[variant="outline-dark"]:disabled {
    color: #767676;
    background-color: #eeeeee;
    border-color: #eeeeee;
  }

  &[variant="expandable"],
  &[variant="expanded"] {
    color: black;
    background-color: $color-aura-white;

    &:before {
      position: absolute;
      opacity: 1;
      top: 50%;
      left: 1rem;
      font-family: monospace;
      width: 1rem;
      height: 2rem;
      display: inline-block;
      margin-right: 0.5rem;
      font-size: 2rem;
      line-height: 2rem;
    }
    &:hover {
      background-color: $color-light-grey;
    }
    &:focus {
      box-shadow: none;
    }
  }

  &[variant="expandable"]:before {
    content: "+";
    transform: translateY(-50%);
  }

  &[variant="expanded"]:before {
    content: "\2212";
    transform: translateY(-50%);
  }

  &[size="large"] {
    padding: 12px 48px;
    font-size: 18px;
    line-height: 24px;
    border-radius: 26px;
    min-width: 240px;
  }

  &[size="large"]:before {
    font-size: 24px;
    -webkit-transform: translate(-220%), 0;
    transform: translate(-220%), 0;
  }

  &[size="large"]:hover:before {
    -webkit-transform: translate(-150%), 0;
    transform: translate(-150%), 0;
  }

  // Small size button variant
  &[size="small"] {
    min-width: 120px;
  }

  &[size="small"]:before {
    font-size: 16px;
    -webkit-transform: translate(-220%), 0;
    transform: translate(-220%), 0;
  }

  &[size="small"]:hover:before {
    -webkit-transform: translate(-150%), 0;
    transform: translate(-150%), 0;
  }

  &[fluid="true"] {
    display: block;
    width: 100%;
  }

  &[fluid="true"] + &[fluid="true"] {
    margin-top: 0.5rem;
  }

  input[type="submit"] &[fluid="true"],
  input[type="reset"] &[fluid="true"],
  input[type="button"] &[fluid="true"] {
    width: 100%;
  }
}

@mixin ui-button-mobile {
  -webkit-transition: none;
  transition: none;
}

:host {
  @include ui-button;
}

@media screen and (prefers-reduced-motion: reduce) {
  :host {
    @include ui-button-mobile;
  }
}
