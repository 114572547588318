@import "../../styles-uic/partials/colors";
@import "../../styles-uic/partials/breakpoints";

%ui-grid-border {
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: $color-light-grey;
}
%ui-grid-tile {
  flex-grow: 2;
  display: block;
  box-sizing: border-box;
  // TODO: this is causing problems with the default colspan. Check and fix later.
  width: 25%;
  @include breakpoint-xs {
    width: 100% !important;
    min-width: 100% !important;
  }
}
%ui-grid-tile-border {
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: $color-light-grey;
}

%ui-grid-tile-gutter-border {
  border-width: 1px;
  border-style: solid;
  border-color: $color-light-grey;
}
@mixin makeColumns($namespace, $gutter) {
  @for $cols from 1 through 12 {
    @for $colspan from 1 through 12 {
      @if $cols >= $colspan {
        /**
        example without gutter (default):
          <section class="ui-grid ui-grid--cols-4">
            <section class="ui-grid__tile--colspan-2"></section>
          </section>
        example with gutter (defaults to 1rem (16px) between columns:
          <section class="ui-grid-gutter ui-grid--cols-4">
            <section class="ui-grid__tile--colspan-2"></section>
          </section>

        */
        &.#{$namespace}--cols-#{$cols} {
          .#{$namespace}__tile--colspan-#{$colspan} {
            flex-basis: calc((100% /#{$cols * $colspan}) - #{$gutter});
            // if gutter is passed on, add half of the gutter margin to the __tile element
            @if ($gutter > 0) {
              @include breakpoint-sm {
                margin: #{$gutter/4};
              }
              @include breakpoint-md {
                margin: #{$gutter/2};
              }
              @include breakpoint-lg {
                margin: #{$gutter/2};
              }
            }
          }
        }
      }
    }
  }
}
@mixin ui-grid($selector) {
  .#{$selector} {
    //.ui-grid
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: left;
    &__tile {
      @extend %ui-grid-tile;
    }
    &--border:not(&--gutter) {
      @extend %ui-grid-border;
    }
    // border when grid has no gutter
    &--border:not(&--gutter) &__tile {
      @extend %ui-grid-tile-border;
    }
    // defaults border with gutter.
    &--border &__tile {
      @extend %ui-grid-tile-gutter-border;
    }
    //  default grid without gutter space.
    @include makeColumns($selector, 0rem);
  }

  .#{$selector}--gutter {
    align-content: space-between;
    align-content: space-around;
    flex-direction: row;

    // IE needs to specifically know that the background is transparent. Defaults to white.
    background: transparent;
    // default gutter to 0.5rem.
    @include makeColumns($selector, 0.5rem);

    // specifically
    &__small {
      // create columns with 1rem gutter space between.
      @include makeColumns($selector, 1.375rem);
    }
    &__large {
      // create columns with 1rem gutter space between.
      @include makeColumns($selector, 2rem);
    }
    .#{$selector}__tile {
      align-self: flex-start;
    }
  }
}
