@import "../../styles-uic/partials/colors";
@import "../../styles-uic/partials/fonts";
@import "../../styles-uic/partials/breakpoints";

@mixin ui-container($selector) {
  .#{$selector} {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    margin-right: auto;
    margin-left: auto;
    clear: left;
    // TODO: Set font-family as Storybook theme instead and remove this
    font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
    @include container-breakpoint-xs {
      max-width: 540px;
      padding-left: 10px;
      padding-right: 10px;
    }
    @include container-breakpoint-sm {
      max-width: 720px;
    }
    @include container-breakpoint-md {
      // empty
    }

    @include container-breakpoint-lg() {
      max-width: 960px;
      padding-right: 20px;
      padding-left: 20px;
    }
    @include container-breakpoint-xl() {
      max-width: 1160px;
      padding-right: 20px;
      padding-left: 20px;
    }
    &--variant-tight {
      @include container-breakpoint-md {
        max-width: 600px;
      }
      @include container-breakpoint-lg {
        max-width: 774px;
      }
      @include container-breakpoint-xl {
        max-width: 960px;
      }
    }
    &--variant-bleed {
      max-width: 1680px;
      margin: auto;
      padding-right: 20px;
      padding-left: 20px;
      @include container-breakpoint-xs {
        padding-left: 0;
        padding-right: 0;
      }
      @include container-breakpoint-sm {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &--overlay-top {
      position: relative;
      top: -3.75rem;
      z-index: 2;
    }
  }
}
