/*
References:

https://digitaldesign.vattenfall.com/responsive 
 widths  375 - 667 - 768 - 1024 - 1920
*/

$breakpoint-phone-portrait: 375px;
$breakpoint-phone-landscape: 599px; // TODO: where is the reference to this number?
$breakpoint-tablet-portrait: 768px;
$breakpoint-tablet-landscape: 1024px;

// 0-599px
@mixin breakpoint-xs {
  @media (max-width: $breakpoint-phone-landscape) {
    @content;
  }
}
// 600-768px
@mixin breakpoint-sm {
  @media (min-width: $breakpoint-phone-landscape + 1) and (max-width: $breakpoint-tablet-portrait) {
    @content;
  }
}
// 769-1024px
@mixin breakpoint-md {
  @media (min-width: $breakpoint-tablet-portrait + 1) and (max-width: $breakpoint-tablet-landscape) {
    @content;
  }
}
// 1025px - ...
@mixin breakpoint-lg {
  @media (min-width: $breakpoint-tablet-landscape + 1) {
    @content;
  }
}

/*
  Container Breakpoints inherited from Vattenfall Digital Design System.
*/

$container-breakpoint-phone-landscape: 576px;
$container-breakpoint-tablet-portrait: 768px;
$container-breakpoint-tablet-landscape: 992px;
$container-breakpoint-desktop: 1200px;

// 0-576px
@mixin container-breakpoint-xs {
  @media (max-width: $container-breakpoint-phone-landscape) {
    @content;
  }
}
// 577-768px
@mixin container-breakpoint-sm {
  @media (min-width: $container-breakpoint-phone-landscape + 1) and (max-width: $container-breakpoint-tablet-portrait) {
    @content;
  }
}
// 769-992px
@mixin container-breakpoint-md {
  @media (min-width: $container-breakpoint-tablet-portrait + 1) and (max-width: $container-breakpoint-tablet-landscape) {
    @content;
  }
}
// 993-1200px
@mixin container-breakpoint-lg {
  @media (min-width: $container-breakpoint-tablet-landscape + 1) and (max-width: $container-breakpoint-desktop) {
    @content;
  }
}
// 1201px - ...
@mixin container-breakpoint-xl {
  @media (min-width: $container-breakpoint-desktop + 1) {
    @content;
  }
}