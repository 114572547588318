/* primary brand colors */
$color-solar-yellow: #ffda00;
$color-ocean-blue: #2071b5;
$color-coal-black: #222222;
$color-magnetic-grey: #4e4b48;
$color-aura-white: #ffffff;

/* secondary web colors */
$color-dark-green: #005c63;
$color-green: #3dc07c;
$color-pink: #d1266b;
$color-purple: #9b62c3;
$color-dark-purple: #85254b;
$color-red: #f93b18;
$color-orange: #f66000;

/* background web colors */
$color-pastel-yellow: #fffde5;
$color-pastel-green: #edf9f3;
$color-pastel-turquoise: #ebf2f3;
$color-pastel-blue: #edf1f6;
$color-pastel-red: #fef0ea;
$color-pastel-grey: #f2f2f2;

/* tertiary web colors */
$color-light-grey: #ccc;
$color-medium-grey: #999;
$color-ash-blue: #869bad;
$color-dark-ash-blue: #69788c;
$color-disabled: #eee;
$color-disabled-text: #767676;

/* misc colors */
$color-ocean-blue-hover: #2071b5;
$color-ocean-blue-active: #1e324f;
$color-contrast-ok-orange: #f66000;
$color-vattenfall-yellow-button-hover: #ffe85b;
$color-vattenfall-yellow-button-pressed: #f1c50b;
$color-vattenfall-button-text-disabled: #666;
$color-vattenfall-blue-button-pressed: #1e324f;
$color-vattenfall-blue-button-main-state: #1964a3;
$color-vattenfall-input-border-hover: $color-medium-grey;
$color-vattenfall-input-border: $color-light-grey;
$color-vattenfall-input-disabled: $color-disabled;

$pastel-colors: (
  green: $color-pastel-green,
  blue: $color-pastel-blue
);

/* todo: setting temporary color for focus borders 
while awaiting requirements */
$color-focus: $color-ocean-blue; // $color-contrast-ok-orange;
