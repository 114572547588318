@import "./vf-font-faces";
@import "./vf-icons-font";
@import "./colors";
@import "./breakpoints";

/* 
This file is a mix of two graphical sources:
Vattenfall official styleguide
https://digitaldesign.vattenfall.com/css/text
"my-pages contract"
https://vattenfall.invisionapp.com/share/42U9SSSVANU#/screens/393223332

Provide style as a class and a mixin, to be able to use 
in projected content.

The classes and mixins have syntax to specify size, weight and line-height.
The intention of this formilized syntax is to assemble the set of fontstyles
into tag-agnostic sets. This means for example that a h1 may have different 
appearance. 

See the fonts.stories.ts for examples.

mixin codes::
@mixin fonts-[size]-[weight]-[lineheight]

usage:
p {
  @include font-fs16-fw5-lh22;
}

All fontstyle codes within the ui-lib are:
font-fs32-fw9-lh38 (largest heading : info-table-heading)
font-fs26-fw9-lh30
font-fs20-fw6-lh24 (dashboard titel, mix of project design and implementation based on ddb)
font-fs18-fw5-lh24 (ui-button)
font-fs16-fw7-lh30 
font-fs16-fw5-lh19 (small condensed: links, buttons, small heading)
font-fs16-fw4-lh19 (select-input-item-primary-title)
font-fs16-fw4-lh28 (default)
font-fs16-fw5-lh22 (dt,dd in for example key-value-list)
font-fs16-fw4-lh24
font-fs16-fw4-lh22 (key-value-list: dt, dd)
font-fs14-fw5-lh20 (key-value-list: dt, dd)
font-fs14-fw5-lh18 (ui-button)
font-fs14-fw4-lh20 (key-value-list: dt, dd)
font-fs14-fw4-lh14 (selector: .select-input-item-secondary-title)
font-fs12-fw5-lh14 
font-fs12-fw4-lh19
font-icon

The ddb system:
font-fs72-fw6-lh84
font-fs52-fw6-lh72
font-fs36-fw6-lh40
font-fs28-fw6-lh37
font-fs24-fw6-lh28
font-fs24-fw6-lh36
font-fs24-fw6-lh36
font-fs24-fw5-lh40
font-fs20-fw5-lh36
font-fs12-fw6-lh22
font-fs16-fw6-lh36
font-fs16-fw4-lh28

The design for 'mina avtal' and 'mina fakturor':
font-fs52-fw7-lh72

The current 'mina fakturor' mobile:
font-fs22-fw7-lh28

Guidelines:
Favor usage of mixins.
Default to 'inherit'.
Avoid style on elements. (h1 may for example be used inside an article anywhere on the page, and
may have other size than h1 inside the main)
*/

@mixin font-fs16-fw4-lh28 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  font-size: 1rem; // 16px
  line-height: 1.75rem; // 28px
  color: $color-coal-black;
}

.font-fs16-fw4-lh28 {
  @include font-fs16-fw4-lh28;
}

@mixin font-icon {
  font-family: "Vattenfall Icons", "sans-serif";
  user-select: none;
  font-style: normal;
}
.font-icon {
  @include font-icon;
}

/* font-fs20-fw6-lh24: Used in main dashboard widget titles 'mina avtal', 'mina fakturor' and more*/
@mixin font-fs20-fw6-lh24 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0rem;
  font-size: 1.25rem; // 20px
  line-height: 1.5rem; // 24px
}
.font-fs20-fw6-lh24 {
  @include font-fs20-fw6-lh24;
}

@mixin font-fs52-fw6-lh72 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0rem;
  font-size: 3.25rem; // 52px
  line-height: 4.5rem; // 72px
}
@mixin font-fs22-fw7-lh28 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0rem;
  font-size: 1.375rem; //22px;
  line-height: 1.75rem; //28px;
}
/* font-fs52-fw6-lh72: Used in main title 'mina avtal', 'mina fakturor' */
.font-fs52-fw6-lh72 {
  @include font-fs52-fw6-lh72;
  @include breakpoint-xs {
    @include font-fs22-fw7-lh28;
  }
}

/* font-fs26-fw9-lh30 */
@mixin font-fs26-fw9-lh30 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 900;
  letter-spacing: 0rem;
  font-size: 1.625rem; // 26px
  line-height: 1.875; //30px
}
.font-fs26-fw9-lh30 {
  @include font-fs26-fw9-lh30;
}
/* font-fs26-fw6-lh28 */
@mixin font-fs26-fw6-lh28 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0rem;
  font-size: 1.625rem; // 26px
  line-height: 1.75; //28px
}
.font-fs26-fw6-lh28 {
  @include font-fs26-fw6-lh28;
}

/* font-fs32-fw9-lh38: Used in info-table-heading */
@mixin font-fs32-fw9-lh38 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 900;
  letter-spacing: 0rem;
  font-size: 2rem; // 32px
  line-height: 2.375rem; // 38px
}
@mixin font-fs32-fw4-lh40 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0rem;
  font-size: 2rem; // 32px
  line-height: 2.5rem; // 40px
}
@mixin font-fs22-fw9-lh26 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 900;
  font-size: 1.375rem; //22px;
  line-height: 1.625rem; //26px;
}
.font-fs32-fw9-lh38 {
  @include font-fs32-fw9-lh38;
  @include breakpoint-xs {
    @include font-fs22-fw9-lh26;
  }
}
.font-fs32-fw4-lh40 {
  @include font-fs32-fw4-lh40;
}

/* font-fs16-fw4-lh19: Used in info-table-heading and accordion */
@mixin font-fs16-fw4-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem; //16px;
  font-weight: 400;
  line-height: 1.1875rem; //19px;
}
.font-fs16-fw4-lh19 {
  @include font-fs16-fw4-lh19;
}

/* font-fs14-fw6-lh19: Used in flows-premise-contract-summary */
@mixin font-fs14-fw6-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem; // 14px;
  font-weight: 600;
  line-height: 1.1875rem; //19px;
}
.font-fs14-fw6-lh19 {
  @include font-fs14-fw6-lh19;
}

/* font-fs14-fw4-lh18 Used in my-contact-notification-dialog */
@mixin font-fs14-fw4-lh18 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem; // 14px;
  font-weight: 400;
  line-height: 1.125rem; //18px;
}
.font-fs14-fw4-lh18 {
  @include font-fs14-fw4-lh18;
}

/* font-fs14-fw5-lh18: Used in ui-button */
@mixin font-fs14-fw5-lh18 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem; // 14px;
  font-weight: 500;
  line-height: 1.125rem; //18px;
}
.font-fs14-fw5-lh18 {
  @include font-fs14-fw5-lh18;
}

/* font-fs14-fw4-lh19: Used in flows-premise-contract-summary */
@mixin font-fs14-fw4-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem; // 14px;
  font-weight: 400;
  line-height: 1.1875rem; //19px;
}
.font-fs14-fw4-lh19 {
  @include font-fs14-fw4-lh19;
}

/* font-fs14-fw4-lh17: Used in flows-premise-contract-summary */
@mixin font-fs14-fw4-lh17 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem; // 14px;
  font-weight: 400;
  line-height: 1.0625rem; //17px;
}
.font-fs14-fw4-lh17 {
  @include font-fs14-fw4-lh17;
}

/* font-fs14-fw4-lh14: Used in select-input */
@mixin font-fs14-fw4-lh14 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem; // 14px;
  font-weight: 400;
  line-height: 0.875rem; //14px;
}
.font-fs14-fw4-lh14 {
  @include font-fs14-fw4-lh14;
}

/* font-fs12-fw5-lh14 */
// Used in Submit Section in Renewal flow
@mixin font-fs12-fw5-lh14 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.75rem; // 12px;
  font-weight: 500;
  line-height: 0.875rem; //14px;
}
.font-fs12-fw5-lh14 {
  @include font-fs12-fw5-lh14;
}

/* font-fs12-fw4-lh19 */
@mixin font-fs12-fw4-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.75rem; // 12px;
  font-weight: 400;
  line-height: 1.1875rem; //19px;
}
.font-fs12-fw4-lh19 {
  @include font-fs12-fw4-lh19;
}

/* font-fs18-fw4-lh28: Used in checkout top sections */
@mixin font-fs18-fw4-lh28 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1.125rem; //18px;
  font-weight: 400;
  line-height: 1.75rem; //24px;
}
.font-fs18-fw4-lh28 {
  @include font-fs18-fw4-lh28;
}

/* font-fs18-fw5-lh24: Used in ui-button */
@mixin font-fs18-fw5-lh24 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1.125rem; //18px;
  font-weight: 500;
  line-height: 1.5rem; //24px;
}
.font-fs18-fw5-lh24 {
  @include font-fs18-fw5-lh24;
}

/* font-fs18-fw5-lh24: Used in checkout top section */
@mixin font-fs18-fw6-lh24 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1.125rem; //18px;
  font-weight: 600;
  line-height: 1.5rem; //24px;
}

.font-fs18-fw6-lh24 {
  @include font-fs18-fw6-lh24;
}
// This is also defined in 'app.component' to be able to style on index.html
@mixin font-fs16-fw5-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem; //16px;
  font-weight: 500;
  line-height: 1.1875rem; //19px;
}
.font-fs16-fw5-lh19 {
  @include font-fs16-fw5-lh19;
}

@mixin font-fs16-fw7-lh30 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem; //16px;
  font-weight: 700;
  line-height: 1.875; //30px;
}
.font-fs16-fw7-lh30 {
  @include font-fs16-fw7-lh30;
}

//used in header of flows-premise-contract-summary
@mixin font-fs16-fw6-lh22 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem; // 16px;
  font-weight: 600;
  line-height: 1.375rem; //22px;
}
.font-fs16-fw6-lh22 {
  @include font-fs16-fw6-lh22;
}

//used in premise selector of flows-premises-select
@mixin font-fs16-fw6-lh28 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem; // 16px;
  font-weight: 600;
  line-height: 1.75rem; //28px;
}
.font-fs16-fw6-lh28 {
  @include font-fs16-fw6-lh28;
}

//used in header of flows-confirmation-renewal-section
@mixin font-fs16-fw6-lh32 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem; // 16px;
  font-weight: 600;
  line-height: 2rem; //32px;
}
.font-fs16-fw6-lh32 {
  @include font-fs16-fw6-lh32;
}

@mixin font-fs16-fw5-lh22 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem; // 16px;
  font-weight: 500;
  line-height: 1.375rem; //22px;
}
@mixin font-fs16-fw4-lh24 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem; // 16px;
  font-weight: 400;
  line-height: 1.5rem; //24px;
}
.font-fs16-fw4-lh24 {
  @include font-fs16-fw4-lh24;
}

@mixin font-fs16-fw4-lh20 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem; // 16px;
  font-weight: 400;
  line-height: 1.25rem; //20px;
}
.font-fs16-fw4-lh20 {
  @include font-fs16-fw4-lh20;
}

@mixin font-fs14-fw5-lh20 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem; // 14px;
  font-weight: 500;
  line-height: 1.25rem; //20px;
}
.font-fs14-fw5-lh20 {
  @include font-fs14-fw5-lh20;
}
.font-fs16-fw5-lh22 {
  @include font-fs16-fw5-lh22;
  @include breakpoint-xs {
    @include font-fs14-fw5-lh20;
  }
}

// info-table, key-value-list{
@mixin font-fs16-fw4-lh22 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem; // 16px;
  font-weight: 400;
  line-height: 1.375rem; //22px;
}
@mixin font-fs14-fw4-lh20 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem; // 14px;
  font-weight: 400;
  line-height: 1.25rem; //20px;
}
.font-fs16-fw4-lh22 {
  @include font-fs16-fw4-lh22;
  @include breakpoint-xs {
    @include font-fs14-fw4-lh20;
  }
}
// info-table, key-value-list {
@mixin font-fs16-fw5-lh22 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem; // 16px;
  font-weight: 500;
  line-height: 1.375rem; //22px
}
@mixin font-fs14-fw5-lh20 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem; // 14px
  font-weight: 500;
  line-height: 1.25rem; //20px
}
.font-fs16-fw5-lh22 {
  @include font-fs16-fw5-lh22;
  @include breakpoint-xs {
    @include font-fs14-fw5-lh20;
  }
}

/* used in info-table-footer fonts-a */
@mixin font-fs16-fw5-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem; // 16px;
  font-weight: 500;
  line-height: 1.1875rem; //19px;
}
.font-fs16-fw5-lh19 {
  @include font-fs16-fw5-lh19;
}

//used in header of flows-confirmation-renewal-section
@mixin font-fs24-fw8-lh32 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1.5rem; // 24px;
  font-weight: 800;
  line-height: 2rem; //32px;
}
.font-fs24-fw8-lh32 {
  @include font-fs24-fw8-lh32;
}
