@charset "UTF-8";
/*
 These components can be styled from outside the #shadow-root
 */
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 300;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-Light.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Light.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Light.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 300;
  font-style: italic;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-LightItalic.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-LightItalic.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-LightItalic.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 400;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-Regular.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Regular.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Regular.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 400;
  font-style: italic;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-RegularItalic.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-RegularItalic.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-RegularItalic.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 500;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-Medium.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Medium.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Medium.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 500;
  font-style: italic;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-MediumItalic.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-MediumItalic.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-MediumItalic.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall Display";
  font-weight: 600;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHallDisplay-Bold.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHallDisplay-Bold.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHallDisplay-Bold.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 600;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-Bold.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Bold.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Bold.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 600;
  font-style: italic;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-BoldItalic.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-BoldItalic.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-BoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 700;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-Heavy.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Heavy.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Heavy.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 700;
  font-style: italic;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-HeavyItalic.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-HeavyItalic.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-HeavyItalic.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Icons";
  src: url("https://cdn.vattenfall.nl/vattenfallnlprd/1.2.0/assets/fonts/Vattenfall-Icons.eot") format("embedded-opentype"), url("https://cdn.vattenfall.nl/vattenfallnlprd/1.2.0/assets/fonts/Vattenfall-Icons.woff") format("woff"), url("https://cdn.vattenfall.nl/vattenfallnlprd/1.2.0/assets/fonts/Vattenfall-Icons.woff2") format("woff2"), url("https://cdn.vattenfall.nl/vattenfallnlprd/1.2.0/assets/fonts/Vattenfall-Icons.ttf") format("truetype");
}
@font-face {
  font-family: "Vattenfall Icons";
  src: url("https://elements.vattenfall.se/fonts/VattenfallElements-Icons.eot") format("embedded-opentype"), url("https://elements.vattenfall.se/fonts/VattenfallElements-Icons.woff") format("woff"), url("https://elements.vattenfall.se/fonts/VattenfallElements-Icons.ttf") format("truetype");
}
i[class^=vf-icon-]:before,
i[class*=" vf-icon-"]:before {
  font-family: "Vattenfall Icons", sans-serif !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vf-icon-VF_add_contact_rgb:before {
  content: "";
}

.vf-icon-VF_alert_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_down_left_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_down_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_down_right_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_left_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_right_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_up_left_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_up_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_up_right_rgb:before {
  content: "";
}

.vf-icon-VF_calendar_rgb:before {
  content: "";
}

.vf-icon-VF_call_rgb:before {
  content: "";
}

.vf-icon-VF_camera_rgb:before {
  content: "";
}

.vf-icon-VF_chat_rgb:before {
  content: "";
}

.vf-icon-VF_checkbox_rgb:before {
  content: "";
}

.vf-icon-VF_download_pdf_rgb:before {
  content: "";
}

.vf-icon-VF_download_rgb:before {
  content: "";
}

.vf-icon-VF_edit_rgb:before {
  content: "";
}

.vf-icon-VF_elipsis_rgb:before, .vattenfall-elements__edit-link:before {
  content: "";
}

.vf-icon-VF_eye_rgb:before {
  content: "";
}

.vf-icon-VF_favourite_rgb:before {
  content: "";
}

.vf-icon-VF_gallery_rgb:before {
  content: "";
}

.vf-icon-VF_guide_icon_rgb:before {
  content: "";
}

.vf-icon-VF_heart_rgb:before {
  content: "";
}

.vf-icon-VF_help_rgb:before {
  content: "";
}

.vf-icon-VF_information_rgb:before {
  content: "";
}

.vf-icon-VF_locked_rgb:before {
  content: "";
}

.vf-icon-VF_mail_rgb:before {
  content: "";
}

.vf-icon-VF_maps_rgb:before {
  content: "";
}

.vf-icon-VF_nina_avatar_background_rgb:before {
  content: "";
}

.vf-icon-VF_nina_happy_avatar_rgb:before {
  content: "";
}

.vf-icon-VF_notvisible_rgb:before {
  content: "";
}

.vf-icon-VF_off_rgb:before {
  content: "";
}

.vf-icon-VF_open_new_window_rgb:before {
  content: "";
}

.vf-icon-VF_pause_rgb:before {
  content: "";
}

.vf-icon-VF_phone_rgb:before {
  content: "";
}

.vf-icon-VF_pin_rgb:before {
  content: "";
}

.vf-icon-VF_play_rgb:before {
  content: "";
}

.vf-icon-VF_print_rgb:before {
  content: "";
}

.vf-icon-VF_settings_rgb:before {
  content: "";
}

.vf-icon-VF_share_rgb:before {
  content: "";
}

.vf-icon-VF_shop_rgb:before {
  content: "";
}

.vf-icon-VF_sign_out_rgb:before {
  content: "";
}

.vf-icon-VF_sort_rgb:before {
  content: "";
}

.vf-icon-VF_tag_rgb:before {
  content: "";
}

.vf-icon-VF_unlocked_rgb:before {
  content: "";
}

.vf-icon-VF_user_rgb:before {
  content: "";
}

.vf-icon-VF_zoom_in_rgb:before {
  content: "";
}

.vf-icon-VF_zoom_out_rgb:before {
  content: "";
}

.vf-icon-VF_allocate_agent_rgb:before {
  content: "";
}

.vf-icon-VF_allocate_location_rgb:before {
  content: "";
}

.vf-icon-VF_allocate_party_rgb:before {
  content: "";
}

.vf-icon-VF_allocate_team_rgb:before {
  content: "";
}

.vf-icon-VF_promotion_rgb:before {
  content: "";
}

.vf-icon-VF_Sustainable_use_of_resources_rgb:before {
  content: "";
}

.vf-icon-VF_airplane_rgb:before {
  content: "";
}

.vf-icon-VF_apartment_house_rgb:before {
  content: "";
}

.vf-icon-VF_battery_rgb:before {
  content: "";
}

.vf-icon-VF_benchmark_co2_rgb:before {
  content: "";
}

.vf-icon-VF_benchmark_cockpit_rgb:before {
  content: "";
}

.vf-icon-VF_benchmark_rgb:before {
  content: "";
}

.vf-icon-VF_benchmark_temperature_rgb:before {
  content: "";
}

.vf-icon-VF_benefit_rgb:before {
  content: "";
}

.vf-icon-VF_biofuel-biomass_rgb:before {
  content: "";
}

.vf-icon-VF_brainstorm_rgb:before {
  content: "";
}

.vf-icon-VF_callback_rgb:before {
  content: "";
}

.vf-icon-VF_chart_rgb:before {
  content: "";
}

.vf-icon-VF_city_rgb:before {
  content: "";
}

.vf-icon-VF_clock_rgb:before {
  content: "";
}

.vf-icon-VF_coal_rgb:before {
  content: "";
}

.vf-icon-VF_cold_rgb:before {
  content: "";
}

.vf-icon-VF_combined_heat_and_power_rgb:before {
  content: "";
}

.vf-icon-VF_consumption_rgb:before {
  content: "";
}

.vf-icon-VF_contract_details_rgb:before {
  content: "";
}

.vf-icon-VF_corporate_rgb:before {
  content: "";
}

.vf-icon-VF_cost_saving_rgb:before {
  content: "";
}

.vf-icon-VF_cottage_rgb:before {
  content: "";
}

.vf-icon-VF_currency_rgb:before {
  content: "";
}

.vf-icon-VF_defence_rgb:before {
  content: "";
}

.vf-icon-VF_discussion_B_rgb:before {
  content: "";
}

.vf-icon-VF_discussion_rgb:before {
  content: "";
}

.vf-icon-VF_distribution_rgb:before {
  content: "";
}

.vf-icon-VF_dont_rgb:before {
  content: "";
}

.vf-icon-VF_e-bike_rgb:before {
  content: "";
}

.vf-icon-VF_e-mobility_rgb:before {
  content: "";
}

.vf-icon-VF_economize_rgb:before {
  content: "";
}

.vf-icon-VF_eko_hourly_spot_rgb:before {
  content: "";
}

.vf-icon-VF_electricity_rgb:before {
  content: "";
}

.vf-icon-VF_electronic_payment_euro_rgb:before {
  content: "";
}

.vf-icon-VF_energy_mix_rgb:before {
  content: "";
}

.vf-icon-VF_energy_solutions_rgb:before {
  content: "";
}

.vf-icon-VF_euro_rgb:before {
  content: "";
}

.vf-icon-VF_evaluation_rgb:before {
  content: "";
}

.vf-icon-VF_farm_rgb:before {
  content: "";
}

.vf-icon-VF_fax_rgb:before {
  content: "";
}

.vf-icon-VF_full_water_quartal_icon_rgb:before {
  content: "";
}

.vf-icon-VF_gas_rgb:before {
  content: "";
}

.vf-icon-VF_globe_rgb:before {
  content: "";
}

.vf-icon-VF_good_deal_rgb:before {
  content: "";
}

.vf-icon-VF_group_discussion_rgb:before {
  content: "";
}

.vf-icon-VF_heating_rgb:before {
  content: "";
}

.vf-icon-VF_hot_rgb:before {
  content: "";
}

.vf-icon-VF_house_rgb:before {
  content: "";
}

.vf-icon-VF_hydro_rgb:before {
  content: "";
}

.vf-icon-VF_in_queue_rgb:before {
  content: "";
}

.vf-icon-VF_industry_rgb:before {
  content: "";
}

.vf-icon-VF_invoices_rgb:before {
  content: "";
}

.vf-icon-VF_large_battery_rgb:before {
  content: "";
}

.vf-icon-VF_lecture_rgb:before {
  content: "";
}

.vf-icon-VF_life_events_rgb:before {
  content: "";
}

.vf-icon-VF_light_bulb_rgb:before {
  content: "";
}

.vf-icon-VF_manage_my_team_rgb:before {
  content: "";
}

.vf-icon-VF_mobility_rgb:before {
  content: "";
}

.vf-icon-VF_modules_rgb:before {
  content: "";
}

.vf-icon-VF_move_with_existing_contract_rgb:before {
  content: "";
}

.vf-icon-VF_move_with_new_contract_rgb:before {
  content: "";
}

.vf-icon-VF_multi_home_box_rgb:before {
  content: "";
}

.vf-icon-VF_my_cases_rgb:before {
  content: "";
}

.vf-icon-VF_my_documents_rgb:before {
  content: "";
}

.vf-icon-VF_nighttime_electricity_metering_rgb:before {
  content: "";
}

.vf-icon-VF_nuclear_rgb:before {
  content: "";
}

.vf-icon-VF_ocean_energy_rgb:before {
  content: "";
}

.vf-icon-VF_offshore_rgb:before {
  content: "";
}

.vf-icon-VF_oil_condesing_gas_turbine_rgb:before {
  content: "";
}

.vf-icon-VF_partners_rgb:before {
  content: "";
}

.vf-icon-VF_peat_rgb:before {
  content: "";
}

.vf-icon-VF_planned_outage_rgb:before {
  content: "";
}

.vf-icon-VF_policies_rgb:before {
  content: "";
}

.vf-icon-VF_power_grid_rgb:before {
  content: "";
}

.vf-icon-VF_power_plant_rgb:before {
  content: "";
}

.vf-icon-VF_premium_rgb:before {
  content: "";
}

.vf-icon-VF_present_rgb:before {
  content: "";
}

.vf-icon-VF_private_end_user_rgb:before {
  content: "";
}

.vf-icon-VF_private_rgb:before {
  content: "";
}

.vf-icon-VF_recycling_rgb:before {
  content: "";
}

.vf-icon-VF_salary_time_and_benefits_rgb:before {
  content: "";
}

.vf-icon-VF_semi_detached_house_rgb:before {
  content: "";
}

.vf-icon-VF_ship_docking_rgb:before {
  content: "";
}

.vf-icon-VF_smart_home_rgb:before {
  content: "";
}

.vf-icon-VF_smartphone_text_rgb:before {
  content: "";
}

.vf-icon-VF_solar_panel_rgb:before {
  content: "";
}

.vf-icon-VF_solar_power_rgb:before {
  content: "";
}

.vf-icon-VF_standard_contract_continuos_rgb:before {
  content: "";
}

.vf-icon-VF_support_rgb:before {
  content: "";
}

.vf-icon-VF_temperature_rgb:before {
  content: "";
}

.vf-icon-VF_voice_search_rgb:before {
  content: "";
}

.vf-icon-VF_wind_rgb:before {
  content: "";
}

.vf-icon-VF_card_and_tag_rgb:before {
  content: "";
}

.vf-icon-VF_charge_up_your_business_rgb:before {
  content: "";
}

.vf-icon-VF_charging_wall_box_rgb:before {
  content: "";
}

.vf-icon-VF_parking_meter_rgb:before {
  content: "";
}

.vf-icon-VF_partner_rgb:before {
  content: "";
}

.vf-icon-VF_private_charging_rgb:before {
  content: "";
}

.vf-icon-VF_public_business_charging_rgb:before {
  content: "";
}

.vf-icon-VF_public_charging_rgb:before {
  content: "";
}

.vf-icon-VF_scooter_2_rgb:before {
  content: "";
}

.vf-icon-VF_scooter_rgb:before {
  content: "";
}

.vf-icon-VF_smart_charging_poles_rgb:before {
  content: "";
}

.vf-icon-VF_check_rgb:before {
  content: "";
}

.vf-icon-VF_close_rgb:before {
  content: "";
}

.vf-icon-VF_down_rgb:before {
  content: "";
}

.vf-icon-VF_filter_rgb:before {
  content: "";
}

.vf-icon-VF_home_rgb:before {
  content: "";
}

.vf-icon-VF_left_rgb:before {
  content: "";
}

.vf-icon-VF_less_info_rgb:before {
  content: "";
}

.vf-icon-VF_menu_rgb:before {
  content: "";
}

.vf-icon-VF_more_rgb:before {
  content: "";
}

.vf-icon-VF_refresh_rgb:before {
  content: "";
}

.vf-icon-VF_right_rgb:before {
  content: "";
}

.vf-icon-VF_rss_rgb:before {
  content: "";
}

.vf-icon-VF_search_rgb:before {
  content: "";
}

.vf-icon-VF_send_rgb:before {
  content: "";
}

.vf-icon-VF_up_rgb:before {
  content: "";
}

.vf-icon-VF_TV_remote_rgb:before {
  content: "";
}

.vf-icon-VF_alarm_rgb:before {
  content: "";
}

.vf-icon-VF_cloudy_rgb:before {
  content: "";
}

.vf-icon-VF_coffee_maker_rgb:before {
  content: "";
}

.vf-icon-VF_console_rgb:before {
  content: "";
}

.vf-icon-VF_desklamp_rgb:before {
  content: "";
}

.vf-icon-VF_dishwasher_rgb:before {
  content: "";
}

.vf-icon-VF_door_closed_rgb:before {
  content: "";
}

.vf-icon-VF_door_open_rgb:before {
  content: "";
}

.vf-icon-VF_dryer_rgb:before {
  content: "";
}

.vf-icon-VF_fridge_freezer_rgb:before {
  content: "";
}

.vf-icon-VF_ice_rgb:before {
  content: "";
}

.vf-icon-VF_kettle_rgb:before {
  content: "";
}

.vf-icon-VF_laptop_rgb:before {
  content: "";
}

.vf-icon-VF_light_switch_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_step_1_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_step_2_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_step_3_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_step_4_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_step_5_rgb:before {
  content: "";
}

.vf-icon-VF_microwave_rgb:before {
  content: "";
}

.vf-icon-VF_moon_rgb:before {
  content: "";
}

.vf-icon-VF_phone_charging_rgb:before {
  content: "";
}

.vf-icon-VF_radiator_rgb:before {
  content: "";
}

.vf-icon-VF_rain_rgb:before {
  content: "";
}

.vf-icon-VF_router_rgb:before {
  content: "";
}

.vf-icon-VF_smart_plug_rgb:before {
  content: "";
}

.vf-icon-VF_smoke_alarm_rgb:before {
  content: "";
}

.vf-icon-VF_snow_rgb:before {
  content: "";
}

.vf-icon-VF_sun_rgb:before {
  content: "";
}

.vf-icon-VF_table_lamp_2_rgb:before {
  content: "";
}

.vf-icon-VF_table_lamp_rgb:before {
  content: "";
}

.vf-icon-VF_thunder_rgb:before {
  content: "";
}

.vf-icon-VF_ventilator_rgb:before {
  content: "";
}

.vf-icon-VF_washing_machine_rgb:before {
  content: "";
}

.vf-icon-VF_window_blinds_rgb:before {
  content: "";
}

.vf-icon-VF_window_closed_rgb:before {
  content: "";
}

.vf-icon-VF_window_open_rgb:before {
  content: "";
}

.vf-icon-VF_window_rgb:before {
  content: "";
}

.vf-icon-VF_dropbox_rgb:before {
  content: "";
}

.vf-icon-VF_facebook_rgb:before {
  content: "";
}

.vf-icon-VF_flickr_rgb:before {
  content: "";
}

.vf-icon-VF_google_plus_rgb:before {
  content: "";
}

.vf-icon-VF_instagram_rgb:before {
  content: "";
}

.vf-icon-VF_linkedin_rgb:before {
  content: "";
}

.vf-icon-VF_pinterest_rgb:before {
  content: "";
}

.vf-icon-VF_skype_rgb:before {
  content: "";
}

.vf-icon-VF_slideshare_rgb:before {
  content: "";
}

.vf-icon-VF_snapchat_rgb:before {
  content: "";
}

.vf-icon-VF_twitter_rgb:before {
  content: "";
}

.vf-icon-VF_vimeo_rgb:before {
  content: "";
}

.vf-icon-VF_xing_rgb:before {
  content: "";
}

.vf-icon-VF_youtube_rgb:before {
  content: "";
}

/* primary brand colors */
/* secondary web colors */
/* background web colors */
/* tertiary web colors */
/* misc colors */
/* todo: setting temporary color for focus borders 
while awaiting requirements */
/*
References:

https://digitaldesign.vattenfall.com/responsive 
 widths  375 - 667 - 768 - 1024 - 1920
*/
/*
  Container Breakpoints inherited from Vattenfall Digital Design System.
*/
/* 
This file is a mix of two graphical sources:
Vattenfall official styleguide
https://digitaldesign.vattenfall.com/css/text
"my-pages contract"
https://vattenfall.invisionapp.com/share/42U9SSSVANU#/screens/393223332

Provide style as a class and a mixin, to be able to use 
in projected content.

The classes and mixins have syntax to specify size, weight and line-height.
The intention of this formilized syntax is to assemble the set of fontstyles
into tag-agnostic sets. This means for example that a h1 may have different 
appearance. 

See the fonts.stories.ts for examples.

mixin codes::
@mixin fonts-[size]-[weight]-[lineheight]

usage:
p {
  @include font-fs16-fw5-lh22;
}

All fontstyle codes within the ui-lib are:
font-fs32-fw9-lh38 (largest heading : info-table-heading)
font-fs26-fw9-lh30
font-fs20-fw6-lh24 (dashboard titel, mix of project design and implementation based on ddb)
font-fs18-fw5-lh24 (ui-button)
font-fs16-fw7-lh30 
font-fs16-fw5-lh19 (small condensed: links, buttons, small heading)
font-fs16-fw4-lh19 (select-input-item-primary-title)
font-fs16-fw4-lh28 (default)
font-fs16-fw5-lh22 (dt,dd in for example key-value-list)
font-fs16-fw4-lh24
font-fs16-fw4-lh22 (key-value-list: dt, dd)
font-fs14-fw5-lh20 (key-value-list: dt, dd)
font-fs14-fw5-lh18 (ui-button)
font-fs14-fw4-lh20 (key-value-list: dt, dd)
font-fs14-fw4-lh14 (selector: .select-input-item-secondary-title)
font-fs12-fw5-lh14 
font-fs12-fw4-lh19
font-icon

The ddb system:
font-fs72-fw6-lh84
font-fs52-fw6-lh72
font-fs36-fw6-lh40
font-fs28-fw6-lh37
font-fs24-fw6-lh28
font-fs24-fw6-lh36
font-fs24-fw6-lh36
font-fs24-fw5-lh40
font-fs20-fw5-lh36
font-fs12-fw6-lh22
font-fs16-fw6-lh36
font-fs16-fw4-lh28

The design for 'mina avtal' and 'mina fakturor':
font-fs52-fw7-lh72

The current 'mina fakturor' mobile:
font-fs22-fw7-lh28

Guidelines:
Favor usage of mixins.
Default to 'inherit'.
Avoid style on elements. (h1 may for example be used inside an article anywhere on the page, and
may have other size than h1 inside the main)
*/
.font-fs16-fw4-lh28 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  font-size: 1rem;
  line-height: 1.75rem;
  color: #222222;
}

.font-icon {
  font-family: "Vattenfall Icons", "sans-serif";
  user-select: none;
  font-style: normal;
}

/* font-fs20-fw6-lh24: Used in main dashboard widget titles 'mina avtal', 'mina fakturor' and more*/
.font-fs20-fw6-lh24 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0rem;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

/* font-fs52-fw6-lh72: Used in main title 'mina avtal', 'mina fakturor' */
.font-fs52-fw6-lh72 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0rem;
  font-size: 3.25rem;
  line-height: 4.5rem;
}
@media (max-width: 599px) {
  .font-fs52-fw6-lh72 {
    font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
    font-weight: 700;
    letter-spacing: 0rem;
    font-size: 1.375rem;
    line-height: 1.75rem;
  }
}

/* font-fs26-fw9-lh30 */
.font-fs26-fw9-lh30 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 900;
  letter-spacing: 0rem;
  font-size: 1.625rem;
  line-height: 1.875;
}

/* font-fs26-fw6-lh28 */
.font-fs26-fw6-lh28 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0rem;
  font-size: 1.625rem;
  line-height: 1.75;
}

/* font-fs32-fw9-lh38: Used in info-table-heading */
.font-fs32-fw9-lh38 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 900;
  letter-spacing: 0rem;
  font-size: 2rem;
  line-height: 2.375rem;
}
@media (max-width: 599px) {
  .font-fs32-fw9-lh38 {
    font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
    font-weight: 900;
    font-size: 1.375rem;
    line-height: 1.625rem;
  }
}

.font-fs32-fw4-lh40 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0rem;
  font-size: 2rem;
  line-height: 2.5rem;
}

/* font-fs16-fw4-lh19: Used in info-table-heading and accordion */
.font-fs16-fw4-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1875rem;
}

/* font-fs14-fw6-lh19: Used in flows-premise-contract-summary */
.font-fs14-fw6-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.1875rem;
}

/* font-fs14-fw4-lh18 Used in my-contact-notification-dialog */
.font-fs14-fw4-lh18 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
}

/* font-fs14-fw5-lh18: Used in ui-button */
.font-fs14-fw5-lh18 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.125rem;
}

/* font-fs14-fw4-lh19: Used in flows-premise-contract-summary */
.font-fs14-fw4-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.1875rem;
}

/* font-fs14-fw4-lh17: Used in flows-premise-contract-summary */
.font-fs14-fw4-lh17 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.0625rem;
}

/* font-fs14-fw4-lh14: Used in select-input */
.font-fs14-fw4-lh14 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 0.875rem;
}

/* font-fs12-fw5-lh14 */
.font-fs12-fw5-lh14 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.875rem;
}

/* font-fs12-fw4-lh19 */
.font-fs12-fw4-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.1875rem;
}

/* font-fs18-fw4-lh28: Used in checkout top sections */
.font-fs18-fw4-lh28 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
}

/* font-fs18-fw5-lh24: Used in ui-button */
.font-fs18-fw5-lh24 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
}

/* font-fs18-fw5-lh24: Used in checkout top section */
.font-fs18-fw6-lh24 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.font-fs16-fw5-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.1875rem;
}

.font-fs16-fw7-lh30 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.875;
}

.font-fs16-fw6-lh22 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375rem;
}

.font-fs16-fw6-lh28 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.75rem;
}

.font-fs16-fw6-lh32 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2rem;
}

.font-fs16-fw4-lh24 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.font-fs16-fw4-lh20 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.font-fs14-fw5-lh20 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
}

.font-fs16-fw5-lh22 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375rem;
}
@media (max-width: 599px) {
  .font-fs16-fw5-lh22 {
    font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
  }
}

.font-fs16-fw4-lh22 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
}
@media (max-width: 599px) {
  .font-fs16-fw4-lh22 {
    font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }
}

.font-fs16-fw5-lh22 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375rem;
}
@media (max-width: 599px) {
  .font-fs16-fw5-lh22 {
    font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
  }
}

/* used in info-table-footer fonts-a */
.font-fs16-fw5-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.1875rem;
}

.font-fs24-fw8-lh32 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2rem;
}

:host {
  text-decoration: none;
  box-sizing: border-box;
  display: inline-block;
  font-weight: 500;
  font-family: "Vattenfall Hall";
  color: #222222;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 9px 48px;
  font-size: 14px;
  line-height: 18px;
  border-radius: 20px;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  min-width: 180px;
  position: relative;
}
:host:before {
  content: "→";
  position: absolute;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  -webkit-transform: translate(-220%, 0);
  transform: translate(-220%, 0);
  font-weight: normal;
  font-size: 20px;
  opacity: 0;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
:host:hover:before {
  -webkit-transition-delay: 150ms;
  transition-delay: 150ms;
  -webkit-transform: translate(-150%, 0);
  transform: translate(-150%, 0);
  opacity: 1;
}
:host:hover, :host:focus {
  color: #222222;
  text-decoration: none;
}
:host:focus, :host.focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 218, 0, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(255, 218, 0, 0.25);
}
:host:disabled {
  opacity: 0.65;
}
:host:not(:disabled) {
  cursor: pointer;
}
:host[variant=primary] {
  color: #111111;
  background: #ffda00;
}
:host[variant=primary]:hover {
  color: #111111;
  background: #ffe85b;
}
:host[variant=primary]:focus {
  background: #f1c50b;
}
:host[variant=primary]:disabled {
  color: #767676;
  background-color: #eeeeee;
}
:host[variant=secondary] {
  color: white;
  background: #1964a3;
}
:host[variant=secondary]:hover {
  color: white;
  background: #2071b5;
}
:host[variant=secondary]:focus, :host[variant=secondary]:active {
  color: white;
  background: #1e324f;
}
:host[variant=secondary]:disabled {
  color: #767676;
  background-color: #eeeeee;
}
:host[variant=tertiary] {
  color: #222222;
  background: transparent;
  border: 2px #cdd1d4 solid;
}
:host[variant=tertiary]:hover {
  color: white;
  background: #1964a3;
  border-color: #1964a3;
}
:host[variant=tertiary]:focus, :host[variant=tertiary]:active {
  color: white;
  background: #1e324f;
  border-color: #1e324f;
}
:host[variant=tertiary]:disabled {
  color: #767676;
  background-color: #eeeeee;
  border-color: #eeeeee;
}
:host[variant=outline-secondary], :host[variant=expandable], :host[variant=expanded] {
  color: #1964a3;
  background: transparent;
  border: 2px #2071b5 solid;
}
:host[variant=outline-secondary]:hover {
  color: white;
  background: #1964a3;
  border-color: #1964a3;
}
:host[variant=outline-secondary]:focus, :host[variant=outline-secondary]:active {
  color: white;
  background: #1e324f;
  border-color: #1e324f;
}
:host[variant=outline-secondary]:disabled, :host[variant=expandable]:disabled, :host[variant=expanded]:disabled {
  color: #767676;
  background-color: #eeeeee;
  border-color: #eeeeee;
}
:host[variant=outline-dark] {
  color: #222222;
  background: transparent;
  border: 2px #222222 solid;
}
:host[variant=outline-dark]:hover {
  color: white;
  background: #222222;
  border-color: #222222;
}
:host[variant=outline-dark]:focus, :host[variant=outline-dark]:active {
  color: white;
  background: #4e4b48;
  border-color: #4e4b48;
}
:host[variant=outline-dark]:disabled {
  color: #767676;
  background-color: #eeeeee;
  border-color: #eeeeee;
}
:host[variant=expandable], :host[variant=expanded] {
  color: black;
  background-color: #ffffff;
}
:host[variant=expandable]:before, :host[variant=expanded]:before {
  position: absolute;
  opacity: 1;
  top: 50%;
  left: 1rem;
  font-family: monospace;
  width: 1rem;
  height: 2rem;
  display: inline-block;
  margin-right: 0.5rem;
  font-size: 2rem;
  line-height: 2rem;
}
:host[variant=expandable]:hover, :host[variant=expanded]:hover {
  background-color: #ccc;
}
:host[variant=expandable]:focus, :host[variant=expanded]:focus {
  box-shadow: none;
}
:host[variant=expandable]:before {
  content: "+";
  transform: translateY(-50%);
}
:host[variant=expanded]:before {
  content: "−";
  transform: translateY(-50%);
}
:host[size=large] {
  padding: 12px 48px;
  font-size: 18px;
  line-height: 24px;
  border-radius: 26px;
  min-width: 240px;
}
:host[size=large]:before {
  font-size: 24px;
  -webkit-transform: translate(-220%), 0;
  transform: translate(-220%), 0;
}
:host[size=large]:hover:before {
  -webkit-transform: translate(-150%), 0;
  transform: translate(-150%), 0;
}
:host[size=small] {
  min-width: 120px;
}
:host[size=small]:before {
  font-size: 16px;
  -webkit-transform: translate(-220%), 0;
  transform: translate(-220%), 0;
}
:host[size=small]:hover:before {
  -webkit-transform: translate(-150%), 0;
  transform: translate(-150%), 0;
}
:host[fluid=true] {
  display: block;
  width: 100%;
}
:host[fluid=true] + :host[fluid=true] {
  margin-top: 0.5rem;
}
input[type=submit] :host[fluid=true], input[type=reset] :host[fluid=true], input[type=button] :host[fluid=true] {
  width: 100%;
}

@media screen and (prefers-reduced-motion: reduce) {
  :host {
    -webkit-transition: none;
    transition: none;
  }
}
.ui-button,
[ui-button] {
  text-decoration: none;
  box-sizing: border-box;
  display: inline-block;
  font-weight: 500;
  font-family: "Vattenfall Hall";
  color: #222222;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 9px 48px;
  font-size: 14px;
  line-height: 18px;
  border-radius: 20px;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  min-width: 180px;
  position: relative;
}
.ui-button:before,
[ui-button]:before {
  content: "→";
  position: absolute;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  -webkit-transform: translate(-220%, 0);
  transform: translate(-220%, 0);
  font-weight: normal;
  font-size: 20px;
  opacity: 0;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-delay: 0ms;
  transition-delay: 0ms;
}
.ui-button:hover:before,
[ui-button]:hover:before {
  -webkit-transition-delay: 150ms;
  transition-delay: 150ms;
  -webkit-transform: translate(-150%, 0);
  transform: translate(-150%, 0);
  opacity: 1;
}
.ui-button:hover, .ui-button:focus,
[ui-button]:hover,
[ui-button]:focus {
  color: #222222;
  text-decoration: none;
}
.ui-button:focus, .ui-button.focus,
[ui-button]:focus,
[ui-button].focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 218, 0, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(255, 218, 0, 0.25);
}
.ui-button:disabled,
[ui-button]:disabled {
  opacity: 0.65;
}
.ui-button:not(:disabled),
[ui-button]:not(:disabled) {
  cursor: pointer;
}
.ui-button[variant=primary],
[ui-button][variant=primary] {
  color: #111111;
  background: #ffda00;
}
.ui-button[variant=primary]:hover,
[ui-button][variant=primary]:hover {
  color: #111111;
  background: #ffe85b;
}
.ui-button[variant=primary]:focus,
[ui-button][variant=primary]:focus {
  background: #f1c50b;
}
.ui-button[variant=primary]:disabled,
[ui-button][variant=primary]:disabled {
  color: #767676;
  background-color: #eeeeee;
}
.ui-button[variant=secondary],
[ui-button][variant=secondary] {
  color: white;
  background: #1964a3;
}
.ui-button[variant=secondary]:hover,
[ui-button][variant=secondary]:hover {
  color: white;
  background: #2071b5;
}
.ui-button[variant=secondary]:focus, .ui-button[variant=secondary]:active,
[ui-button][variant=secondary]:focus,
[ui-button][variant=secondary]:active {
  color: white;
  background: #1e324f;
}
.ui-button[variant=secondary]:disabled,
[ui-button][variant=secondary]:disabled {
  color: #767676;
  background-color: #eeeeee;
}
.ui-button[variant=tertiary],
[ui-button][variant=tertiary] {
  color: #222222;
  background: transparent;
  border: 2px #cdd1d4 solid;
}
.ui-button[variant=tertiary]:hover,
[ui-button][variant=tertiary]:hover {
  color: white;
  background: #1964a3;
  border-color: #1964a3;
}
.ui-button[variant=tertiary]:focus, .ui-button[variant=tertiary]:active,
[ui-button][variant=tertiary]:focus,
[ui-button][variant=tertiary]:active {
  color: white;
  background: #1e324f;
  border-color: #1e324f;
}
.ui-button[variant=tertiary]:disabled,
[ui-button][variant=tertiary]:disabled {
  color: #767676;
  background-color: #eeeeee;
  border-color: #eeeeee;
}
.ui-button[variant=outline-secondary], .ui-button[variant=expandable], .ui-button[variant=expanded],
[ui-button][variant=outline-secondary],
[ui-button][variant=expandable],
[ui-button][variant=expanded] {
  color: #1964a3;
  background: transparent;
  border: 2px #2071b5 solid;
}
.ui-button[variant=outline-secondary]:hover,
[ui-button][variant=outline-secondary]:hover {
  color: white;
  background: #1964a3;
  border-color: #1964a3;
}
.ui-button[variant=outline-secondary]:focus, .ui-button[variant=outline-secondary]:active,
[ui-button][variant=outline-secondary]:focus,
[ui-button][variant=outline-secondary]:active {
  color: white;
  background: #1e324f;
  border-color: #1e324f;
}
.ui-button[variant=outline-secondary]:disabled, .ui-button[variant=expandable]:disabled, .ui-button[variant=expanded]:disabled,
[ui-button][variant=outline-secondary]:disabled,
[ui-button][variant=expandable]:disabled,
[ui-button][variant=expanded]:disabled {
  color: #767676;
  background-color: #eeeeee;
  border-color: #eeeeee;
}
.ui-button[variant=outline-dark],
[ui-button][variant=outline-dark] {
  color: #222222;
  background: transparent;
  border: 2px #222222 solid;
}
.ui-button[variant=outline-dark]:hover,
[ui-button][variant=outline-dark]:hover {
  color: white;
  background: #222222;
  border-color: #222222;
}
.ui-button[variant=outline-dark]:focus, .ui-button[variant=outline-dark]:active,
[ui-button][variant=outline-dark]:focus,
[ui-button][variant=outline-dark]:active {
  color: white;
  background: #4e4b48;
  border-color: #4e4b48;
}
.ui-button[variant=outline-dark]:disabled,
[ui-button][variant=outline-dark]:disabled {
  color: #767676;
  background-color: #eeeeee;
  border-color: #eeeeee;
}
.ui-button[variant=expandable], .ui-button[variant=expanded],
[ui-button][variant=expandable],
[ui-button][variant=expanded] {
  color: black;
  background-color: #ffffff;
}
.ui-button[variant=expandable]:before, .ui-button[variant=expanded]:before,
[ui-button][variant=expandable]:before,
[ui-button][variant=expanded]:before {
  position: absolute;
  opacity: 1;
  top: 50%;
  left: 1rem;
  font-family: monospace;
  width: 1rem;
  height: 2rem;
  display: inline-block;
  margin-right: 0.5rem;
  font-size: 2rem;
  line-height: 2rem;
}
.ui-button[variant=expandable]:hover, .ui-button[variant=expanded]:hover,
[ui-button][variant=expandable]:hover,
[ui-button][variant=expanded]:hover {
  background-color: #ccc;
}
.ui-button[variant=expandable]:focus, .ui-button[variant=expanded]:focus,
[ui-button][variant=expandable]:focus,
[ui-button][variant=expanded]:focus {
  box-shadow: none;
}
.ui-button[variant=expandable]:before,
[ui-button][variant=expandable]:before {
  content: "+";
  transform: translateY(-50%);
}
.ui-button[variant=expanded]:before,
[ui-button][variant=expanded]:before {
  content: "−";
  transform: translateY(-50%);
}
.ui-button[size=large],
[ui-button][size=large] {
  padding: 12px 48px;
  font-size: 18px;
  line-height: 24px;
  border-radius: 26px;
  min-width: 240px;
}
.ui-button[size=large]:before,
[ui-button][size=large]:before {
  font-size: 24px;
  -webkit-transform: translate(-220%), 0;
  transform: translate(-220%), 0;
}
.ui-button[size=large]:hover:before,
[ui-button][size=large]:hover:before {
  -webkit-transform: translate(-150%), 0;
  transform: translate(-150%), 0;
}
.ui-button[size=small],
[ui-button][size=small] {
  min-width: 120px;
}
.ui-button[size=small]:before,
[ui-button][size=small]:before {
  font-size: 16px;
  -webkit-transform: translate(-220%), 0;
  transform: translate(-220%), 0;
}
.ui-button[size=small]:hover:before,
[ui-button][size=small]:hover:before {
  -webkit-transform: translate(-150%), 0;
  transform: translate(-150%), 0;
}
.ui-button[fluid=true],
[ui-button][fluid=true] {
  display: block;
  width: 100%;
}
.ui-button[fluid=true] + .ui-button[fluid=true],
.ui-button[fluid=true] + [ui-button][fluid=true],
[ui-button][fluid=true] + .ui-button[fluid=true],
[ui-button][fluid=true] + [ui-button][fluid=true] {
  margin-top: 0.5rem;
}
input[type=submit] .ui-button[fluid=true], input[type=reset] .ui-button[fluid=true], input[type=button] .ui-button[fluid=true],
input[type=submit] [ui-button][fluid=true],
input[type=reset] [ui-button][fluid=true],
input[type=button] [ui-button][fluid=true] {
  width: 100%;
}

@media screen and (prefers-reduced-motion: reduce) {
  .ui-button,
[ui-button] {
    -webkit-transition: none;
    transition: none;
  }
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 300;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-Light.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Light.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Light.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 300;
  font-style: italic;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-LightItalic.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-LightItalic.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-LightItalic.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 400;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-Regular.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Regular.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Regular.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 400;
  font-style: italic;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-RegularItalic.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-RegularItalic.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-RegularItalic.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 500;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-Medium.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Medium.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Medium.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 500;
  font-style: italic;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-MediumItalic.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-MediumItalic.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-MediumItalic.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall Display";
  font-weight: 600;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHallDisplay-Bold.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHallDisplay-Bold.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHallDisplay-Bold.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 600;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-Bold.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Bold.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Bold.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 600;
  font-style: italic;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-BoldItalic.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-BoldItalic.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-BoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 700;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-Heavy.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Heavy.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Heavy.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 700;
  font-style: italic;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-HeavyItalic.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-HeavyItalic.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-HeavyItalic.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Icons";
  src: url("https://cdn.vattenfall.nl/vattenfallnlprd/1.2.0/assets/fonts/Vattenfall-Icons.eot") format("embedded-opentype"), url("https://cdn.vattenfall.nl/vattenfallnlprd/1.2.0/assets/fonts/Vattenfall-Icons.woff") format("woff"), url("https://cdn.vattenfall.nl/vattenfallnlprd/1.2.0/assets/fonts/Vattenfall-Icons.woff2") format("woff2"), url("https://cdn.vattenfall.nl/vattenfallnlprd/1.2.0/assets/fonts/Vattenfall-Icons.ttf") format("truetype");
}
@font-face {
  font-family: "Vattenfall Icons";
  src: url("https://elements.vattenfall.se/fonts/VattenfallElements-Icons.eot") format("embedded-opentype"), url("https://elements.vattenfall.se/fonts/VattenfallElements-Icons.woff") format("woff"), url("https://elements.vattenfall.se/fonts/VattenfallElements-Icons.ttf") format("truetype");
}
i[class^=vf-icon-]:before,
i[class*=" vf-icon-"]:before {
  font-family: "Vattenfall Icons", sans-serif !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vf-icon-VF_add_contact_rgb:before {
  content: "";
}

.vf-icon-VF_alert_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_down_left_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_down_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_down_right_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_left_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_right_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_up_left_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_up_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_up_right_rgb:before {
  content: "";
}

.vf-icon-VF_calendar_rgb:before {
  content: "";
}

.vf-icon-VF_call_rgb:before {
  content: "";
}

.vf-icon-VF_camera_rgb:before {
  content: "";
}

.vf-icon-VF_chat_rgb:before {
  content: "";
}

.vf-icon-VF_checkbox_rgb:before {
  content: "";
}

.vf-icon-VF_download_pdf_rgb:before {
  content: "";
}

.vf-icon-VF_download_rgb:before {
  content: "";
}

.vf-icon-VF_edit_rgb:before {
  content: "";
}

.vf-icon-VF_elipsis_rgb:before, .vattenfall-elements__edit-link:before {
  content: "";
}

.vf-icon-VF_eye_rgb:before {
  content: "";
}

.vf-icon-VF_favourite_rgb:before {
  content: "";
}

.vf-icon-VF_gallery_rgb:before {
  content: "";
}

.vf-icon-VF_guide_icon_rgb:before {
  content: "";
}

.vf-icon-VF_heart_rgb:before {
  content: "";
}

.vf-icon-VF_help_rgb:before {
  content: "";
}

.vf-icon-VF_information_rgb:before {
  content: "";
}

.vf-icon-VF_locked_rgb:before {
  content: "";
}

.vf-icon-VF_mail_rgb:before {
  content: "";
}

.vf-icon-VF_maps_rgb:before {
  content: "";
}

.vf-icon-VF_nina_avatar_background_rgb:before {
  content: "";
}

.vf-icon-VF_nina_happy_avatar_rgb:before {
  content: "";
}

.vf-icon-VF_notvisible_rgb:before {
  content: "";
}

.vf-icon-VF_off_rgb:before {
  content: "";
}

.vf-icon-VF_open_new_window_rgb:before {
  content: "";
}

.vf-icon-VF_pause_rgb:before {
  content: "";
}

.vf-icon-VF_phone_rgb:before {
  content: "";
}

.vf-icon-VF_pin_rgb:before {
  content: "";
}

.vf-icon-VF_play_rgb:before {
  content: "";
}

.vf-icon-VF_print_rgb:before {
  content: "";
}

.vf-icon-VF_settings_rgb:before {
  content: "";
}

.vf-icon-VF_share_rgb:before {
  content: "";
}

.vf-icon-VF_shop_rgb:before {
  content: "";
}

.vf-icon-VF_sign_out_rgb:before {
  content: "";
}

.vf-icon-VF_sort_rgb:before {
  content: "";
}

.vf-icon-VF_tag_rgb:before {
  content: "";
}

.vf-icon-VF_unlocked_rgb:before {
  content: "";
}

.vf-icon-VF_user_rgb:before {
  content: "";
}

.vf-icon-VF_zoom_in_rgb:before {
  content: "";
}

.vf-icon-VF_zoom_out_rgb:before {
  content: "";
}

.vf-icon-VF_allocate_agent_rgb:before {
  content: "";
}

.vf-icon-VF_allocate_location_rgb:before {
  content: "";
}

.vf-icon-VF_allocate_party_rgb:before {
  content: "";
}

.vf-icon-VF_allocate_team_rgb:before {
  content: "";
}

.vf-icon-VF_promotion_rgb:before {
  content: "";
}

.vf-icon-VF_Sustainable_use_of_resources_rgb:before {
  content: "";
}

.vf-icon-VF_airplane_rgb:before {
  content: "";
}

.vf-icon-VF_apartment_house_rgb:before {
  content: "";
}

.vf-icon-VF_battery_rgb:before {
  content: "";
}

.vf-icon-VF_benchmark_co2_rgb:before {
  content: "";
}

.vf-icon-VF_benchmark_cockpit_rgb:before {
  content: "";
}

.vf-icon-VF_benchmark_rgb:before {
  content: "";
}

.vf-icon-VF_benchmark_temperature_rgb:before {
  content: "";
}

.vf-icon-VF_benefit_rgb:before {
  content: "";
}

.vf-icon-VF_biofuel-biomass_rgb:before {
  content: "";
}

.vf-icon-VF_brainstorm_rgb:before {
  content: "";
}

.vf-icon-VF_callback_rgb:before {
  content: "";
}

.vf-icon-VF_chart_rgb:before {
  content: "";
}

.vf-icon-VF_city_rgb:before {
  content: "";
}

.vf-icon-VF_clock_rgb:before {
  content: "";
}

.vf-icon-VF_coal_rgb:before {
  content: "";
}

.vf-icon-VF_cold_rgb:before {
  content: "";
}

.vf-icon-VF_combined_heat_and_power_rgb:before {
  content: "";
}

.vf-icon-VF_consumption_rgb:before {
  content: "";
}

.vf-icon-VF_contract_details_rgb:before {
  content: "";
}

.vf-icon-VF_corporate_rgb:before {
  content: "";
}

.vf-icon-VF_cost_saving_rgb:before {
  content: "";
}

.vf-icon-VF_cottage_rgb:before {
  content: "";
}

.vf-icon-VF_currency_rgb:before {
  content: "";
}

.vf-icon-VF_defence_rgb:before {
  content: "";
}

.vf-icon-VF_discussion_B_rgb:before {
  content: "";
}

.vf-icon-VF_discussion_rgb:before {
  content: "";
}

.vf-icon-VF_distribution_rgb:before {
  content: "";
}

.vf-icon-VF_dont_rgb:before {
  content: "";
}

.vf-icon-VF_e-bike_rgb:before {
  content: "";
}

.vf-icon-VF_e-mobility_rgb:before {
  content: "";
}

.vf-icon-VF_economize_rgb:before {
  content: "";
}

.vf-icon-VF_eko_hourly_spot_rgb:before {
  content: "";
}

.vf-icon-VF_electricity_rgb:before {
  content: "";
}

.vf-icon-VF_electronic_payment_euro_rgb:before {
  content: "";
}

.vf-icon-VF_energy_mix_rgb:before {
  content: "";
}

.vf-icon-VF_energy_solutions_rgb:before {
  content: "";
}

.vf-icon-VF_euro_rgb:before {
  content: "";
}

.vf-icon-VF_evaluation_rgb:before {
  content: "";
}

.vf-icon-VF_farm_rgb:before {
  content: "";
}

.vf-icon-VF_fax_rgb:before {
  content: "";
}

.vf-icon-VF_full_water_quartal_icon_rgb:before {
  content: "";
}

.vf-icon-VF_gas_rgb:before {
  content: "";
}

.vf-icon-VF_globe_rgb:before {
  content: "";
}

.vf-icon-VF_good_deal_rgb:before {
  content: "";
}

.vf-icon-VF_group_discussion_rgb:before {
  content: "";
}

.vf-icon-VF_heating_rgb:before {
  content: "";
}

.vf-icon-VF_hot_rgb:before {
  content: "";
}

.vf-icon-VF_house_rgb:before {
  content: "";
}

.vf-icon-VF_hydro_rgb:before {
  content: "";
}

.vf-icon-VF_in_queue_rgb:before {
  content: "";
}

.vf-icon-VF_industry_rgb:before {
  content: "";
}

.vf-icon-VF_invoices_rgb:before {
  content: "";
}

.vf-icon-VF_large_battery_rgb:before {
  content: "";
}

.vf-icon-VF_lecture_rgb:before {
  content: "";
}

.vf-icon-VF_life_events_rgb:before {
  content: "";
}

.vf-icon-VF_light_bulb_rgb:before {
  content: "";
}

.vf-icon-VF_manage_my_team_rgb:before {
  content: "";
}

.vf-icon-VF_mobility_rgb:before {
  content: "";
}

.vf-icon-VF_modules_rgb:before {
  content: "";
}

.vf-icon-VF_move_with_existing_contract_rgb:before {
  content: "";
}

.vf-icon-VF_move_with_new_contract_rgb:before {
  content: "";
}

.vf-icon-VF_multi_home_box_rgb:before {
  content: "";
}

.vf-icon-VF_my_cases_rgb:before {
  content: "";
}

.vf-icon-VF_my_documents_rgb:before {
  content: "";
}

.vf-icon-VF_nighttime_electricity_metering_rgb:before {
  content: "";
}

.vf-icon-VF_nuclear_rgb:before {
  content: "";
}

.vf-icon-VF_ocean_energy_rgb:before {
  content: "";
}

.vf-icon-VF_offshore_rgb:before {
  content: "";
}

.vf-icon-VF_oil_condesing_gas_turbine_rgb:before {
  content: "";
}

.vf-icon-VF_partners_rgb:before {
  content: "";
}

.vf-icon-VF_peat_rgb:before {
  content: "";
}

.vf-icon-VF_planned_outage_rgb:before {
  content: "";
}

.vf-icon-VF_policies_rgb:before {
  content: "";
}

.vf-icon-VF_power_grid_rgb:before {
  content: "";
}

.vf-icon-VF_power_plant_rgb:before {
  content: "";
}

.vf-icon-VF_premium_rgb:before {
  content: "";
}

.vf-icon-VF_present_rgb:before {
  content: "";
}

.vf-icon-VF_private_end_user_rgb:before {
  content: "";
}

.vf-icon-VF_private_rgb:before {
  content: "";
}

.vf-icon-VF_recycling_rgb:before {
  content: "";
}

.vf-icon-VF_salary_time_and_benefits_rgb:before {
  content: "";
}

.vf-icon-VF_semi_detached_house_rgb:before {
  content: "";
}

.vf-icon-VF_ship_docking_rgb:before {
  content: "";
}

.vf-icon-VF_smart_home_rgb:before {
  content: "";
}

.vf-icon-VF_smartphone_text_rgb:before {
  content: "";
}

.vf-icon-VF_solar_panel_rgb:before {
  content: "";
}

.vf-icon-VF_solar_power_rgb:before {
  content: "";
}

.vf-icon-VF_standard_contract_continuos_rgb:before {
  content: "";
}

.vf-icon-VF_support_rgb:before {
  content: "";
}

.vf-icon-VF_temperature_rgb:before {
  content: "";
}

.vf-icon-VF_voice_search_rgb:before {
  content: "";
}

.vf-icon-VF_wind_rgb:before {
  content: "";
}

.vf-icon-VF_card_and_tag_rgb:before {
  content: "";
}

.vf-icon-VF_charge_up_your_business_rgb:before {
  content: "";
}

.vf-icon-VF_charging_wall_box_rgb:before {
  content: "";
}

.vf-icon-VF_parking_meter_rgb:before {
  content: "";
}

.vf-icon-VF_partner_rgb:before {
  content: "";
}

.vf-icon-VF_private_charging_rgb:before {
  content: "";
}

.vf-icon-VF_public_business_charging_rgb:before {
  content: "";
}

.vf-icon-VF_public_charging_rgb:before {
  content: "";
}

.vf-icon-VF_scooter_2_rgb:before {
  content: "";
}

.vf-icon-VF_scooter_rgb:before {
  content: "";
}

.vf-icon-VF_smart_charging_poles_rgb:before {
  content: "";
}

.vf-icon-VF_check_rgb:before {
  content: "";
}

.vf-icon-VF_close_rgb:before {
  content: "";
}

.vf-icon-VF_down_rgb:before {
  content: "";
}

.vf-icon-VF_filter_rgb:before {
  content: "";
}

.vf-icon-VF_home_rgb:before {
  content: "";
}

.vf-icon-VF_left_rgb:before {
  content: "";
}

.vf-icon-VF_less_info_rgb:before {
  content: "";
}

.vf-icon-VF_menu_rgb:before {
  content: "";
}

.vf-icon-VF_more_rgb:before {
  content: "";
}

.vf-icon-VF_refresh_rgb:before {
  content: "";
}

.vf-icon-VF_right_rgb:before {
  content: "";
}

.vf-icon-VF_rss_rgb:before {
  content: "";
}

.vf-icon-VF_search_rgb:before {
  content: "";
}

.vf-icon-VF_send_rgb:before {
  content: "";
}

.vf-icon-VF_up_rgb:before {
  content: "";
}

.vf-icon-VF_TV_remote_rgb:before {
  content: "";
}

.vf-icon-VF_alarm_rgb:before {
  content: "";
}

.vf-icon-VF_cloudy_rgb:before {
  content: "";
}

.vf-icon-VF_coffee_maker_rgb:before {
  content: "";
}

.vf-icon-VF_console_rgb:before {
  content: "";
}

.vf-icon-VF_desklamp_rgb:before {
  content: "";
}

.vf-icon-VF_dishwasher_rgb:before {
  content: "";
}

.vf-icon-VF_door_closed_rgb:before {
  content: "";
}

.vf-icon-VF_door_open_rgb:before {
  content: "";
}

.vf-icon-VF_dryer_rgb:before {
  content: "";
}

.vf-icon-VF_fridge_freezer_rgb:before {
  content: "";
}

.vf-icon-VF_ice_rgb:before {
  content: "";
}

.vf-icon-VF_kettle_rgb:before {
  content: "";
}

.vf-icon-VF_laptop_rgb:before {
  content: "";
}

.vf-icon-VF_light_switch_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_step_1_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_step_2_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_step_3_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_step_4_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_step_5_rgb:before {
  content: "";
}

.vf-icon-VF_microwave_rgb:before {
  content: "";
}

.vf-icon-VF_moon_rgb:before {
  content: "";
}

.vf-icon-VF_phone_charging_rgb:before {
  content: "";
}

.vf-icon-VF_radiator_rgb:before {
  content: "";
}

.vf-icon-VF_rain_rgb:before {
  content: "";
}

.vf-icon-VF_router_rgb:before {
  content: "";
}

.vf-icon-VF_smart_plug_rgb:before {
  content: "";
}

.vf-icon-VF_smoke_alarm_rgb:before {
  content: "";
}

.vf-icon-VF_snow_rgb:before {
  content: "";
}

.vf-icon-VF_sun_rgb:before {
  content: "";
}

.vf-icon-VF_table_lamp_2_rgb:before {
  content: "";
}

.vf-icon-VF_table_lamp_rgb:before {
  content: "";
}

.vf-icon-VF_thunder_rgb:before {
  content: "";
}

.vf-icon-VF_ventilator_rgb:before {
  content: "";
}

.vf-icon-VF_washing_machine_rgb:before {
  content: "";
}

.vf-icon-VF_window_blinds_rgb:before {
  content: "";
}

.vf-icon-VF_window_closed_rgb:before {
  content: "";
}

.vf-icon-VF_window_open_rgb:before {
  content: "";
}

.vf-icon-VF_window_rgb:before {
  content: "";
}

.vf-icon-VF_dropbox_rgb:before {
  content: "";
}

.vf-icon-VF_facebook_rgb:before {
  content: "";
}

.vf-icon-VF_flickr_rgb:before {
  content: "";
}

.vf-icon-VF_google_plus_rgb:before {
  content: "";
}

.vf-icon-VF_instagram_rgb:before {
  content: "";
}

.vf-icon-VF_linkedin_rgb:before {
  content: "";
}

.vf-icon-VF_pinterest_rgb:before {
  content: "";
}

.vf-icon-VF_skype_rgb:before {
  content: "";
}

.vf-icon-VF_slideshare_rgb:before {
  content: "";
}

.vf-icon-VF_snapchat_rgb:before {
  content: "";
}

.vf-icon-VF_twitter_rgb:before {
  content: "";
}

.vf-icon-VF_vimeo_rgb:before {
  content: "";
}

.vf-icon-VF_xing_rgb:before {
  content: "";
}

.vf-icon-VF_youtube_rgb:before {
  content: "";
}

/* primary brand colors */
/* secondary web colors */
/* background web colors */
/* tertiary web colors */
/* misc colors */
/* todo: setting temporary color for focus borders 
while awaiting requirements */
/*
References:

https://digitaldesign.vattenfall.com/responsive 
 widths  375 - 667 - 768 - 1024 - 1920
*/
/*
  Container Breakpoints inherited from Vattenfall Digital Design System.
*/
/* 
This file is a mix of two graphical sources:
Vattenfall official styleguide
https://digitaldesign.vattenfall.com/css/text
"my-pages contract"
https://vattenfall.invisionapp.com/share/42U9SSSVANU#/screens/393223332

Provide style as a class and a mixin, to be able to use 
in projected content.

The classes and mixins have syntax to specify size, weight and line-height.
The intention of this formilized syntax is to assemble the set of fontstyles
into tag-agnostic sets. This means for example that a h1 may have different 
appearance. 

See the fonts.stories.ts for examples.

mixin codes::
@mixin fonts-[size]-[weight]-[lineheight]

usage:
p {
  @include font-fs16-fw5-lh22;
}

All fontstyle codes within the ui-lib are:
font-fs32-fw9-lh38 (largest heading : info-table-heading)
font-fs26-fw9-lh30
font-fs20-fw6-lh24 (dashboard titel, mix of project design and implementation based on ddb)
font-fs18-fw5-lh24 (ui-button)
font-fs16-fw7-lh30 
font-fs16-fw5-lh19 (small condensed: links, buttons, small heading)
font-fs16-fw4-lh19 (select-input-item-primary-title)
font-fs16-fw4-lh28 (default)
font-fs16-fw5-lh22 (dt,dd in for example key-value-list)
font-fs16-fw4-lh24
font-fs16-fw4-lh22 (key-value-list: dt, dd)
font-fs14-fw5-lh20 (key-value-list: dt, dd)
font-fs14-fw5-lh18 (ui-button)
font-fs14-fw4-lh20 (key-value-list: dt, dd)
font-fs14-fw4-lh14 (selector: .select-input-item-secondary-title)
font-fs12-fw5-lh14 
font-fs12-fw4-lh19
font-icon

The ddb system:
font-fs72-fw6-lh84
font-fs52-fw6-lh72
font-fs36-fw6-lh40
font-fs28-fw6-lh37
font-fs24-fw6-lh28
font-fs24-fw6-lh36
font-fs24-fw6-lh36
font-fs24-fw5-lh40
font-fs20-fw5-lh36
font-fs12-fw6-lh22
font-fs16-fw6-lh36
font-fs16-fw4-lh28

The design for 'mina avtal' and 'mina fakturor':
font-fs52-fw7-lh72

The current 'mina fakturor' mobile:
font-fs22-fw7-lh28

Guidelines:
Favor usage of mixins.
Default to 'inherit'.
Avoid style on elements. (h1 may for example be used inside an article anywhere on the page, and
may have other size than h1 inside the main)
*/
.font-fs16-fw4-lh28 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  font-size: 1rem;
  line-height: 1.75rem;
  color: #222222;
}

.font-icon {
  font-family: "Vattenfall Icons", "sans-serif";
  user-select: none;
  font-style: normal;
}

/* font-fs20-fw6-lh24: Used in main dashboard widget titles 'mina avtal', 'mina fakturor' and more*/
.font-fs20-fw6-lh24 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0rem;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

/* font-fs52-fw6-lh72: Used in main title 'mina avtal', 'mina fakturor' */
.font-fs52-fw6-lh72 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0rem;
  font-size: 3.25rem;
  line-height: 4.5rem;
}
@media (max-width: 599px) {
  .font-fs52-fw6-lh72 {
    font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
    font-weight: 700;
    letter-spacing: 0rem;
    font-size: 1.375rem;
    line-height: 1.75rem;
  }
}

/* font-fs26-fw9-lh30 */
.font-fs26-fw9-lh30 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 900;
  letter-spacing: 0rem;
  font-size: 1.625rem;
  line-height: 1.875;
}

/* font-fs26-fw6-lh28 */
.font-fs26-fw6-lh28 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0rem;
  font-size: 1.625rem;
  line-height: 1.75;
}

/* font-fs32-fw9-lh38: Used in info-table-heading */
.font-fs32-fw9-lh38 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 900;
  letter-spacing: 0rem;
  font-size: 2rem;
  line-height: 2.375rem;
}
@media (max-width: 599px) {
  .font-fs32-fw9-lh38 {
    font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
    font-weight: 900;
    font-size: 1.375rem;
    line-height: 1.625rem;
  }
}

.font-fs32-fw4-lh40 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0rem;
  font-size: 2rem;
  line-height: 2.5rem;
}

/* font-fs16-fw4-lh19: Used in info-table-heading and accordion */
.font-fs16-fw4-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1875rem;
}

/* font-fs14-fw6-lh19: Used in flows-premise-contract-summary */
.font-fs14-fw6-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.1875rem;
}

/* font-fs14-fw4-lh18 Used in my-contact-notification-dialog */
.font-fs14-fw4-lh18 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
}

/* font-fs14-fw5-lh18: Used in ui-button */
.font-fs14-fw5-lh18 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.125rem;
}

/* font-fs14-fw4-lh19: Used in flows-premise-contract-summary */
.font-fs14-fw4-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.1875rem;
}

/* font-fs14-fw4-lh17: Used in flows-premise-contract-summary */
.font-fs14-fw4-lh17 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.0625rem;
}

/* font-fs14-fw4-lh14: Used in select-input */
.font-fs14-fw4-lh14 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 0.875rem;
}

/* font-fs12-fw5-lh14 */
.font-fs12-fw5-lh14 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.875rem;
}

/* font-fs12-fw4-lh19 */
.font-fs12-fw4-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.1875rem;
}

/* font-fs18-fw4-lh28: Used in checkout top sections */
.font-fs18-fw4-lh28 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
}

/* font-fs18-fw5-lh24: Used in ui-button */
.font-fs18-fw5-lh24 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
}

/* font-fs18-fw5-lh24: Used in checkout top section */
.font-fs18-fw6-lh24 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.font-fs16-fw5-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.1875rem;
}

.font-fs16-fw7-lh30 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.875;
}

.font-fs16-fw6-lh22 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375rem;
}

.font-fs16-fw6-lh28 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.75rem;
}

.font-fs16-fw6-lh32 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2rem;
}

.font-fs16-fw4-lh24 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.font-fs16-fw4-lh20 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.font-fs14-fw5-lh20 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
}

.font-fs16-fw5-lh22 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375rem;
}
@media (max-width: 599px) {
  .font-fs16-fw5-lh22 {
    font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
  }
}

.font-fs16-fw4-lh22 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
}
@media (max-width: 599px) {
  .font-fs16-fw4-lh22 {
    font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }
}

.font-fs16-fw5-lh22 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375rem;
}
@media (max-width: 599px) {
  .font-fs16-fw5-lh22 {
    font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
  }
}

/* used in info-table-footer fonts-a */
.font-fs16-fw5-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.1875rem;
}

.font-fs24-fw8-lh32 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2rem;
}

/* primary brand colors */
/* secondary web colors */
/* background web colors */
/* tertiary web colors */
/* misc colors */
/* todo: setting temporary color for focus borders 
while awaiting requirements */
:host {
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  text-decoration: none;
  position: relative;
  font-family: "Vattenfall Hall", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  letter-spacing: -0.05px;
  line-height: 28px;
  font-weight: normal;
  font-weight: 500;
  color: #222222;
  padding-left: 35px;
}
:host:visited {
  color: #222222;
}
:host:before {
  content: "→";
  position: absolute;
  left: 0;
  font-size: 28px;
  font-weight: normal;
  color: #2071b5;
  transition-property: opacity, transform;
  transition-duration: 300ms;
  transition-delay: 0ms;
  transform: translate(0%, 0);
}
:host:hover:before {
  color: #2071b5;
  transform: translate(50%, 0);
}
:host:active:before {
  color: #1e324f;
}
:host:hover {
  color: #2071b5;
  text-decoration: none;
  cursor: pointer;
}
:host:focus {
  text-decoration: none;
  border-color: transparent;
  box-shadow: none;
}
:host:disabled {
  color: #767676;
  pointer-events: none;
}
:host:active {
  color: #1e324f;
  text-decoration: none;
}
:host[size=medium] {
  font-size: 16px;
}
:host[variant=externallink]:before {
  font-family: "Vattenfall Icons", "sans-serif";
  user-select: none;
  font-style: normal;
  content: "";
  position: absolute;
}
:host[variant=externallink]:hover:before {
  color: #2071b5;
  transform: none;
}
:host[variant=document]:before {
  font-family: "Vattenfall Icons", "sans-serif";
  user-select: none;
  font-style: normal;
  content: "";
  position: absolute;
  transform: none;
}
:host[variant=document]:hover:before {
  color: #2071b5;
  transform: none;
}
:host[variant=edit] {
  font-weight: 400;
  color: #2071b5;
  padding-left: 0.5rem;
  padding-right: 2rem;
  vertical-align: baseline;
  line-height: unset;
}
:host[variant=edit]:hover {
  color: #18568a;
}
:host[variant=edit]::before {
  content: none;
}
:host[variant=edit]::after {
  font-family: "Vattenfall Icons", "sans-serif";
  user-select: none;
  font-style: normal;
  content: "";
  position: absolute;
  left: unset;
  font-size: 1rem;
  right: -1.5rem;
  margin-right: 2rem;
  transform: none;
  top: 2px;
}

@media (min-width: 576px) {
  :host {
    font-family: "Vattenfall Hall", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 20px;
    letter-spacing: 0;
    line-height: 36px;
  }
}
.ui-link,
[ui-link] {
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  text-decoration: none;
  position: relative;
  font-family: "Vattenfall Hall", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 16px;
  letter-spacing: -0.05px;
  line-height: 28px;
  font-weight: normal;
  font-weight: 500;
  color: #222222;
  padding-left: 35px;
}
.ui-link:visited,
[ui-link]:visited {
  color: #222222;
}
.ui-link:before,
[ui-link]:before {
  content: "→";
  position: absolute;
  left: 0;
  font-size: 28px;
  font-weight: normal;
  color: #2071b5;
  transition-property: opacity, transform;
  transition-duration: 300ms;
  transition-delay: 0ms;
  transform: translate(0%, 0);
}
.ui-link:hover:before,
[ui-link]:hover:before {
  color: #2071b5;
  transform: translate(50%, 0);
}
.ui-link:active:before,
[ui-link]:active:before {
  color: #1e324f;
}
.ui-link:hover,
[ui-link]:hover {
  color: #2071b5;
  text-decoration: none;
  cursor: pointer;
}
.ui-link:focus,
[ui-link]:focus {
  text-decoration: none;
  border-color: transparent;
  box-shadow: none;
}
.ui-link:disabled,
[ui-link]:disabled {
  color: #767676;
  pointer-events: none;
}
.ui-link:active,
[ui-link]:active {
  color: #1e324f;
  text-decoration: none;
}
.ui-link[size=medium],
[ui-link][size=medium] {
  font-size: 16px;
}
.ui-link[variant=externallink]:before,
[ui-link][variant=externallink]:before {
  font-family: "Vattenfall Icons", "sans-serif";
  user-select: none;
  font-style: normal;
  content: "";
  position: absolute;
}
.ui-link[variant=externallink]:hover:before,
[ui-link][variant=externallink]:hover:before {
  color: #2071b5;
  transform: none;
}
.ui-link[variant=document]:before,
[ui-link][variant=document]:before {
  font-family: "Vattenfall Icons", "sans-serif";
  user-select: none;
  font-style: normal;
  content: "";
  position: absolute;
  transform: none;
}
.ui-link[variant=document]:hover:before,
[ui-link][variant=document]:hover:before {
  color: #2071b5;
  transform: none;
}
.ui-link[variant=edit],
[ui-link][variant=edit] {
  font-weight: 400;
  color: #2071b5;
  padding-left: 0.5rem;
  padding-right: 2rem;
  vertical-align: baseline;
  line-height: unset;
}
.ui-link[variant=edit]:hover,
[ui-link][variant=edit]:hover {
  color: #18568a;
}
.ui-link[variant=edit]::before,
[ui-link][variant=edit]::before {
  content: none;
}
.ui-link[variant=edit]::after,
[ui-link][variant=edit]::after {
  font-family: "Vattenfall Icons", "sans-serif";
  user-select: none;
  font-style: normal;
  content: "";
  position: absolute;
  left: unset;
  font-size: 1rem;
  right: -1.5rem;
  margin-right: 2rem;
  transform: none;
  top: 2px;
}

@media (min-width: 576px) {
  .ui-link,
[ui-link] {
    font-family: "Vattenfall Hall", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 20px;
    letter-spacing: 0;
    line-height: 36px;
  }
}
/* primary brand colors */
/* secondary web colors */
/* background web colors */
/* tertiary web colors */
/* misc colors */
/* todo: setting temporary color for focus borders 
while awaiting requirements */
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 300;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-Light.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Light.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Light.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 300;
  font-style: italic;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-LightItalic.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-LightItalic.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-LightItalic.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 400;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-Regular.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Regular.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Regular.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 400;
  font-style: italic;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-RegularItalic.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-RegularItalic.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-RegularItalic.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 500;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-Medium.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Medium.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Medium.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 500;
  font-style: italic;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-MediumItalic.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-MediumItalic.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-MediumItalic.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall Display";
  font-weight: 600;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHallDisplay-Bold.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHallDisplay-Bold.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHallDisplay-Bold.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 600;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-Bold.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Bold.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Bold.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 600;
  font-style: italic;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-BoldItalic.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-BoldItalic.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-BoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 700;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-Heavy.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Heavy.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Heavy.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 700;
  font-style: italic;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-HeavyItalic.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-HeavyItalic.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-HeavyItalic.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Icons";
  src: url("https://cdn.vattenfall.nl/vattenfallnlprd/1.2.0/assets/fonts/Vattenfall-Icons.eot") format("embedded-opentype"), url("https://cdn.vattenfall.nl/vattenfallnlprd/1.2.0/assets/fonts/Vattenfall-Icons.woff") format("woff"), url("https://cdn.vattenfall.nl/vattenfallnlprd/1.2.0/assets/fonts/Vattenfall-Icons.woff2") format("woff2"), url("https://cdn.vattenfall.nl/vattenfallnlprd/1.2.0/assets/fonts/Vattenfall-Icons.ttf") format("truetype");
}
@font-face {
  font-family: "Vattenfall Icons";
  src: url("https://elements.vattenfall.se/fonts/VattenfallElements-Icons.eot") format("embedded-opentype"), url("https://elements.vattenfall.se/fonts/VattenfallElements-Icons.woff") format("woff"), url("https://elements.vattenfall.se/fonts/VattenfallElements-Icons.ttf") format("truetype");
}
i[class^=vf-icon-]:before,
i[class*=" vf-icon-"]:before {
  font-family: "Vattenfall Icons", sans-serif !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vf-icon-VF_add_contact_rgb:before {
  content: "";
}

.vf-icon-VF_alert_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_down_left_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_down_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_down_right_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_left_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_right_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_up_left_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_up_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_up_right_rgb:before {
  content: "";
}

.vf-icon-VF_calendar_rgb:before {
  content: "";
}

.vf-icon-VF_call_rgb:before {
  content: "";
}

.vf-icon-VF_camera_rgb:before {
  content: "";
}

.vf-icon-VF_chat_rgb:before {
  content: "";
}

.vf-icon-VF_checkbox_rgb:before {
  content: "";
}

.vf-icon-VF_download_pdf_rgb:before {
  content: "";
}

.vf-icon-VF_download_rgb:before {
  content: "";
}

.vf-icon-VF_edit_rgb:before {
  content: "";
}

.vf-icon-VF_elipsis_rgb:before, .vattenfall-elements__edit-link:before {
  content: "";
}

.vf-icon-VF_eye_rgb:before {
  content: "";
}

.vf-icon-VF_favourite_rgb:before {
  content: "";
}

.vf-icon-VF_gallery_rgb:before {
  content: "";
}

.vf-icon-VF_guide_icon_rgb:before {
  content: "";
}

.vf-icon-VF_heart_rgb:before {
  content: "";
}

.vf-icon-VF_help_rgb:before {
  content: "";
}

.vf-icon-VF_information_rgb:before {
  content: "";
}

.vf-icon-VF_locked_rgb:before {
  content: "";
}

.vf-icon-VF_mail_rgb:before {
  content: "";
}

.vf-icon-VF_maps_rgb:before {
  content: "";
}

.vf-icon-VF_nina_avatar_background_rgb:before {
  content: "";
}

.vf-icon-VF_nina_happy_avatar_rgb:before {
  content: "";
}

.vf-icon-VF_notvisible_rgb:before {
  content: "";
}

.vf-icon-VF_off_rgb:before {
  content: "";
}

.vf-icon-VF_open_new_window_rgb:before {
  content: "";
}

.vf-icon-VF_pause_rgb:before {
  content: "";
}

.vf-icon-VF_phone_rgb:before {
  content: "";
}

.vf-icon-VF_pin_rgb:before {
  content: "";
}

.vf-icon-VF_play_rgb:before {
  content: "";
}

.vf-icon-VF_print_rgb:before {
  content: "";
}

.vf-icon-VF_settings_rgb:before {
  content: "";
}

.vf-icon-VF_share_rgb:before {
  content: "";
}

.vf-icon-VF_shop_rgb:before {
  content: "";
}

.vf-icon-VF_sign_out_rgb:before {
  content: "";
}

.vf-icon-VF_sort_rgb:before {
  content: "";
}

.vf-icon-VF_tag_rgb:before {
  content: "";
}

.vf-icon-VF_unlocked_rgb:before {
  content: "";
}

.vf-icon-VF_user_rgb:before {
  content: "";
}

.vf-icon-VF_zoom_in_rgb:before {
  content: "";
}

.vf-icon-VF_zoom_out_rgb:before {
  content: "";
}

.vf-icon-VF_allocate_agent_rgb:before {
  content: "";
}

.vf-icon-VF_allocate_location_rgb:before {
  content: "";
}

.vf-icon-VF_allocate_party_rgb:before {
  content: "";
}

.vf-icon-VF_allocate_team_rgb:before {
  content: "";
}

.vf-icon-VF_promotion_rgb:before {
  content: "";
}

.vf-icon-VF_Sustainable_use_of_resources_rgb:before {
  content: "";
}

.vf-icon-VF_airplane_rgb:before {
  content: "";
}

.vf-icon-VF_apartment_house_rgb:before {
  content: "";
}

.vf-icon-VF_battery_rgb:before {
  content: "";
}

.vf-icon-VF_benchmark_co2_rgb:before {
  content: "";
}

.vf-icon-VF_benchmark_cockpit_rgb:before {
  content: "";
}

.vf-icon-VF_benchmark_rgb:before {
  content: "";
}

.vf-icon-VF_benchmark_temperature_rgb:before {
  content: "";
}

.vf-icon-VF_benefit_rgb:before {
  content: "";
}

.vf-icon-VF_biofuel-biomass_rgb:before {
  content: "";
}

.vf-icon-VF_brainstorm_rgb:before {
  content: "";
}

.vf-icon-VF_callback_rgb:before {
  content: "";
}

.vf-icon-VF_chart_rgb:before {
  content: "";
}

.vf-icon-VF_city_rgb:before {
  content: "";
}

.vf-icon-VF_clock_rgb:before {
  content: "";
}

.vf-icon-VF_coal_rgb:before {
  content: "";
}

.vf-icon-VF_cold_rgb:before {
  content: "";
}

.vf-icon-VF_combined_heat_and_power_rgb:before {
  content: "";
}

.vf-icon-VF_consumption_rgb:before {
  content: "";
}

.vf-icon-VF_contract_details_rgb:before {
  content: "";
}

.vf-icon-VF_corporate_rgb:before {
  content: "";
}

.vf-icon-VF_cost_saving_rgb:before {
  content: "";
}

.vf-icon-VF_cottage_rgb:before {
  content: "";
}

.vf-icon-VF_currency_rgb:before {
  content: "";
}

.vf-icon-VF_defence_rgb:before {
  content: "";
}

.vf-icon-VF_discussion_B_rgb:before {
  content: "";
}

.vf-icon-VF_discussion_rgb:before {
  content: "";
}

.vf-icon-VF_distribution_rgb:before {
  content: "";
}

.vf-icon-VF_dont_rgb:before {
  content: "";
}

.vf-icon-VF_e-bike_rgb:before {
  content: "";
}

.vf-icon-VF_e-mobility_rgb:before {
  content: "";
}

.vf-icon-VF_economize_rgb:before {
  content: "";
}

.vf-icon-VF_eko_hourly_spot_rgb:before {
  content: "";
}

.vf-icon-VF_electricity_rgb:before {
  content: "";
}

.vf-icon-VF_electronic_payment_euro_rgb:before {
  content: "";
}

.vf-icon-VF_energy_mix_rgb:before {
  content: "";
}

.vf-icon-VF_energy_solutions_rgb:before {
  content: "";
}

.vf-icon-VF_euro_rgb:before {
  content: "";
}

.vf-icon-VF_evaluation_rgb:before {
  content: "";
}

.vf-icon-VF_farm_rgb:before {
  content: "";
}

.vf-icon-VF_fax_rgb:before {
  content: "";
}

.vf-icon-VF_full_water_quartal_icon_rgb:before {
  content: "";
}

.vf-icon-VF_gas_rgb:before {
  content: "";
}

.vf-icon-VF_globe_rgb:before {
  content: "";
}

.vf-icon-VF_good_deal_rgb:before {
  content: "";
}

.vf-icon-VF_group_discussion_rgb:before {
  content: "";
}

.vf-icon-VF_heating_rgb:before {
  content: "";
}

.vf-icon-VF_hot_rgb:before {
  content: "";
}

.vf-icon-VF_house_rgb:before {
  content: "";
}

.vf-icon-VF_hydro_rgb:before {
  content: "";
}

.vf-icon-VF_in_queue_rgb:before {
  content: "";
}

.vf-icon-VF_industry_rgb:before {
  content: "";
}

.vf-icon-VF_invoices_rgb:before {
  content: "";
}

.vf-icon-VF_large_battery_rgb:before {
  content: "";
}

.vf-icon-VF_lecture_rgb:before {
  content: "";
}

.vf-icon-VF_life_events_rgb:before {
  content: "";
}

.vf-icon-VF_light_bulb_rgb:before {
  content: "";
}

.vf-icon-VF_manage_my_team_rgb:before {
  content: "";
}

.vf-icon-VF_mobility_rgb:before {
  content: "";
}

.vf-icon-VF_modules_rgb:before {
  content: "";
}

.vf-icon-VF_move_with_existing_contract_rgb:before {
  content: "";
}

.vf-icon-VF_move_with_new_contract_rgb:before {
  content: "";
}

.vf-icon-VF_multi_home_box_rgb:before {
  content: "";
}

.vf-icon-VF_my_cases_rgb:before {
  content: "";
}

.vf-icon-VF_my_documents_rgb:before {
  content: "";
}

.vf-icon-VF_nighttime_electricity_metering_rgb:before {
  content: "";
}

.vf-icon-VF_nuclear_rgb:before {
  content: "";
}

.vf-icon-VF_ocean_energy_rgb:before {
  content: "";
}

.vf-icon-VF_offshore_rgb:before {
  content: "";
}

.vf-icon-VF_oil_condesing_gas_turbine_rgb:before {
  content: "";
}

.vf-icon-VF_partners_rgb:before {
  content: "";
}

.vf-icon-VF_peat_rgb:before {
  content: "";
}

.vf-icon-VF_planned_outage_rgb:before {
  content: "";
}

.vf-icon-VF_policies_rgb:before {
  content: "";
}

.vf-icon-VF_power_grid_rgb:before {
  content: "";
}

.vf-icon-VF_power_plant_rgb:before {
  content: "";
}

.vf-icon-VF_premium_rgb:before {
  content: "";
}

.vf-icon-VF_present_rgb:before {
  content: "";
}

.vf-icon-VF_private_end_user_rgb:before {
  content: "";
}

.vf-icon-VF_private_rgb:before {
  content: "";
}

.vf-icon-VF_recycling_rgb:before {
  content: "";
}

.vf-icon-VF_salary_time_and_benefits_rgb:before {
  content: "";
}

.vf-icon-VF_semi_detached_house_rgb:before {
  content: "";
}

.vf-icon-VF_ship_docking_rgb:before {
  content: "";
}

.vf-icon-VF_smart_home_rgb:before {
  content: "";
}

.vf-icon-VF_smartphone_text_rgb:before {
  content: "";
}

.vf-icon-VF_solar_panel_rgb:before {
  content: "";
}

.vf-icon-VF_solar_power_rgb:before {
  content: "";
}

.vf-icon-VF_standard_contract_continuos_rgb:before {
  content: "";
}

.vf-icon-VF_support_rgb:before {
  content: "";
}

.vf-icon-VF_temperature_rgb:before {
  content: "";
}

.vf-icon-VF_voice_search_rgb:before {
  content: "";
}

.vf-icon-VF_wind_rgb:before {
  content: "";
}

.vf-icon-VF_card_and_tag_rgb:before {
  content: "";
}

.vf-icon-VF_charge_up_your_business_rgb:before {
  content: "";
}

.vf-icon-VF_charging_wall_box_rgb:before {
  content: "";
}

.vf-icon-VF_parking_meter_rgb:before {
  content: "";
}

.vf-icon-VF_partner_rgb:before {
  content: "";
}

.vf-icon-VF_private_charging_rgb:before {
  content: "";
}

.vf-icon-VF_public_business_charging_rgb:before {
  content: "";
}

.vf-icon-VF_public_charging_rgb:before {
  content: "";
}

.vf-icon-VF_scooter_2_rgb:before {
  content: "";
}

.vf-icon-VF_scooter_rgb:before {
  content: "";
}

.vf-icon-VF_smart_charging_poles_rgb:before {
  content: "";
}

.vf-icon-VF_check_rgb:before {
  content: "";
}

.vf-icon-VF_close_rgb:before {
  content: "";
}

.vf-icon-VF_down_rgb:before {
  content: "";
}

.vf-icon-VF_filter_rgb:before {
  content: "";
}

.vf-icon-VF_home_rgb:before {
  content: "";
}

.vf-icon-VF_left_rgb:before {
  content: "";
}

.vf-icon-VF_less_info_rgb:before {
  content: "";
}

.vf-icon-VF_menu_rgb:before {
  content: "";
}

.vf-icon-VF_more_rgb:before {
  content: "";
}

.vf-icon-VF_refresh_rgb:before {
  content: "";
}

.vf-icon-VF_right_rgb:before {
  content: "";
}

.vf-icon-VF_rss_rgb:before {
  content: "";
}

.vf-icon-VF_search_rgb:before {
  content: "";
}

.vf-icon-VF_send_rgb:before {
  content: "";
}

.vf-icon-VF_up_rgb:before {
  content: "";
}

.vf-icon-VF_TV_remote_rgb:before {
  content: "";
}

.vf-icon-VF_alarm_rgb:before {
  content: "";
}

.vf-icon-VF_cloudy_rgb:before {
  content: "";
}

.vf-icon-VF_coffee_maker_rgb:before {
  content: "";
}

.vf-icon-VF_console_rgb:before {
  content: "";
}

.vf-icon-VF_desklamp_rgb:before {
  content: "";
}

.vf-icon-VF_dishwasher_rgb:before {
  content: "";
}

.vf-icon-VF_door_closed_rgb:before {
  content: "";
}

.vf-icon-VF_door_open_rgb:before {
  content: "";
}

.vf-icon-VF_dryer_rgb:before {
  content: "";
}

.vf-icon-VF_fridge_freezer_rgb:before {
  content: "";
}

.vf-icon-VF_ice_rgb:before {
  content: "";
}

.vf-icon-VF_kettle_rgb:before {
  content: "";
}

.vf-icon-VF_laptop_rgb:before {
  content: "";
}

.vf-icon-VF_light_switch_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_step_1_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_step_2_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_step_3_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_step_4_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_step_5_rgb:before {
  content: "";
}

.vf-icon-VF_microwave_rgb:before {
  content: "";
}

.vf-icon-VF_moon_rgb:before {
  content: "";
}

.vf-icon-VF_phone_charging_rgb:before {
  content: "";
}

.vf-icon-VF_radiator_rgb:before {
  content: "";
}

.vf-icon-VF_rain_rgb:before {
  content: "";
}

.vf-icon-VF_router_rgb:before {
  content: "";
}

.vf-icon-VF_smart_plug_rgb:before {
  content: "";
}

.vf-icon-VF_smoke_alarm_rgb:before {
  content: "";
}

.vf-icon-VF_snow_rgb:before {
  content: "";
}

.vf-icon-VF_sun_rgb:before {
  content: "";
}

.vf-icon-VF_table_lamp_2_rgb:before {
  content: "";
}

.vf-icon-VF_table_lamp_rgb:before {
  content: "";
}

.vf-icon-VF_thunder_rgb:before {
  content: "";
}

.vf-icon-VF_ventilator_rgb:before {
  content: "";
}

.vf-icon-VF_washing_machine_rgb:before {
  content: "";
}

.vf-icon-VF_window_blinds_rgb:before {
  content: "";
}

.vf-icon-VF_window_closed_rgb:before {
  content: "";
}

.vf-icon-VF_window_open_rgb:before {
  content: "";
}

.vf-icon-VF_window_rgb:before {
  content: "";
}

.vf-icon-VF_dropbox_rgb:before {
  content: "";
}

.vf-icon-VF_facebook_rgb:before {
  content: "";
}

.vf-icon-VF_flickr_rgb:before {
  content: "";
}

.vf-icon-VF_google_plus_rgb:before {
  content: "";
}

.vf-icon-VF_instagram_rgb:before {
  content: "";
}

.vf-icon-VF_linkedin_rgb:before {
  content: "";
}

.vf-icon-VF_pinterest_rgb:before {
  content: "";
}

.vf-icon-VF_skype_rgb:before {
  content: "";
}

.vf-icon-VF_slideshare_rgb:before {
  content: "";
}

.vf-icon-VF_snapchat_rgb:before {
  content: "";
}

.vf-icon-VF_twitter_rgb:before {
  content: "";
}

.vf-icon-VF_vimeo_rgb:before {
  content: "";
}

.vf-icon-VF_xing_rgb:before {
  content: "";
}

.vf-icon-VF_youtube_rgb:before {
  content: "";
}

/* primary brand colors */
/* secondary web colors */
/* background web colors */
/* tertiary web colors */
/* misc colors */
/* todo: setting temporary color for focus borders 
while awaiting requirements */
/*
References:

https://digitaldesign.vattenfall.com/responsive 
 widths  375 - 667 - 768 - 1024 - 1920
*/
/*
  Container Breakpoints inherited from Vattenfall Digital Design System.
*/
/* 
This file is a mix of two graphical sources:
Vattenfall official styleguide
https://digitaldesign.vattenfall.com/css/text
"my-pages contract"
https://vattenfall.invisionapp.com/share/42U9SSSVANU#/screens/393223332

Provide style as a class and a mixin, to be able to use 
in projected content.

The classes and mixins have syntax to specify size, weight and line-height.
The intention of this formilized syntax is to assemble the set of fontstyles
into tag-agnostic sets. This means for example that a h1 may have different 
appearance. 

See the fonts.stories.ts for examples.

mixin codes::
@mixin fonts-[size]-[weight]-[lineheight]

usage:
p {
  @include font-fs16-fw5-lh22;
}

All fontstyle codes within the ui-lib are:
font-fs32-fw9-lh38 (largest heading : info-table-heading)
font-fs26-fw9-lh30
font-fs20-fw6-lh24 (dashboard titel, mix of project design and implementation based on ddb)
font-fs18-fw5-lh24 (ui-button)
font-fs16-fw7-lh30 
font-fs16-fw5-lh19 (small condensed: links, buttons, small heading)
font-fs16-fw4-lh19 (select-input-item-primary-title)
font-fs16-fw4-lh28 (default)
font-fs16-fw5-lh22 (dt,dd in for example key-value-list)
font-fs16-fw4-lh24
font-fs16-fw4-lh22 (key-value-list: dt, dd)
font-fs14-fw5-lh20 (key-value-list: dt, dd)
font-fs14-fw5-lh18 (ui-button)
font-fs14-fw4-lh20 (key-value-list: dt, dd)
font-fs14-fw4-lh14 (selector: .select-input-item-secondary-title)
font-fs12-fw5-lh14 
font-fs12-fw4-lh19
font-icon

The ddb system:
font-fs72-fw6-lh84
font-fs52-fw6-lh72
font-fs36-fw6-lh40
font-fs28-fw6-lh37
font-fs24-fw6-lh28
font-fs24-fw6-lh36
font-fs24-fw6-lh36
font-fs24-fw5-lh40
font-fs20-fw5-lh36
font-fs12-fw6-lh22
font-fs16-fw6-lh36
font-fs16-fw4-lh28

The design for 'mina avtal' and 'mina fakturor':
font-fs52-fw7-lh72

The current 'mina fakturor' mobile:
font-fs22-fw7-lh28

Guidelines:
Favor usage of mixins.
Default to 'inherit'.
Avoid style on elements. (h1 may for example be used inside an article anywhere on the page, and
may have other size than h1 inside the main)
*/
.font-fs16-fw4-lh28 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  font-size: 1rem;
  line-height: 1.75rem;
  color: #222222;
}

.font-icon {
  font-family: "Vattenfall Icons", "sans-serif";
  user-select: none;
  font-style: normal;
}

/* font-fs20-fw6-lh24: Used in main dashboard widget titles 'mina avtal', 'mina fakturor' and more*/
.font-fs20-fw6-lh24 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0rem;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

/* font-fs52-fw6-lh72: Used in main title 'mina avtal', 'mina fakturor' */
.font-fs52-fw6-lh72 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0rem;
  font-size: 3.25rem;
  line-height: 4.5rem;
}
@media (max-width: 599px) {
  .font-fs52-fw6-lh72 {
    font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
    font-weight: 700;
    letter-spacing: 0rem;
    font-size: 1.375rem;
    line-height: 1.75rem;
  }
}

/* font-fs26-fw9-lh30 */
.font-fs26-fw9-lh30 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 900;
  letter-spacing: 0rem;
  font-size: 1.625rem;
  line-height: 1.875;
}

/* font-fs26-fw6-lh28 */
.font-fs26-fw6-lh28 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0rem;
  font-size: 1.625rem;
  line-height: 1.75;
}

/* font-fs32-fw9-lh38: Used in info-table-heading */
.font-fs32-fw9-lh38 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 900;
  letter-spacing: 0rem;
  font-size: 2rem;
  line-height: 2.375rem;
}
@media (max-width: 599px) {
  .font-fs32-fw9-lh38 {
    font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
    font-weight: 900;
    font-size: 1.375rem;
    line-height: 1.625rem;
  }
}

.font-fs32-fw4-lh40 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0rem;
  font-size: 2rem;
  line-height: 2.5rem;
}

/* font-fs16-fw4-lh19: Used in info-table-heading and accordion */
.font-fs16-fw4-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1875rem;
}

/* font-fs14-fw6-lh19: Used in flows-premise-contract-summary */
.font-fs14-fw6-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.1875rem;
}

/* font-fs14-fw4-lh18 Used in my-contact-notification-dialog */
.font-fs14-fw4-lh18 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
}

/* font-fs14-fw5-lh18: Used in ui-button */
.font-fs14-fw5-lh18 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.125rem;
}

/* font-fs14-fw4-lh19: Used in flows-premise-contract-summary */
.font-fs14-fw4-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.1875rem;
}

/* font-fs14-fw4-lh17: Used in flows-premise-contract-summary */
.font-fs14-fw4-lh17 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.0625rem;
}

/* font-fs14-fw4-lh14: Used in select-input */
.font-fs14-fw4-lh14 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 0.875rem;
}

/* font-fs12-fw5-lh14 */
.font-fs12-fw5-lh14 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.875rem;
}

/* font-fs12-fw4-lh19 */
.font-fs12-fw4-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.1875rem;
}

/* font-fs18-fw4-lh28: Used in checkout top sections */
.font-fs18-fw4-lh28 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
}

/* font-fs18-fw5-lh24: Used in ui-button */
.font-fs18-fw5-lh24 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
}

/* font-fs18-fw5-lh24: Used in checkout top section */
.font-fs18-fw6-lh24 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.font-fs16-fw5-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.1875rem;
}

.font-fs16-fw7-lh30 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.875;
}

.font-fs16-fw6-lh22 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375rem;
}

.font-fs16-fw6-lh28 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.75rem;
}

.font-fs16-fw6-lh32 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2rem;
}

.font-fs16-fw4-lh24 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.font-fs16-fw4-lh20 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.font-fs14-fw5-lh20 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
}

.font-fs16-fw5-lh22 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375rem;
}
@media (max-width: 599px) {
  .font-fs16-fw5-lh22 {
    font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
  }
}

.font-fs16-fw4-lh22 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
}
@media (max-width: 599px) {
  .font-fs16-fw4-lh22 {
    font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
  }
}

.font-fs16-fw5-lh22 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375rem;
}
@media (max-width: 599px) {
  .font-fs16-fw5-lh22 {
    font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
  }
}

/* used in info-table-footer fonts-a */
.font-fs16-fw5-lh19 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.1875rem;
}

.font-fs24-fw8-lh32 {
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2rem;
}

/*
References:

https://digitaldesign.vattenfall.com/responsive 
 widths  375 - 667 - 768 - 1024 - 1920
*/
/*
  Container Breakpoints inherited from Vattenfall Digital Design System.
*/
.ui-container {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: auto;
  margin-left: auto;
  clear: left;
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
}
@media (max-width: 576px) {
  .ui-container {
    max-width: 540px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 577px) and (max-width: 768px) {
  .ui-container {
    max-width: 720px;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .ui-container {
    max-width: 960px;
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media (min-width: 1201px) {
  .ui-container {
    max-width: 1160px;
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  .ui-container--variant-tight {
    max-width: 600px;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .ui-container--variant-tight {
    max-width: 774px;
  }
}
@media (min-width: 1201px) {
  .ui-container--variant-tight {
    max-width: 960px;
  }
}
.ui-container--variant-bleed {
  max-width: 1680px;
  margin: auto;
  padding-right: 20px;
  padding-left: 20px;
}
@media (max-width: 576px) {
  .ui-container--variant-bleed {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 577px) and (max-width: 768px) {
  .ui-container--variant-bleed {
    padding-left: 0;
    padding-right: 0;
  }
}
.ui-container--overlay-top {
  position: relative;
  top: -3.75rem;
  z-index: 2;
}

/* primary brand colors */
/* secondary web colors */
/* background web colors */
/* tertiary web colors */
/* misc colors */
/* todo: setting temporary color for focus borders 
while awaiting requirements */
/*
References:

https://digitaldesign.vattenfall.com/responsive 
 widths  375 - 667 - 768 - 1024 - 1920
*/
/*
  Container Breakpoints inherited from Vattenfall Digital Design System.
*/
.vattenfall-elements__ui-grid--border:not(.vattenfall-elements__ui-grid--gutter), .ui-grid--border:not(.ui-grid--gutter) {
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: #ccc;
}

.vattenfall-elements__ui-grid__tile, .ui-grid__tile {
  flex-grow: 2;
  display: block;
  box-sizing: border-box;
  width: 25%;
}
@media (max-width: 599px) {
  .vattenfall-elements__ui-grid__tile, .ui-grid__tile {
    width: 100% !important;
    min-width: 100% !important;
  }
}

.vattenfall-elements__ui-grid--border:not(.vattenfall-elements__ui-grid--gutter) .vattenfall-elements__ui-grid__tile, .ui-grid--border:not(.ui-grid--gutter) .ui-grid__tile {
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: #ccc;
}

.vattenfall-elements__ui-grid--border .vattenfall-elements__ui-grid__tile, .ui-grid--border .ui-grid__tile {
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
}

.ui-grid {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: left;
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
}
.ui-grid.ui-grid--cols-1 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /1) - 0rem);
}
.ui-grid.ui-grid--cols-2 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /2) - 0rem);
}
.ui-grid.ui-grid--cols-2 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /4) - 0rem);
}
.ui-grid.ui-grid--cols-3 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /3) - 0rem);
}
.ui-grid.ui-grid--cols-3 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /6) - 0rem);
}
.ui-grid.ui-grid--cols-3 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /9) - 0rem);
}
.ui-grid.ui-grid--cols-4 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /4) - 0rem);
}
.ui-grid.ui-grid--cols-4 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /8) - 0rem);
}
.ui-grid.ui-grid--cols-4 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /12) - 0rem);
}
.ui-grid.ui-grid--cols-4 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /16) - 0rem);
}
.ui-grid.ui-grid--cols-5 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /5) - 0rem);
}
.ui-grid.ui-grid--cols-5 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /10) - 0rem);
}
.ui-grid.ui-grid--cols-5 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /15) - 0rem);
}
.ui-grid.ui-grid--cols-5 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /20) - 0rem);
}
.ui-grid.ui-grid--cols-5 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /25) - 0rem);
}
.ui-grid.ui-grid--cols-6 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /6) - 0rem);
}
.ui-grid.ui-grid--cols-6 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /12) - 0rem);
}
.ui-grid.ui-grid--cols-6 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /18) - 0rem);
}
.ui-grid.ui-grid--cols-6 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /24) - 0rem);
}
.ui-grid.ui-grid--cols-6 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /30) - 0rem);
}
.ui-grid.ui-grid--cols-6 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /36) - 0rem);
}
.ui-grid.ui-grid--cols-7 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /7) - 0rem);
}
.ui-grid.ui-grid--cols-7 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /14) - 0rem);
}
.ui-grid.ui-grid--cols-7 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /21) - 0rem);
}
.ui-grid.ui-grid--cols-7 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /28) - 0rem);
}
.ui-grid.ui-grid--cols-7 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /35) - 0rem);
}
.ui-grid.ui-grid--cols-7 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /42) - 0rem);
}
.ui-grid.ui-grid--cols-7 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /49) - 0rem);
}
.ui-grid.ui-grid--cols-8 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /8) - 0rem);
}
.ui-grid.ui-grid--cols-8 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /16) - 0rem);
}
.ui-grid.ui-grid--cols-8 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /24) - 0rem);
}
.ui-grid.ui-grid--cols-8 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /32) - 0rem);
}
.ui-grid.ui-grid--cols-8 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /40) - 0rem);
}
.ui-grid.ui-grid--cols-8 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /48) - 0rem);
}
.ui-grid.ui-grid--cols-8 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /56) - 0rem);
}
.ui-grid.ui-grid--cols-8 .ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /64) - 0rem);
}
.ui-grid.ui-grid--cols-9 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /9) - 0rem);
}
.ui-grid.ui-grid--cols-9 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /18) - 0rem);
}
.ui-grid.ui-grid--cols-9 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /27) - 0rem);
}
.ui-grid.ui-grid--cols-9 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /36) - 0rem);
}
.ui-grid.ui-grid--cols-9 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /45) - 0rem);
}
.ui-grid.ui-grid--cols-9 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /54) - 0rem);
}
.ui-grid.ui-grid--cols-9 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /63) - 0rem);
}
.ui-grid.ui-grid--cols-9 .ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /72) - 0rem);
}
.ui-grid.ui-grid--cols-9 .ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /81) - 0rem);
}
.ui-grid.ui-grid--cols-10 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /10) - 0rem);
}
.ui-grid.ui-grid--cols-10 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /20) - 0rem);
}
.ui-grid.ui-grid--cols-10 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /30) - 0rem);
}
.ui-grid.ui-grid--cols-10 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /40) - 0rem);
}
.ui-grid.ui-grid--cols-10 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /50) - 0rem);
}
.ui-grid.ui-grid--cols-10 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /60) - 0rem);
}
.ui-grid.ui-grid--cols-10 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /70) - 0rem);
}
.ui-grid.ui-grid--cols-10 .ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /80) - 0rem);
}
.ui-grid.ui-grid--cols-10 .ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /90) - 0rem);
}
.ui-grid.ui-grid--cols-10 .ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /100) - 0rem);
}
.ui-grid.ui-grid--cols-11 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /11) - 0rem);
}
.ui-grid.ui-grid--cols-11 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /22) - 0rem);
}
.ui-grid.ui-grid--cols-11 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /33) - 0rem);
}
.ui-grid.ui-grid--cols-11 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /44) - 0rem);
}
.ui-grid.ui-grid--cols-11 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /55) - 0rem);
}
.ui-grid.ui-grid--cols-11 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /66) - 0rem);
}
.ui-grid.ui-grid--cols-11 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /77) - 0rem);
}
.ui-grid.ui-grid--cols-11 .ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /88) - 0rem);
}
.ui-grid.ui-grid--cols-11 .ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /99) - 0rem);
}
.ui-grid.ui-grid--cols-11 .ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /110) - 0rem);
}
.ui-grid.ui-grid--cols-11 .ui-grid__tile--colspan-11 {
  flex-basis: calc((100% /121) - 0rem);
}
.ui-grid.ui-grid--cols-12 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /12) - 0rem);
}
.ui-grid.ui-grid--cols-12 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /24) - 0rem);
}
.ui-grid.ui-grid--cols-12 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /36) - 0rem);
}
.ui-grid.ui-grid--cols-12 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /48) - 0rem);
}
.ui-grid.ui-grid--cols-12 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /60) - 0rem);
}
.ui-grid.ui-grid--cols-12 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /72) - 0rem);
}
.ui-grid.ui-grid--cols-12 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /84) - 0rem);
}
.ui-grid.ui-grid--cols-12 .ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /96) - 0rem);
}
.ui-grid.ui-grid--cols-12 .ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /108) - 0rem);
}
.ui-grid.ui-grid--cols-12 .ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /120) - 0rem);
}
.ui-grid.ui-grid--cols-12 .ui-grid__tile--colspan-11 {
  flex-basis: calc((100% /132) - 0rem);
}
.ui-grid.ui-grid--cols-12 .ui-grid__tile--colspan-12 {
  flex-basis: calc((100% /144) - 0rem);
}

.ui-grid--gutter {
  align-content: space-between;
  align-content: space-around;
  flex-direction: row;
  background: transparent;
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
}
.ui-grid--gutter.ui-grid--cols-1 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /1) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-1 .ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-1 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-1 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-2 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /2) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-2 .ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-2 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-2 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-2 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /4) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-2 .ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-2 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-2 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-3 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /3) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-3 .ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-3 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-3 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-3 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /6) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-3 .ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-3 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-3 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-3 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /9) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-3 .ui-grid__tile--colspan-3 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-3 .ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-3 .ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-4 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /4) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-4 .ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-4 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-4 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-4 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /8) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-4 .ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-4 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-4 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-4 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /12) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-4 .ui-grid__tile--colspan-3 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-4 .ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-4 .ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-4 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /16) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-4 .ui-grid__tile--colspan-4 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-4 .ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-4 .ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-5 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /5) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-5 .ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-5 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-5 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-5 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /10) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-5 .ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-5 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-5 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-5 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /15) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-5 .ui-grid__tile--colspan-3 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-5 .ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-5 .ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-5 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /20) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-5 .ui-grid__tile--colspan-4 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-5 .ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-5 .ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-5 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /25) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-5 .ui-grid__tile--colspan-5 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-5 .ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-5 .ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /6) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /12) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /18) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-3 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /24) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-4 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /30) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-5 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /36) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-6 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-6 .ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /7) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /14) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /21) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-3 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /28) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-4 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /35) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-5 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /42) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-6 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /49) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-7 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-7 .ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /8) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /16) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /24) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-3 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /32) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-4 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /40) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-5 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /48) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-6 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /56) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-7 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /64) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-8 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-8 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-8 .ui-grid__tile--colspan-8 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /9) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /18) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /27) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-3 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /36) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-4 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /45) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-5 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /54) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-6 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /63) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-7 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /72) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-8 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-8 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-8 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /81) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-9 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-9 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-9 .ui-grid__tile--colspan-9 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /10) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /20) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /30) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-3 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /40) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-4 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /50) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-5 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /60) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-6 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /70) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-7 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /80) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-8 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-8 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-8 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /90) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-9 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-9 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-9 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /100) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-10 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-10 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-10 .ui-grid__tile--colspan-10 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /11) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /22) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /33) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-3 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /44) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-4 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /55) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-5 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /66) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-6 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /77) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-7 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /88) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-8 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-8 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-8 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /99) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-9 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-9 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-9 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /110) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-10 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-10 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-10 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-11 {
  flex-basis: calc((100% /121) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-11 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-11 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-11 .ui-grid__tile--colspan-11 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /12) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /24) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /36) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-3 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /48) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-4 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /60) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-5 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /72) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-6 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /84) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-7 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /96) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-8 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-8 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-8 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /108) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-9 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-9 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-9 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /120) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-10 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-10 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-10 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-11 {
  flex-basis: calc((100% /132) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-11 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-11 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-11 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-12 {
  flex-basis: calc((100% /144) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-12 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-12 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter.ui-grid--cols-12 .ui-grid__tile--colspan-12 {
    margin: 0.25rem;
  }
}
.ui-grid--gutter__small {
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
}
.ui-grid--gutter__small.ui-grid--cols-1 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /1) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-1 .ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-1 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-1 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-2 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /2) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-2 .ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-2 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-2 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-2 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /4) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-2 .ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-2 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-2 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-3 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /3) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-3 .ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-3 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-3 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-3 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /6) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-3 .ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-3 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-3 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-3 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /9) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-3 .ui-grid__tile--colspan-3 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-3 .ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-3 .ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-4 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /4) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-4 .ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-4 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-4 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-4 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /8) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-4 .ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-4 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-4 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-4 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /12) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-4 .ui-grid__tile--colspan-3 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-4 .ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-4 .ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-4 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /16) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-4 .ui-grid__tile--colspan-4 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-4 .ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-4 .ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-5 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /5) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-5 .ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-5 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-5 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-5 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /10) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-5 .ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-5 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-5 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-5 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /15) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-5 .ui-grid__tile--colspan-3 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-5 .ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-5 .ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-5 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /20) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-5 .ui-grid__tile--colspan-4 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-5 .ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-5 .ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-5 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /25) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-5 .ui-grid__tile--colspan-5 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-5 .ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-5 .ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /6) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /12) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /18) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-3 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /24) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-4 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /30) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-5 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /36) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-6 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-6 .ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /7) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /14) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /21) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-3 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /28) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-4 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /35) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-5 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /42) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-6 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /49) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-7 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-7 .ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /8) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /16) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /24) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-3 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /32) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-4 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /40) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-5 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /48) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-6 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /56) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-7 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /64) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-8 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-8 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-8 .ui-grid__tile--colspan-8 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /9) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /18) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /27) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-3 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /36) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-4 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /45) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-5 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /54) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-6 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /63) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-7 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /72) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-8 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-8 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-8 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /81) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-9 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-9 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-9 .ui-grid__tile--colspan-9 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /10) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /20) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /30) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-3 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /40) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-4 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /50) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-5 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /60) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-6 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /70) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-7 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /80) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-8 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-8 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-8 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /90) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-9 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-9 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-9 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /100) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-10 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-10 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-10 .ui-grid__tile--colspan-10 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /11) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /22) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /33) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-3 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /44) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-4 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /55) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-5 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /66) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-6 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /77) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-7 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /88) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-8 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-8 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-8 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /99) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-9 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-9 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-9 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /110) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-10 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-10 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-10 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-11 {
  flex-basis: calc((100% /121) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-11 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-11 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-11 .ui-grid__tile--colspan-11 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /12) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /24) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /36) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-3 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /48) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-4 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /60) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-5 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /72) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-6 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /84) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-7 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /96) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-8 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-8 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-8 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /108) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-9 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-9 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-9 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /120) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-10 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-10 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-10 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-11 {
  flex-basis: calc((100% /132) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-11 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-11 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-11 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-12 {
  flex-basis: calc((100% /144) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-12 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-12 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__small.ui-grid--cols-12 .ui-grid__tile--colspan-12 {
    margin: 0.6875rem;
  }
}
.ui-grid--gutter__large {
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
}
.ui-grid--gutter__large.ui-grid--cols-1 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /1) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-1 .ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-1 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-1 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-2 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /2) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-2 .ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-2 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-2 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-2 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /4) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-2 .ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-2 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-2 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-3 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /3) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-3 .ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-3 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-3 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-3 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /6) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-3 .ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-3 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-3 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-3 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /9) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-3 .ui-grid__tile--colspan-3 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-3 .ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-3 .ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-4 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /4) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-4 .ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-4 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-4 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-4 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /8) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-4 .ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-4 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-4 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-4 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /12) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-4 .ui-grid__tile--colspan-3 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-4 .ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-4 .ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-4 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /16) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-4 .ui-grid__tile--colspan-4 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-4 .ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-4 .ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-5 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /5) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-5 .ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-5 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-5 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-5 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /10) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-5 .ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-5 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-5 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-5 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /15) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-5 .ui-grid__tile--colspan-3 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-5 .ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-5 .ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-5 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /20) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-5 .ui-grid__tile--colspan-4 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-5 .ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-5 .ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-5 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /25) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-5 .ui-grid__tile--colspan-5 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-5 .ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-5 .ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /6) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /12) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /18) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-3 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /24) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-4 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /30) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-5 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /36) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-6 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-6 .ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /7) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /14) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /21) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-3 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /28) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-4 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /35) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-5 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /42) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-6 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /49) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-7 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-7 .ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /8) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /16) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /24) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-3 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /32) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-4 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /40) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-5 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /48) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-6 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /56) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-7 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /64) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-8 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-8 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-8 .ui-grid__tile--colspan-8 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /9) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /18) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /27) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-3 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /36) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-4 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /45) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-5 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /54) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-6 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /63) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-7 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /72) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-8 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-8 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-8 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /81) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-9 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-9 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-9 .ui-grid__tile--colspan-9 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /10) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /20) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /30) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-3 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /40) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-4 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /50) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-5 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /60) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-6 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /70) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-7 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /80) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-8 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-8 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-8 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /90) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-9 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-9 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-9 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /100) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-10 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-10 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-10 .ui-grid__tile--colspan-10 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /11) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /22) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /33) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-3 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /44) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-4 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /55) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-5 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /66) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-6 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /77) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-7 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /88) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-8 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-8 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-8 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /99) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-9 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-9 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-9 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /110) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-10 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-10 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-10 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-11 {
  flex-basis: calc((100% /121) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-11 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-11 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-11 .ui-grid__tile--colspan-11 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /12) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /24) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /36) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-3 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /48) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-4 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /60) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-5 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /72) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-6 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /84) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-7 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /96) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-8 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-8 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-8 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /108) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-9 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-9 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-9 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /120) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-10 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-10 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-10 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-11 {
  flex-basis: calc((100% /132) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-11 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-11 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-11 {
    margin: 1rem;
  }
}
.ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-12 {
  flex-basis: calc((100% /144) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-12 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-12 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .ui-grid--gutter__large.ui-grid--cols-12 .ui-grid__tile--colspan-12 {
    margin: 1rem;
  }
}
.ui-grid--gutter .ui-grid__tile {
  align-self: flex-start;
}

/* primary brand colors */
/* secondary web colors */
/* background web colors */
/* tertiary web colors */
/* misc colors */
/* todo: setting temporary color for focus borders 
while awaiting requirements */
.ui-list {
  /*
  * Custom classes
  */
  /*
  * padding="none" - to have the list starting at the edge of the container, 
  * canceling default padding of an list.
  */
  /*
  * padding="default" - the default padding of lists with 1rem;
  */
  /**
  * padding="large" - when we need the list to nest deeper in the view
  */
  /*
   * padding="small" - when the list renders closer to the edge. 
  */
}
.ui-list li {
  font-size: 0.875rem;
  list-style: none;
}
.ui-list li:before {
  content: "-";
  font-weight: bold;
  width: 8px;
  margin-left: -1rem;
  margin-right: 0.3rem;
  color: #222222;
}
.ui-list[variant=disc] li {
  line-height: 1.3rem;
}
.ui-list[variant=disc] li:before {
  /* content: \2022 is the CSS Code/unicode for a bullet */
  content: "•";
  font-size: 1.9rem;
  line-height: 1rem;
  vertical-align: sub;
}
.ui-list[variant=diamond] {
  font-size: 1rem;
  list-style: none;
}
.ui-list[variant=diamond] li:before {
  /* content: \2666 is the CSS Code/unicode for a diamond */
  content: "♦";
}
.ui-list[color=blue] li:before {
  color: #2071b5;
}
.ui-list[color=yellow] li:before {
  color: #ffda00;
}
.ui-list[color=gray] li:before {
  color: #4e4b48;
}
.ui-list[padding=none] {
  padding: 0;
}
.ui-list[padding=default] {
  padding: 1rem 1rem;
}
.ui-list[padding=large] {
  padding: 2rem 1rem;
}
.ui-list[padding=small] {
  padding: 0.5rem 1rem;
}

/*
References:

https://digitaldesign.vattenfall.com/responsive 
 widths  375 - 667 - 768 - 1024 - 1920
*/
/*
  Container Breakpoints inherited from Vattenfall Digital Design System.
*/
i[class^=vf-icon-]:before,
i[class*=" vf-icon-"]:before {
  font-family: "Vattenfall Icons", sans-serif !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vf-icon-VF_add_contact_rgb:before {
  content: "";
}

.vf-icon-VF_alert_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_down_left_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_down_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_down_right_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_left_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_right_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_up_left_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_up_rgb:before {
  content: "";
}

.vf-icon-VF_arrow_up_right_rgb:before {
  content: "";
}

.vf-icon-VF_calendar_rgb:before {
  content: "";
}

.vf-icon-VF_call_rgb:before {
  content: "";
}

.vf-icon-VF_camera_rgb:before {
  content: "";
}

.vf-icon-VF_chat_rgb:before {
  content: "";
}

.vf-icon-VF_checkbox_rgb:before {
  content: "";
}

.vf-icon-VF_download_pdf_rgb:before {
  content: "";
}

.vf-icon-VF_download_rgb:before {
  content: "";
}

.vf-icon-VF_edit_rgb:before {
  content: "";
}

.vf-icon-VF_elipsis_rgb:before, .vattenfall-elements__edit-link:before {
  content: "";
}

.vf-icon-VF_eye_rgb:before {
  content: "";
}

.vf-icon-VF_favourite_rgb:before {
  content: "";
}

.vf-icon-VF_gallery_rgb:before {
  content: "";
}

.vf-icon-VF_guide_icon_rgb:before {
  content: "";
}

.vf-icon-VF_heart_rgb:before {
  content: "";
}

.vf-icon-VF_help_rgb:before {
  content: "";
}

.vf-icon-VF_information_rgb:before {
  content: "";
}

.vf-icon-VF_locked_rgb:before {
  content: "";
}

.vf-icon-VF_mail_rgb:before {
  content: "";
}

.vf-icon-VF_maps_rgb:before {
  content: "";
}

.vf-icon-VF_nina_avatar_background_rgb:before {
  content: "";
}

.vf-icon-VF_nina_happy_avatar_rgb:before {
  content: "";
}

.vf-icon-VF_notvisible_rgb:before {
  content: "";
}

.vf-icon-VF_off_rgb:before {
  content: "";
}

.vf-icon-VF_open_new_window_rgb:before {
  content: "";
}

.vf-icon-VF_pause_rgb:before {
  content: "";
}

.vf-icon-VF_phone_rgb:before {
  content: "";
}

.vf-icon-VF_pin_rgb:before {
  content: "";
}

.vf-icon-VF_play_rgb:before {
  content: "";
}

.vf-icon-VF_print_rgb:before {
  content: "";
}

.vf-icon-VF_settings_rgb:before {
  content: "";
}

.vf-icon-VF_share_rgb:before {
  content: "";
}

.vf-icon-VF_shop_rgb:before {
  content: "";
}

.vf-icon-VF_sign_out_rgb:before {
  content: "";
}

.vf-icon-VF_sort_rgb:before {
  content: "";
}

.vf-icon-VF_tag_rgb:before {
  content: "";
}

.vf-icon-VF_unlocked_rgb:before {
  content: "";
}

.vf-icon-VF_user_rgb:before {
  content: "";
}

.vf-icon-VF_zoom_in_rgb:before {
  content: "";
}

.vf-icon-VF_zoom_out_rgb:before {
  content: "";
}

.vf-icon-VF_allocate_agent_rgb:before {
  content: "";
}

.vf-icon-VF_allocate_location_rgb:before {
  content: "";
}

.vf-icon-VF_allocate_party_rgb:before {
  content: "";
}

.vf-icon-VF_allocate_team_rgb:before {
  content: "";
}

.vf-icon-VF_promotion_rgb:before {
  content: "";
}

.vf-icon-VF_Sustainable_use_of_resources_rgb:before {
  content: "";
}

.vf-icon-VF_airplane_rgb:before {
  content: "";
}

.vf-icon-VF_apartment_house_rgb:before {
  content: "";
}

.vf-icon-VF_battery_rgb:before {
  content: "";
}

.vf-icon-VF_benchmark_co2_rgb:before {
  content: "";
}

.vf-icon-VF_benchmark_cockpit_rgb:before {
  content: "";
}

.vf-icon-VF_benchmark_rgb:before {
  content: "";
}

.vf-icon-VF_benchmark_temperature_rgb:before {
  content: "";
}

.vf-icon-VF_benefit_rgb:before {
  content: "";
}

.vf-icon-VF_biofuel-biomass_rgb:before {
  content: "";
}

.vf-icon-VF_brainstorm_rgb:before {
  content: "";
}

.vf-icon-VF_callback_rgb:before {
  content: "";
}

.vf-icon-VF_chart_rgb:before {
  content: "";
}

.vf-icon-VF_city_rgb:before {
  content: "";
}

.vf-icon-VF_clock_rgb:before {
  content: "";
}

.vf-icon-VF_coal_rgb:before {
  content: "";
}

.vf-icon-VF_cold_rgb:before {
  content: "";
}

.vf-icon-VF_combined_heat_and_power_rgb:before {
  content: "";
}

.vf-icon-VF_consumption_rgb:before {
  content: "";
}

.vf-icon-VF_contract_details_rgb:before {
  content: "";
}

.vf-icon-VF_corporate_rgb:before {
  content: "";
}

.vf-icon-VF_cost_saving_rgb:before {
  content: "";
}

.vf-icon-VF_cottage_rgb:before {
  content: "";
}

.vf-icon-VF_currency_rgb:before {
  content: "";
}

.vf-icon-VF_defence_rgb:before {
  content: "";
}

.vf-icon-VF_discussion_B_rgb:before {
  content: "";
}

.vf-icon-VF_discussion_rgb:before {
  content: "";
}

.vf-icon-VF_distribution_rgb:before {
  content: "";
}

.vf-icon-VF_dont_rgb:before {
  content: "";
}

.vf-icon-VF_e-bike_rgb:before {
  content: "";
}

.vf-icon-VF_e-mobility_rgb:before {
  content: "";
}

.vf-icon-VF_economize_rgb:before {
  content: "";
}

.vf-icon-VF_eko_hourly_spot_rgb:before {
  content: "";
}

.vf-icon-VF_electricity_rgb:before {
  content: "";
}

.vf-icon-VF_electronic_payment_euro_rgb:before {
  content: "";
}

.vf-icon-VF_energy_mix_rgb:before {
  content: "";
}

.vf-icon-VF_energy_solutions_rgb:before {
  content: "";
}

.vf-icon-VF_euro_rgb:before {
  content: "";
}

.vf-icon-VF_evaluation_rgb:before {
  content: "";
}

.vf-icon-VF_farm_rgb:before {
  content: "";
}

.vf-icon-VF_fax_rgb:before {
  content: "";
}

.vf-icon-VF_full_water_quartal_icon_rgb:before {
  content: "";
}

.vf-icon-VF_gas_rgb:before {
  content: "";
}

.vf-icon-VF_globe_rgb:before {
  content: "";
}

.vf-icon-VF_good_deal_rgb:before {
  content: "";
}

.vf-icon-VF_group_discussion_rgb:before {
  content: "";
}

.vf-icon-VF_heating_rgb:before {
  content: "";
}

.vf-icon-VF_hot_rgb:before {
  content: "";
}

.vf-icon-VF_house_rgb:before {
  content: "";
}

.vf-icon-VF_hydro_rgb:before {
  content: "";
}

.vf-icon-VF_in_queue_rgb:before {
  content: "";
}

.vf-icon-VF_industry_rgb:before {
  content: "";
}

.vf-icon-VF_invoices_rgb:before {
  content: "";
}

.vf-icon-VF_large_battery_rgb:before {
  content: "";
}

.vf-icon-VF_lecture_rgb:before {
  content: "";
}

.vf-icon-VF_life_events_rgb:before {
  content: "";
}

.vf-icon-VF_light_bulb_rgb:before {
  content: "";
}

.vf-icon-VF_manage_my_team_rgb:before {
  content: "";
}

.vf-icon-VF_mobility_rgb:before {
  content: "";
}

.vf-icon-VF_modules_rgb:before {
  content: "";
}

.vf-icon-VF_move_with_existing_contract_rgb:before {
  content: "";
}

.vf-icon-VF_move_with_new_contract_rgb:before {
  content: "";
}

.vf-icon-VF_multi_home_box_rgb:before {
  content: "";
}

.vf-icon-VF_my_cases_rgb:before {
  content: "";
}

.vf-icon-VF_my_documents_rgb:before {
  content: "";
}

.vf-icon-VF_nighttime_electricity_metering_rgb:before {
  content: "";
}

.vf-icon-VF_nuclear_rgb:before {
  content: "";
}

.vf-icon-VF_ocean_energy_rgb:before {
  content: "";
}

.vf-icon-VF_offshore_rgb:before {
  content: "";
}

.vf-icon-VF_oil_condesing_gas_turbine_rgb:before {
  content: "";
}

.vf-icon-VF_partners_rgb:before {
  content: "";
}

.vf-icon-VF_peat_rgb:before {
  content: "";
}

.vf-icon-VF_planned_outage_rgb:before {
  content: "";
}

.vf-icon-VF_policies_rgb:before {
  content: "";
}

.vf-icon-VF_power_grid_rgb:before {
  content: "";
}

.vf-icon-VF_power_plant_rgb:before {
  content: "";
}

.vf-icon-VF_premium_rgb:before {
  content: "";
}

.vf-icon-VF_present_rgb:before {
  content: "";
}

.vf-icon-VF_private_end_user_rgb:before {
  content: "";
}

.vf-icon-VF_private_rgb:before {
  content: "";
}

.vf-icon-VF_recycling_rgb:before {
  content: "";
}

.vf-icon-VF_salary_time_and_benefits_rgb:before {
  content: "";
}

.vf-icon-VF_semi_detached_house_rgb:before {
  content: "";
}

.vf-icon-VF_ship_docking_rgb:before {
  content: "";
}

.vf-icon-VF_smart_home_rgb:before {
  content: "";
}

.vf-icon-VF_smartphone_text_rgb:before {
  content: "";
}

.vf-icon-VF_solar_panel_rgb:before {
  content: "";
}

.vf-icon-VF_solar_power_rgb:before {
  content: "";
}

.vf-icon-VF_standard_contract_continuos_rgb:before {
  content: "";
}

.vf-icon-VF_support_rgb:before {
  content: "";
}

.vf-icon-VF_temperature_rgb:before {
  content: "";
}

.vf-icon-VF_voice_search_rgb:before {
  content: "";
}

.vf-icon-VF_wind_rgb:before {
  content: "";
}

.vf-icon-VF_card_and_tag_rgb:before {
  content: "";
}

.vf-icon-VF_charge_up_your_business_rgb:before {
  content: "";
}

.vf-icon-VF_charging_wall_box_rgb:before {
  content: "";
}

.vf-icon-VF_parking_meter_rgb:before {
  content: "";
}

.vf-icon-VF_partner_rgb:before {
  content: "";
}

.vf-icon-VF_private_charging_rgb:before {
  content: "";
}

.vf-icon-VF_public_business_charging_rgb:before {
  content: "";
}

.vf-icon-VF_public_charging_rgb:before {
  content: "";
}

.vf-icon-VF_scooter_2_rgb:before {
  content: "";
}

.vf-icon-VF_scooter_rgb:before {
  content: "";
}

.vf-icon-VF_smart_charging_poles_rgb:before {
  content: "";
}

.vf-icon-VF_check_rgb:before {
  content: "";
}

.vf-icon-VF_close_rgb:before {
  content: "";
}

.vf-icon-VF_down_rgb:before {
  content: "";
}

.vf-icon-VF_filter_rgb:before {
  content: "";
}

.vf-icon-VF_home_rgb:before {
  content: "";
}

.vf-icon-VF_left_rgb:before {
  content: "";
}

.vf-icon-VF_less_info_rgb:before {
  content: "";
}

.vf-icon-VF_menu_rgb:before {
  content: "";
}

.vf-icon-VF_more_rgb:before {
  content: "";
}

.vf-icon-VF_refresh_rgb:before {
  content: "";
}

.vf-icon-VF_right_rgb:before {
  content: "";
}

.vf-icon-VF_rss_rgb:before {
  content: "";
}

.vf-icon-VF_search_rgb:before {
  content: "";
}

.vf-icon-VF_send_rgb:before {
  content: "";
}

.vf-icon-VF_up_rgb:before {
  content: "";
}

.vf-icon-VF_TV_remote_rgb:before {
  content: "";
}

.vf-icon-VF_alarm_rgb:before {
  content: "";
}

.vf-icon-VF_cloudy_rgb:before {
  content: "";
}

.vf-icon-VF_coffee_maker_rgb:before {
  content: "";
}

.vf-icon-VF_console_rgb:before {
  content: "";
}

.vf-icon-VF_desklamp_rgb:before {
  content: "";
}

.vf-icon-VF_dishwasher_rgb:before {
  content: "";
}

.vf-icon-VF_door_closed_rgb:before {
  content: "";
}

.vf-icon-VF_door_open_rgb:before {
  content: "";
}

.vf-icon-VF_dryer_rgb:before {
  content: "";
}

.vf-icon-VF_fridge_freezer_rgb:before {
  content: "";
}

.vf-icon-VF_ice_rgb:before {
  content: "";
}

.vf-icon-VF_kettle_rgb:before {
  content: "";
}

.vf-icon-VF_laptop_rgb:before {
  content: "";
}

.vf-icon-VF_light_switch_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_step_1_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_step_2_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_step_3_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_step_4_rgb:before {
  content: "";
}

.vf-icon-VF_lightbulb_step_5_rgb:before {
  content: "";
}

.vf-icon-VF_microwave_rgb:before {
  content: "";
}

.vf-icon-VF_moon_rgb:before {
  content: "";
}

.vf-icon-VF_phone_charging_rgb:before {
  content: "";
}

.vf-icon-VF_radiator_rgb:before {
  content: "";
}

.vf-icon-VF_rain_rgb:before {
  content: "";
}

.vf-icon-VF_router_rgb:before {
  content: "";
}

.vf-icon-VF_smart_plug_rgb:before {
  content: "";
}

.vf-icon-VF_smoke_alarm_rgb:before {
  content: "";
}

.vf-icon-VF_snow_rgb:before {
  content: "";
}

.vf-icon-VF_sun_rgb:before {
  content: "";
}

.vf-icon-VF_table_lamp_2_rgb:before {
  content: "";
}

.vf-icon-VF_table_lamp_rgb:before {
  content: "";
}

.vf-icon-VF_thunder_rgb:before {
  content: "";
}

.vf-icon-VF_ventilator_rgb:before {
  content: "";
}

.vf-icon-VF_washing_machine_rgb:before {
  content: "";
}

.vf-icon-VF_window_blinds_rgb:before {
  content: "";
}

.vf-icon-VF_window_closed_rgb:before {
  content: "";
}

.vf-icon-VF_window_open_rgb:before {
  content: "";
}

.vf-icon-VF_window_rgb:before {
  content: "";
}

.vf-icon-VF_dropbox_rgb:before {
  content: "";
}

.vf-icon-VF_facebook_rgb:before {
  content: "";
}

.vf-icon-VF_flickr_rgb:before {
  content: "";
}

.vf-icon-VF_google_plus_rgb:before {
  content: "";
}

.vf-icon-VF_instagram_rgb:before {
  content: "";
}

.vf-icon-VF_linkedin_rgb:before {
  content: "";
}

.vf-icon-VF_pinterest_rgb:before {
  content: "";
}

.vf-icon-VF_skype_rgb:before {
  content: "";
}

.vf-icon-VF_slideshare_rgb:before {
  content: "";
}

.vf-icon-VF_snapchat_rgb:before {
  content: "";
}

.vf-icon-VF_twitter_rgb:before {
  content: "";
}

.vf-icon-VF_vimeo_rgb:before {
  content: "";
}

.vf-icon-VF_xing_rgb:before {
  content: "";
}

.vf-icon-VF_youtube_rgb:before {
  content: "";
}

@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 300;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-Light.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Light.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Light.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 300;
  font-style: italic;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-LightItalic.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-LightItalic.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-LightItalic.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 400;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-Regular.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Regular.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Regular.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 400;
  font-style: italic;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-RegularItalic.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-RegularItalic.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-RegularItalic.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 500;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-Medium.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Medium.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Medium.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 500;
  font-style: italic;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-MediumItalic.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-MediumItalic.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-MediumItalic.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall Display";
  font-weight: 600;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHallDisplay-Bold.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHallDisplay-Bold.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHallDisplay-Bold.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 600;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-Bold.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Bold.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Bold.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 600;
  font-style: italic;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-BoldItalic.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-BoldItalic.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-BoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 700;
  font-style: normal;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-Heavy.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Heavy.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-Heavy.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Hall";
  font-weight: 700;
  font-style: italic;
  src: url("https://elements.vattenfall.se/fonts/VattenfallHall-HeavyItalic.eot") format("eot"), url("https://elements.vattenfall.se/fonts/VattenfallHall-HeavyItalic.woff2") format("woff2"), url("https://elements.vattenfall.se/fonts/VattenfallHall-HeavyItalic.woff") format("woff");
}
@font-face {
  font-family: "Vattenfall Icons";
  src: url("https://cdn.vattenfall.nl/vattenfallnlprd/1.2.0/assets/fonts/Vattenfall-Icons.eot") format("embedded-opentype"), url("https://cdn.vattenfall.nl/vattenfallnlprd/1.2.0/assets/fonts/Vattenfall-Icons.woff") format("woff"), url("https://cdn.vattenfall.nl/vattenfallnlprd/1.2.0/assets/fonts/Vattenfall-Icons.woff2") format("woff2"), url("https://cdn.vattenfall.nl/vattenfallnlprd/1.2.0/assets/fonts/Vattenfall-Icons.ttf") format("truetype");
}
@font-face {
  font-family: "Vattenfall Icons";
  src: url("https://elements.vattenfall.se/fonts/VattenfallElements-Icons.eot") format("embedded-opentype"), url("https://elements.vattenfall.se/fonts/VattenfallElements-Icons.woff") format("woff"), url("https://elements.vattenfall.se/fonts/VattenfallElements-Icons.ttf") format("truetype");
}
/*
  'vattenfall-elements' is the namespace used for all the styles when used in the global scope
*/
.vattenfall-elements__ui-grid {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: left;
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-1 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /1) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /2) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /4) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /3) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /6) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /9) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /4) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /8) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /12) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /16) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /5) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /10) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /15) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /20) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /25) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /6) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /12) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /18) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /24) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /30) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /36) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /7) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /14) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /21) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /28) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /35) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /42) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /49) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /8) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /16) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /24) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /32) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /40) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /48) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /56) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /64) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /9) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /18) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /27) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /36) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /45) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /54) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /63) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /72) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /81) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /10) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /20) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /30) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /40) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /50) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /60) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /70) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /80) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /90) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /100) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /11) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /22) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /33) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /44) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /55) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /66) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /77) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /88) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /99) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /110) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-11 {
  flex-basis: calc((100% /121) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /12) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /24) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /36) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /48) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /60) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /72) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /84) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /96) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /108) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /120) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-11 {
  flex-basis: calc((100% /132) - 0rem);
}
.vattenfall-elements__ui-grid.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-12 {
  flex-basis: calc((100% /144) - 0rem);
}

.vattenfall-elements__ui-grid--gutter {
  align-content: space-between;
  align-content: space-around;
  flex-direction: row;
  background: transparent;
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-1 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /1) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-1 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-1 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-1 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /2) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /4) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /3) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /6) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /9) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /4) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /8) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /12) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /16) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /5) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /10) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /15) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /20) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /25) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /6) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /12) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /18) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /24) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /30) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /36) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /7) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /14) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /21) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /28) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /35) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /42) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /49) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /8) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /16) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /24) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /32) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /40) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /48) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /56) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /64) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /9) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /18) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /27) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /36) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /45) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /54) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /63) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /72) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /81) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /10) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /20) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /30) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /40) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /50) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /60) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /70) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /80) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /90) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /100) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /11) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /22) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /33) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /44) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /55) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /66) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /77) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /88) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /99) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /110) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-11 {
  flex-basis: calc((100% /121) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-11 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-11 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-11 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /12) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /24) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /36) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /48) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /60) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /72) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /84) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /96) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /108) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /120) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-11 {
  flex-basis: calc((100% /132) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-11 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-11 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-11 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-12 {
  flex-basis: calc((100% /144) - 0.5rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-12 {
    margin: 0.125rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-12 {
    margin: 0.25rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-12 {
    margin: 0.25rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small {
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-1 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /1) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-1 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-1 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-1 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /2) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /4) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /3) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /6) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /9) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /4) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /8) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /12) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /16) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /5) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /10) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /15) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /20) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /25) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /6) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /12) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /18) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /24) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /30) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /36) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /7) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /14) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /21) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /28) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /35) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /42) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /49) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /8) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /16) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /24) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /32) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /40) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /48) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /56) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /64) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /9) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /18) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /27) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /36) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /45) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /54) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /63) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /72) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /81) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /10) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /20) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /30) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /40) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /50) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /60) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /70) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /80) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /90) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /100) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /11) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /22) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /33) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /44) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /55) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /66) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /77) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /88) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /99) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /110) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-11 {
  flex-basis: calc((100% /121) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-11 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-11 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-11 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /12) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /24) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /36) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /48) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /60) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /72) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /84) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /96) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /108) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /120) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-11 {
  flex-basis: calc((100% /132) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-11 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-11 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-11 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-12 {
  flex-basis: calc((100% /144) - 1.375rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-12 {
    margin: 0.34375rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-12 {
    margin: 0.6875rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__small.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-12 {
    margin: 0.6875rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large {
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
  /**
  example without gutter (default):
    <section class="ui-grid ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>
  example with gutter (defaults to 1rem (16px) between columns:
    <section class="ui-grid-gutter ui-grid--cols-4">
      <section class="ui-grid__tile--colspan-2"></section>
    </section>

  */
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-1 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /1) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-1 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-1 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-1 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /2) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /4) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-2 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /3) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /6) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /9) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-3 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /4) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /8) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /12) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /16) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-4 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /5) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /10) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /15) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /20) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /25) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-5 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /6) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /12) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /18) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /24) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /30) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /36) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-6 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /7) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /14) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /21) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /28) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /35) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /42) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /49) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-7 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /8) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /16) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /24) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /32) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /40) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /48) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /56) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /64) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-8 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /9) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /18) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /27) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /36) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /45) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /54) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /63) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /72) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /81) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-9 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /10) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /20) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /30) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /40) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /50) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /60) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /70) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /80) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /90) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /100) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-10 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /11) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /22) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /33) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /44) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /55) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /66) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /77) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /88) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /99) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /110) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-11 {
  flex-basis: calc((100% /121) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-11 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-11 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-11 .vattenfall-elements__ui-grid__tile--colspan-11 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-1 {
  flex-basis: calc((100% /12) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-1 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-2 {
  flex-basis: calc((100% /24) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-2 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-3 {
  flex-basis: calc((100% /36) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-3 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-4 {
  flex-basis: calc((100% /48) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-4 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-5 {
  flex-basis: calc((100% /60) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-5 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-6 {
  flex-basis: calc((100% /72) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-6 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-7 {
  flex-basis: calc((100% /84) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-7 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-8 {
  flex-basis: calc((100% /96) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-8 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-9 {
  flex-basis: calc((100% /108) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-9 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-10 {
  flex-basis: calc((100% /120) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-10 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-11 {
  flex-basis: calc((100% /132) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-11 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-11 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-11 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-12 {
  flex-basis: calc((100% /144) - 2rem);
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-12 {
    margin: 0.5rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-12 {
    margin: 1rem;
  }
}
@media (min-width: 1025px) {
  .vattenfall-elements__ui-grid--gutter__large.vattenfall-elements__ui-grid--cols-12 .vattenfall-elements__ui-grid__tile--colspan-12 {
    margin: 1rem;
  }
}
.vattenfall-elements__ui-grid--gutter .vattenfall-elements__ui-grid__tile {
  align-self: flex-start;
}

.vattenfall-elements__ui-container {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: auto;
  margin-left: auto;
  clear: left;
  font-family: "Vattenfall Hall", "Helvetica", Arial, sans-serif;
}
@media (max-width: 576px) {
  .vattenfall-elements__ui-container {
    max-width: 540px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 577px) and (max-width: 768px) {
  .vattenfall-elements__ui-container {
    max-width: 720px;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .vattenfall-elements__ui-container {
    max-width: 960px;
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media (min-width: 1201px) {
  .vattenfall-elements__ui-container {
    max-width: 1160px;
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media (min-width: 769px) and (max-width: 992px) {
  .vattenfall-elements__ui-container--variant-tight {
    max-width: 600px;
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .vattenfall-elements__ui-container--variant-tight {
    max-width: 774px;
  }
}
@media (min-width: 1201px) {
  .vattenfall-elements__ui-container--variant-tight {
    max-width: 960px;
  }
}
.vattenfall-elements__ui-container--variant-bleed {
  max-width: 1680px;
  margin: auto;
  padding-right: 20px;
  padding-left: 20px;
}
@media (max-width: 576px) {
  .vattenfall-elements__ui-container--variant-bleed {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 577px) and (max-width: 768px) {
  .vattenfall-elements__ui-container--variant-bleed {
    padding-left: 0;
    padding-right: 0;
  }
}
.vattenfall-elements__ui-container--overlay-top {
  position: relative;
  top: -3.75rem;
  z-index: 2;
}

.vattenfall-elements__ui-list {
  /*
  * Custom classes
  */
  /*
  * padding="none" - to have the list starting at the edge of the container, 
  * canceling default padding of an list.
  */
  /*
  * padding="default" - the default padding of lists with 1rem;
  */
  /**
  * padding="large" - when we need the list to nest deeper in the view
  */
  /*
   * padding="small" - when the list renders closer to the edge. 
  */
}
.vattenfall-elements__ui-list li {
  font-size: 0.875rem;
  list-style: none;
}
.vattenfall-elements__ui-list li:before {
  content: "-";
  font-weight: bold;
  width: 8px;
  margin-left: -1rem;
  margin-right: 0.3rem;
  color: #222222;
}
.vattenfall-elements__ui-list[variant=disc] li {
  line-height: 1.3rem;
}
.vattenfall-elements__ui-list[variant=disc] li:before {
  /* content: \2022 is the CSS Code/unicode for a bullet */
  content: "•";
  font-size: 1.9rem;
  line-height: 1rem;
  vertical-align: sub;
}
.vattenfall-elements__ui-list[variant=diamond] {
  font-size: 1rem;
  list-style: none;
}
.vattenfall-elements__ui-list[variant=diamond] li:before {
  /* content: \2666 is the CSS Code/unicode for a diamond */
  content: "♦";
}
.vattenfall-elements__ui-list[color=blue] li:before {
  color: #2071b5;
}
.vattenfall-elements__ui-list[color=yellow] li:before {
  color: #ffda00;
}
.vattenfall-elements__ui-list[color=gray] li:before {
  color: #4e4b48;
}
.vattenfall-elements__ui-list[padding=none] {
  padding: 0;
}
.vattenfall-elements__ui-list[padding=default] {
  padding: 1rem 1rem;
}
.vattenfall-elements__ui-list[padding=large] {
  padding: 2rem 1rem;
}
.vattenfall-elements__ui-list[padding=small] {
  padding: 0.5rem 1rem;
}

body {
  /**
  * Disable scrolling when dialog modal is open. 
  * This class is added by ui-dialog (dialog.component.ts) on dialog open.
  */
}
body.ui-dialog--open {
  overflow: hidden;
}

:root {
  font-family: "Vattenfall Hall", Helvetica, sans-serif;
}

.compiling {
  opacity: 0.3;
  background: linear-gradient(90deg, rgba(152, 152, 152, 0.5), rgba(216, 216, 216, 0.5));
  background-size: 200% 50%;
  background-position: -200%;
  animation: leftToRightWawe 1.4s cubic-bezier(0.07, 0.85, 0.65, 0.06) infinite;
  transition: background-position 0.8s ease-in-out;
  color: rgba(0, 0, 0, 0) !important;
}
@keyframes leftToRightWawe {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: -100% 0%;
  }
}

/*
  Styles for when a component is in a compiling state or a ready to be compiled state
*/
.compiling {
  font-size: 0;
  color: transparent;
}
.compiling > *[slot] {
  display: none;
}

/*
  Styles for when a component is in a error state.
  Only used when the application is running in development.
*/
.failed {
  display: block;
  min-height: 3rem;
  background: #eee;
  padding: 0;
}
.failed *[slot] {
  display: none;
}
.failed code {
  display: block;
  min-height: 3rem;
  padding: 0.75rem;
  background: #fef0ea;
  color: #f93b18;
}

/*
  Hide slots for custom elements that has not been defined
*/
*:not(:defined):not(.failed) elements-input {
  display: none;
}
*:not(:defined):not(.failed) > *[slot] {
  display: none;
}

/*
  Styles to address specific slots
*/
/* MySelectConsumptionIntervalFormSlot styling */
.vattenfall-elements__main-heading-container {
  position: relative;
  z-index: 1;
  background: #edf1f6;
  width: 100%;
  margin: auto;
  padding: 2.0625rem 0 5.6875rem 0;
}

.vattenfall-elements__main-heading,
[slot=mainHeading] {
  display: block;
  text-align: center;
  font-size: 4.125rem;
  font-weight: 600;
  width: 100%;
  word-break: break-word;
  margin-bottom: 2rem;
  max-width: 56.875rem;
  margin: 1rem auto 2rem;
}
@media (max-width: 599px) {
  .vattenfall-elements__main-heading,
[slot=mainHeading] {
    font-size: 1.625rem;
    margin-bottom: 1rem;
  }
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__main-heading,
[slot=mainHeading] {
    font-size: 1.625rem;
    margin-bottom: 1rem;
  }
}

.vattenfall-elements__main-heading--variant-bleed,
[slot=mainHeading] {
  display: block;
  text-align: center;
  font-size: 2.625rem;
  font-weight: 600;
  width: 100%;
  word-break: break-word;
  margin-bottom: 2rem;
  max-width: 56.875rem;
  margin: auto;
  line-height: 3.125rem;
}
@media (max-width: 599px) {
  .vattenfall-elements__main-heading--variant-bleed,
[slot=mainHeading] {
    font-size: 1.625rem;
    padding: 0;
  }
}
@media (min-width: 600px) and (max-width: 768px) {
  .vattenfall-elements__main-heading--variant-bleed,
[slot=mainHeading] {
    font-size: 1.625rem;
    padding: 0;
  }
}

.vattenfall-elements__main-body,
[slot=mainBody] {
  display: block;
  text-align: center;
  font-size: 1.25rem;
  font-weight: normal;
  width: 100%;
  max-width: 56.875rem;
  margin: 1rem auto 2rem;
}

.vattenfall-elements__center {
  text-align: center;
}

.vattenfall-elements__expand-button {
  padding: 0.625rem 1.625rem 0.625rem 2.875rem;
  background-color: #ffffff;
  border: 2px solid #2071b5;
  font-size: 1rem;
  line-height: 1.1875rem;
  border-radius: 2rem;
  cursor: pointer;
  outline: none;
  position: relative;
  margin: 2rem auto;
}
.vattenfall-elements__expand-button:before {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  content: "+";
  font-family: monospace;
  width: 1rem;
  height: 2rem;
  display: inline-block;
  margin-right: 0.5rem;
  font-size: 2rem;
  line-height: 2rem;
}
.vattenfall-elements__expand-button--expanded:before {
  content: "-";
  transform: translateY(-55%);
}
.vattenfall-elements__expand-button:hover {
  background-color: #ccc;
}

.vattenfall-elements__ui-container--collapsed {
  max-height: 6rem;
  overflow: hidden;
}

.vattenfall-elements__ui-container--expanded {
  max-height: unset !important;
  overflow: visible;
}

.vattenfall-elements__edit-link {
  text-decoration: none;
  color: #000;
}
.vattenfall-elements__edit-link:hover {
  text-decoration: none;
  color: #000;
}
.vattenfall-elements__edit-link::before {
  font-family: "Vattenfall Icons", sans-serif;
  color: #2071b5;
  font-size: 1.3125rem;
  position: relative;
  top: 0.25rem;
  padding-right: 0.5rem;
}

.vattenfall-elements__edit-link--pencil-icon__right {
  text-decoration: none;
  color: #000;
}
.vattenfall-elements__edit-link--pencil-icon__right:hover {
  text-decoration: none;
  color: #000;
}
.vattenfall-elements__edit-link--pencil-icon__right::after {
  content: "";
  font-family: "Vattenfall Icons", sans-serif;
  color: #2071b5;
  font-size: 1.3125rem;
  position: relative;
  top: 0.25rem;
  padding-left: 0.5rem;
}

[slot=consumptionIntervalHeading],
[slot=heatingSystemHeading] {
  font-size: 1.625rem;
  font-weight: 900;
}

[slot=consumptionIntervalBody],
[slot=heatingSystemBody] {
  font-size: 16px;
  margin-top: 25px;
}

[slot=consumptionInterval1Icon],
[slot=consumptionInterval2Icon],
[slot=consumptionInterval3Icon],
[slot=consumptionInterval4Icon] {
  height: 100%;
  max-height: 70px;
  display: inline-block;
  border-radius: 50%;
  margin: auto;
}

/* End of MySelectConsumptionIntervalFormSlot */
[slot=flowsNewContractSectionNotMovingContractStartDateCaption],
[slot=flowsNewContractSectionMovingContractStartDateCaption],
[slot=flowsSubmitContractSectionCurrentContractEndDateCaption] {
  font-style: italic;
  color: #999;
}

[slot=flowsNewContractSectionInValidDateMessage],
[slot=flowsSubmitContractSectionInvalidDateMessage] {
  color: #f93b18;
}

[slot=invoiceOverviewLatestInvoiceStatusPaidLabel] {
  font-weight: bold;
  color: #3dc07c;
}

[slot=invoiceOverviewLatestInvoiceStatusUnpaidLabel] {
  font-weight: bold;
  color: #222222;
}

[slot=invoiceOverviewLatestInvoiceStatusPassedDueDateLabel] {
  font-weight: bold;
  color: #f93b18;
}

[slot^=staticWhiteImage] {
  height: 6rem;
  min-width: 6rem;
  padding: 1rem;
  border-radius: 50%;
  box-sizing: content-box;
}

[slot=submitFormButtonText] {
  max-width: 34.8125rem;
  margin: auto;
}

[slot=mySelectElectricalProductIcon] {
  width: 3.125rem;
  display: inline-block;
  vertical-align: middle;
}

[slot=mySelectElectricalProductLabel] {
  color: #ffffff;
  border-radius: 2rem;
  position: absolute;
  top: -1rem;
  left: 50%;
  font-weight: 600;
  transform: translateX(-50%) translateY(-50%);
  background-color: #005c63;
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
}
[slot=mySelectElectricalProductLabel]:empty {
  display: none;
}

.vattenfall-elements__input-prefix {
  max-width: 10%;
  display: block;
}
@media (max-width: 599px) {
  .vattenfall-elements__input-prefix {
    margin: auto;
    text-align: center;
    margin: 0 auto 1rem auto;
  }
}

[slot=postalCodeGridareaPrefix],
[slot=consumptionIntervalPrefix] {
  color: #ffffff;
  background-color: #2071b5;
  border-radius: 50%;
  padding: 4px 11px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.375rem;
  margin-right: 0.75rem;
  vertical-align: top;
  top: 1rem;
  position: relative;
}
@media (max-width: 599px) {
  [slot=postalCodeGridareaPrefix],
[slot=consumptionIntervalPrefix] {
    display: block;
    max-width: 35px;
    margin: 0 auto 20px;
    padding: 0.4rem;
    text-align: center;
    top: unset;
    position: unset;
  }
}
[slot=postalCodeGridareaPrefix]:empty,
[slot=consumptionIntervalPrefix]:empty {
  display: none;
}